<template>
	<div>
		<b-modal
			ref="modal-add-dead-cp-ca"
			@hidden="closeModal"
			:title="title"
			no-close-on-backdrop
			cancel-title="Cancelar"
			size="xmd"
			v-model="openModal"
			scrollable
			@ok.prevent="deadChicks"
			ok-title="Guardar"
		>
			<div class="d-flex alig-items-center justify-content-between mx-2">
				<div class="d-flex align-items-center justify-content-start" :style="{ witdh: '600px' }">
					<b-form-group label="Elegir muerte o depurar" label-class="font-small-3">
						<div class="d-flex">
							<b-form-radio
								class="mr-1 d-flex align-items-center"
								v-model="depuracion_boolean"
								name="some-dead"
								:value="true"
								>Muerto</b-form-radio
							>
							<b-form-radio
								class="mr-1 d-flex align-items-center"
								v-model="depuracion_boolean"
								name="some-dead"
								:value="false"
								>Depurar</b-form-radio
							>
						</div>
					</b-form-group>
					<div v-if="!hide_select_chicks" class="d-flex flex-column ml-2">
						<p class="mb-0 text-add-cintillo font-small-3">Agregar cintillo:</p>
						<v-select
							ref="searchCA"
							@input="addCintilloToCorral($event)"
							v-model="chickSelect"
							:options="chicksArray"
							:clearable="false"
							label="label"
							:reduce="(option) => option"
							transition=""
							placeholder="Buscar por cintillo en ala"
							@search="
								(search, loading) =>
									getChicks({
										search,
										loading,
									})
							"
							class="select-add-cintillo"
						>
							<template slot="no-options"> Sin cintillo alas encontradas </template>

							<template slot="option" slot-scope="option">
								<div class="d-center">
									{{ option.label }}
								</div>
							</template>
							<template slot="selected-option" slot-scope="option">
								<div class="selected d-center">
									{{ option.label }}
								</div>
							</template>
						</v-select>
					</div>
				</div>
				<b-form-group label="Masivo" v-if="depuracion_boolean">
					<div class="d-flex">
						<b-form-radio
							class="mr-1 d-flex align-items-center"
							v-model="isMassively"
							name="some-massively"
							:value="true"
							>No</b-form-radio
						>
						<b-form-radio
							class="mr-1 d-flex align-items-center"
							v-model="isMassively"
							name="some-massively"
							:value="false"
							>Si</b-form-radio
						>
					</div>
				</b-form-group>
			</div>
			<div>
				<ValidationObserver ref="form" class="w-100">
					<b-row class="m-1">
						<b-col cols="12" class="">
							<b-row class="card p-1 mb-0">
								<span v-if="!isMassively" class="font-small-3 mt-50 mb-1">Cintillos: </span>
								<b-col
									class="container-chicks-dead"
									:class="[isMassively ? 'add-mass' : '']"
									cols="12"
									:lg="isMassively ? '12' : '12'"
								>
									<div
										class="mb-1 d-flex align-items-center justify-content-center"
										v-for="(item, key) in chicksArrayDeads"
										:style="{ position: 'relative' }"
									>
										<b-row class="w-100 d-flex align-items-center justify-content-center">
											<b-col
												cols="12"
												:lg="isMassively ? '4' : '12'"
												class="d-flex align-items-center w-100"
											>
												<b-form-group
													label-class="font-small-2"
													id="input-group-1"
													:label="isMassively ? 'Cintillo:' : ''"
													label-for="input-1"
													class="w-100 relative mb-0"
												>
													<div class="w-100 d-flex align-items-center justify-content-center">
														<div class="chick-item">
															<p class="mb-0 font-weight-bolder">
																{{ item.correlative }}
															</p>
															<div
																class="close"
																v-if="!isMassively"
																@click="deleteCintillo(item.correlative)"
															>
																<FeatherIcon icon="XIcon" class="icon" />
															</div>
														</div>
													</div>
												</b-form-group>
											</b-col>
											<b-col
												v-if="depuracion_boolean && isMassively"
												cols="12"
												:lg="isMassively ? '4' : '6'"
												class="d-flex align-items-center w-100"
											>
												<b-form-group
													id="input-group-1"
													label="Observación:"
													label-for="input-1"
													class="w-100"
													label-class="font-small-2"
												>
													<ValidationProvider rules="required" v-slot="{ errors }">
														<v-select
															v-model="item.reason_id"
															:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
															:options="arrayReasons"
															:clearable="false"
															label="reason"
															:reduce="(option) => option.id"
															:class="{ 'border-danger rounded': errors[0] }"
															@input="
																() => {
																	dead.observation = null;
																}
															"
															class="w-100"
														>
															<template v-slot:option="option">
																<div
																	class="d-flex justify-content-between align-items-center"
																>
																	<span
																		:style="`margin-left: ${
																			option.parent_id ? '10' : '0'
																		}px`"
																		>{{ option.reason }}
																	</span>
																	<!-- <span v-if="option.parent_id"> Sub-motivo de {{ option.parent_reason }} </span> -->
																</div>
															</template>
														</v-select>
														<small v-if="errors[0]" class="text-danger">{{
															errors[0]
														}}</small>
													</ValidationProvider>
												</b-form-group>
											</b-col>
											<b-col
												v-if="!depuracion_boolean && isMassively"
												class="input-select-observation"
												cols="12"
												:lg="isMassively ? '4' : '6'"
											>
												<b-form-group label="Observaciones:">
													<b-form-textarea
														v-model="item.observation"
														placeholder="Ingrese una observacion"
														rows="3"
														max-rows="6"
													></b-form-textarea>
												</b-form-group>
											</b-col>

											<b-col v-if="isMassively" cols="12" lg="4" md="4">
												<b-form-group label="Images:" label-class="font-small-2">
													<DragAndDropImages
														v-model="item.images"
														:filesArray="item.images"
														:source="'1'"
														:containerDescription="`Seleccionar evidencia`"
														containerPadding="25px"
													/>
												</b-form-group>
											</b-col>
										</b-row>

										<FeatherIcon
											v-if="isMassively"
											@click="deleteCintillo(item.correlative)"
											icon="XIcon"
											class="x-icon-row text-danger cursor-pointer"
										/>
									</div>
									<!-- <div class="d-flex align-items-center  justify-content-center mr-2 mb-2">
                                    <b-button variant="primary" size="sm" class="m-0">Agregar</b-button>
                                </div> -->
								</b-col>
							</b-row>
						</b-col>

						<!-- SI ES MASIVO -->
						<b-col cols="12" v-if="!isMassively" class="card mt-1 p-1 mb-0">
							<b-row>
								<b-col cols="12" lg="6" class="input-select-observation">
									<b-form-group
										v-if="!depuracion_boolean"
										description="Descripcion de la depuracion(opcional)."
										label="Observaciones:"
									>
										<b-form-textarea
											v-model="observationMassively"
											placeholder="Ingrese una observación"
											rows="3"
											max-rows="6"
										></b-form-textarea>
									</b-form-group>

									<b-form-group
										v-else
										id="input-group-2"
										label="Observación:"
										label-for="input-1"
										class="w-100"
										label-class="font-small-2"
									>
										<ValidationProvider rules="required" v-slot="{ errors }">
											<v-select
												id="select-masive"
												v-model="reasonMassively"
												:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
												:options="arrayReasons"
												:clearable="false"
												label="reason"
												:reduce="(option) => option.id"
												:class="{ 'border-danger rounded': errors[0] }"
												@input="
													() => {
														dead.observation = null;
													}
												"
												class="w-100"
											>
												<template v-slot:option="option">
													<div class="d-flex justify-content-between align-items-center">
														<span :style="`margin-left: ${option.parent_id ? '10' : '0'}px`"
															>{{ option.reason }}
														</span>
														<!-- <span v-if="option.parent_id"> Sub-motivo de {{ option.parent_reason }} </span> -->
													</div>
												</template>
											</v-select>
											<small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
										</ValidationProvider>
									</b-form-group>
								</b-col>
								<b-col cols="12" lg="6">
									<b-form-group label="Evidencias:" label-class="font-small-2">
										<DragAndDropImages
											v-model="dead.evidences"
											:filesArray="dead.evidences"
											:containerDescription="`Seleccionar evidencia`"
											containerPadding="25px"
										/>
									</b-form-group>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
				</ValidationObserver>
			</div>
		</b-modal>
	</div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import reasonsService from "@/services/reasons.service";
import corralService from "@/services/encaste/corral.service";
import DragAndDropImages from "@/components/commons/drag-and-drop/DragAndDropImages.vue";
import chicksService from "@/services/encaste/chicks.service";
import { setEvidencesBase64 } from "@/helpers/functions";

export default {
	name: "DeadsMassivelyChicksCA",
	components: {
		DragAndDropImages,
	},
	props: {
		chicksDeads: {
			type: Array,
			default: () => [],
		},
		massively: {
			type: Boolean,
			default: false,
		},
		recursive: {
			type: Boolean,
			default: false,
		},
		open: {
			type: Boolean,
			default: false,
		},
		recursive: {
			type: Boolean,
			default: false,
		},
		status_id: {
			type: Number,
			default: 3,
		},
		hide_select_chicks: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		dead: {
			ca_id: null,
			reason_id: null,
			observation: null,
			evidences: [],
			customFormData: [],
			type: 1,
			postura: {
				quantity: 1,
				egg_lote_id: null,
				cp_id: null,
			},
		},
		arrayReasons: [],
		depuracion_boolean: true,
		chicksArray: [],
		chickSelect: null,
		chicksArrayDeads: [],
		isMassively: false,
		imageMassively: [],
		observationMassively: null,
		reasonMassively: null,
		openModal: false,
	}),
	async mounted() {
		this.openModal = this.open;
		this.isPreloading();
		await this.getReasonsByModule(1);
		// this.toggleModal("modal-add-dead-cp-ca");
		this.isPreloading(false);

		this.chicksDeads.forEach((it) => {});
		this.chicksArrayDeads = this.chicksDeads;

		this.depuracion_boolean = this.status_id == 3 ? true : false;
	},
	watch: {
		depuracion_boolean(value) {
			if (!this.depuracion_boolean) {
				this.isMassively = false;
			}
			// isMassively
		},
	},
	computed: {
		...mapState("encasteChicks", ["chicks", "chicks_recursive", "jsonSearchChicks"]),
		title() {
			return (
				// plate se refiere al correlativo, no la placa en si
				`Registro ${this.depuracion_boolean ? "muertos" : "depurados"} en cintillo en la ala`
			);
		},
	},
	methods: {
		//RESET_STATUS_ITEM_DEAD
		...mapMutations("encasteChicks", [
			"RESET_STATUS_ITEM_DEAD",
			"ACTIVE_STATUS_ITEM_DEAD",
			"TOGGLE_RELOAD_CHICKS",
			"TOGGLE_RELOAD_COUNTERS",
		]),
		...mapActions("encasteChicks", ["getChicks", "getChicksRecursive"]),
		async getReasonsByModule(module_id) {
			const data = await reasonsService.getByModule(module_id);
			this.arrayReasons = data;
		},
		async getChicks({ search, loading }) {
			loading(true);
			if (search.length > 1) {
				const { message, chicks } = await chicksService.getChicksAlive({
					search,
				});
				this.chicksArray = chicks.map((it) => {
					return {
						label: it.correlative,
						value: it.id,
						id: it.id,
					};
				});
			}
			loading(false);
		},
		addCintilloToCorral(event) {
			let repeat = false;
			for (let i = 0; i < this.chicksArrayDeads.length; i++) {
				if (this.chicksArrayDeads[i].id == event.id) {
					repeat = true;
				}
			}
			!repeat &&
				this.chicksArrayDeads.push({
					correlative: event.label,
					id: event.id,
					images: [],
					observation: null,
					reason_id: null,
				});
			this.ACTIVE_STATUS_ITEM_DEAD({
				correlative: event.label,
			});
			this.chicksArray = [];
			this.chickSelect = null;
		},
		async deadChicks() {
			if (!(await this.$refs.form.validate())) {
				this.showToast(
					"danger",
					"top-right",
					"Campos Incompletos",
					"AlertCircleIcon",
					"Faltan campos por llenar"
				);
				return;
			}
			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;
			this.isPreloading();
			// console.log("recursive", this.isMassively);
			let [deadBase64] = await setEvidencesBase64([this.dead]);
			if (!this.isMassively) {
				this.chicksArrayDeads = this.chicksArrayDeads.map((it) => {
					return {
						...it,
						reason_id: this.reasonMassively,
						observation: this.observationMassively,
					};
				});
				await chicksService.deadChicksMassively({
					chicks: this.chicksArrayDeads,
					evidences: deadBase64.customFormData,
					status_id: this.depuracion_boolean ? 3 : 4,
					is_masive: 1,
				});
			} else {
				this.chicksArrayDeads = await setEvidencesBase64(
					this.chicksArrayDeads.map((it) => {
						return {
							...it,
							postura: null,
							evidences: it.images,
							customFormData: [],
						};
					})
				);

				let imagesArray = [];

				this.chicksArrayDeads.forEach((it) => {
					const images = it.customFormData.map((df) => {
						return {
							...df,
							chick_id: it.id,
						};
					});
					imagesArray = [...imagesArray, ...images];
				});
				for (let index = 0; index < this.chicksArrayDeads.length; index++) {
					delete this.chicksArrayDeads[index].customFormData;
					delete this.chicksArrayDeads[index].evidences;
					delete this.chicksArrayDeads[index].images;
				}
				await chicksService.deadChicksMassively({
					chicks: this.chicksArrayDeads,
					evidences: imagesArray,
					status_id: this.depuracion_boolean ? 3 : 4,
					is_masive: 0,
				});
			}

			await this.showToast(
				"success",
				"top-right",
				"Corrales",
				"SuccessIcon",
				`Se ${!this.depuracion_boolean ? "depuraron" : "mataron"} los cintillos seleccionados.`
			);

			this.isPreloading(false);
			this.TOGGLE_RELOAD_CHICKS({
				reload: true,
			});
			this.TOGGLE_RELOAD_COUNTERS({
				reload: true,
			});
			this.closeModal();

			// console.log('ADD', this.chicksArrayDeads, deadBasedeadBase64.evidences64.evidences, this.isMassively);
		},
		deleteCintillo(correlative) {
			if (this.chicksArrayDeads.length == 1) {
				this.showToast(
					"warning",
					"top-right",
					"Pollos",
					"WarningIcon",
					"Debe haber al menos un pollo en la lista."
				);
			} else {
				this.chicksArrayDeads = this.chicksArrayDeads.filter((it) => it.correlative !== correlative);
				this.RESET_STATUS_ITEM_DEAD({
					correlative,
				});
			}
		},
		closeModal() {
			// this.isPreloading();
			this.openModal = false;
			// console.log("close");
			this.$emit("refresh");
		},
	},
	watch: {
		depuracion_boolean() {
			this.reasonMassively = null;
			this.observationMassively = null;
		},
	},
};
</script>

<style lang="scss" scoped>
.container-chicks-dead {
	// display: grid;
	// grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: flex-start;
	flex-direction: row !important;
}
.add-mass {
	display: grid;
	grid-template-columns: 1fr;
}
.x-icon {
	position: absolute;
	top: 0;
	right: 60px;
	width: 20px;
	height: 20px;
}
.x-icon-row {
	position: absolute;
	top: 10px;
	right: 10px;
	width: 20px;
	height: 20px;
}
.chick-item {
	background: #7367f0 !important;
	width: auto;
	position: relative;
	padding: 0.3rem 0.6rem;
	border-radius: 4px;
	margin-right: 1rem;
	margin-bottom: 1rem;
	&:last-child {
		// margin-bottom: 0;
	}
	p {
		color: #fff;
		font-weight: bolder;
	}
	.close {
		z-index: 100;
		position: absolute !important;
		top: -10px;
		right: -10px;
		background: rgb(255, 51, 1);
		height: 20px;
		width: 20px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: 0.5s all ease-in-out;
		cursor: pointer;
		.icon {
			color: #fff;
		}
	}
}
.text-add-cintillo {
	font-size: 14px;
	margin-bottom: 0.2rem !important;
}
.select-add-cintillo {
	width: 200px;
	// margin-left: 1rem;
}
</style>
