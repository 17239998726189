var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modal-add-dead-cp-ca",attrs:{"title":_vm.title,"no-close-on-backdrop":"","cancel-title":"Cancelar","size":"lg"},on:{"hidden":function($event){return _vm.$emit('hidden', _vm.hasChanged)}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [(!_vm.loading)?_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return cancel()}}},[_vm._v(" Cancelar ")]):_c('b-button',{attrs:{"variant":"secondary","disabled":""}},[_c('b-spinner',{attrs:{"small":""}}),_vm._v(" Cargando... ")],1),(!_vm.loading)?_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Guardar ")]):_c('b-button',{attrs:{"variant":"primary","disabled":""}},[_c('b-spinner',{attrs:{"small":""}}),_vm._v(" Cargando... ")],1)]}}])},[_c('b-form-group',{attrs:{"label":"Elegir muerte o depurar"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('div',{staticClass:"d-flex"},[_c('b-form-radio',{staticClass:"mr-1 d-flex align-items-center",attrs:{"name":"some-radios","value":true},model:{value:(_vm.depuracion_boolean),callback:function ($$v) {_vm.depuracion_boolean=$$v},expression:"depuracion_boolean"}},[_vm._v("Muerto")]),_c('b-form-radio',{staticClass:"mr-1 d-flex align-items-center",attrs:{"name":"some-radios","value":false},model:{value:(_vm.depuracion_boolean),callback:function ($$v) {_vm.depuracion_boolean=$$v},expression:"depuracion_boolean"}},[_vm._v("Depurar")])],1)]}}])}),_c('ValidationObserver',{ref:"form"},[(_vm.depuracion_boolean)?_c('b-row',[_c('b-col',{staticClass:"input-select-observation",attrs:{"cols":"12","lg":"6","md":"6"}},[_c('b-form-group',{attrs:{"id":"input-group-1","label":"Observación:","label-for":"input-1"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors[0] },attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.arrayReasons,"clearable":false,"label":"reason","reduce":function (option) { return option.id; }},on:{"input":function () {
										_vm.dead.observation = null;
									}},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('span',{style:(("margin-left: " + (option.parent_id ? '10' : '0') + "px"))},[_vm._v(_vm._s(option.reason)+" ")])])]}}],null,true),model:{value:(_vm.dead.reason_id),callback:function ($$v) {_vm.$set(_vm.dead, "reason_id", $$v)},expression:"dead.reason_id"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,false,3589889427)})],1),(_vm.dead.reason_id === 19)?_c('b-form-group',{attrs:{"label":"Otros","label-for":"observation"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var errors = ref.errors;
return [_c('b-input-group',{attrs:{"label-for":"observation"}},[_c('b-form-textarea',{attrs:{"id":"observation","type":"text","placeholder":"Ingrese otra razon","state":errors[0] ? false : null},model:{value:(_vm.dead.observation),callback:function ($$v) {_vm.$set(_vm.dead, "observation", $$v)},expression:"dead.observation"}})],1),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1782035594)})],1):_vm._e()],1),_c('b-col',{attrs:{"cols":"12","lg":"6","md":"12"}},[_c('DragAndDropImages',{attrs:{"filesArray":_vm.dead.evidences,"source":'1',"containerDescription":"Seleccionar evidencia","containerPadding":"25px"},model:{value:(_vm.dead.evidences),callback:function ($$v) {_vm.$set(_vm.dead, "evidences", $$v)},expression:"dead.evidences"}})],1)],1):_c('b-row',[_c('b-col',{staticClass:"input-select-observation",attrs:{"cols":"12","lg":"12","md":"12"}},[_c('b-form-group',{attrs:{"description":"Descripcion de la depuracion(opcional).","label":"Observaciones:"}},[_c('b-form-textarea',{attrs:{"placeholder":"Ingrese una observacion","rows":"3","max-rows":"6"},model:{value:(_vm.observation_depuracion),callback:function ($$v) {_vm.observation_depuracion=$$v},expression:"observation_depuracion"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }