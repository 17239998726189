<template>
	<b-modal
		ref="modal-debugging-chicks"
		@hidden="$emit('hidden')"
		title="Depurar"
		no-close-on-backdrop
		ok-only
		ok-title="Cerrar"
		ok-variant="secondary"
		size="xl"
	>
		<b-card no-body class="mb-1">
			<div class="m-2">
				<b-row>
					<b-col md="4">
						<b-form-group
							label="Cintillo en ala"
							label-for="cintillo-ala-input-group"
						>
							<b-input-group id="cintillo-ala-input-group">
								<b-form-input
									placeholder="Cintillo en ala"
									v-model="cintilloAla"
									@keyup.enter="getGroupedDetail()"
								/>
								<b-input-group-append>
									<b-button
										variant="outline-primary"
										@click="getGroupedDetail()"
									>
										<feather-icon class="cursor-pointer" icon="SearchIcon" />
									</b-button>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</b-col>
				</b-row>
			</div>
			<div>
				<debugging-search-result
					:posturas="posturas"
					:paginate="paginate"
					v-loading.full="loading"
					@updatePosturas="getGroupedDetail()"
				></debugging-search-result>
			</div>
		</b-card>
	</b-modal>
</template>
<script>
import DebuggingSearchResult from "./DebuggingSearchResult.vue";
import DebuggingService from "./service/debugging.service";
import modalMixin from "@/mixins/modal";
export default {
	name: "DebuggingPosturas",
	mixins: [modalMixin],
	components: {
		DebuggingSearchResult,
	},
	mounted() {
		this.toggleModal("modal-debugging-chicks");
	},
	data() {
		return {
			cintilloAla: null,
			posturas: [],
			paginate: {
				orderby: 3,
				order: "desc",
				start_page: "",
				end_page: "",
				total_data: "",
				perpage: "",
				next_page: "",
				search_input: "",
			},
			loading: false,
		};
	},
	methods: {
		async getGroupedDetail() {
			this.loading = true;
			let params = {
				orderby: "desc",
				order: 3,
				campo: null,
				cintillo_ala: this.cintilloAla,
				status: null,
				gender: null,
			};
			try {
				const data = await DebuggingService.getGroupedDetail(params);
				this.posturas = data.data;
				this.paginate.start_page = data.current_page;
				this.paginate.perpage = data.per_page;
				this.paginate.next_page = this.paginate.start_page + 1;
				this.paginate.end_page = data.last_page;
				this.paginate.total_data = data.total;
				this.loading = false;
			} catch (error) {
				console.log(error);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}
td.div {
	width: 100% !important;
}
@media (max-width: 960px) {
	.column-table {
		display: flex;
		flex-direction: column;
	}
}
.cintillo-card {
	width: 100% !important;
	max-width: 150px !important;
	margin: 0 auto !important;
	flex-wrap: wrap !important;
	white-space: initial !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
