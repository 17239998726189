<template>
	<b-modal centered no-close-on-backdrop size="lg" :title="title" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<!-- <pre style="max-width: 10rem">{{ rows }}</pre>
			<pre style="max-width: 10rem">{{ selectedChicks }}</pre> -->
			<ValidationObserver ref="form">
				<b-table-simple class="mt-2" small sticky-header="80vh">
					<b-thead class="text-center">
						<b-tr>
							<b-th>Cintillo</b-th>
							<b-th>
								<div class="d-flex align-items-center justify-content-center">
									<p class="mb-0">Razones</p>
									<feather-icon
										@click="addReason.show = true"
										icon="PlusIcon"
										class="ml-50 text-primary cursor-pointer"
										size="18"
									/>
								</div>
							</b-th>
							<b-th>Acciones</b-th>
						</b-tr>
					</b-thead>
					<b-tbody class="text-center">
						<b-tr v-for="(row, idx) in rows" :key="idx">
							<b-td>
								<span v-if="onlyOne">{{ info.chick.correlative }}</span>
								<ValidationProvider rules="required" v-slot="{ errors }" v-else>
									<v-select
										label="correlative"
										style="min-width: 12rem"
										:class="{ 'border-danger rounded': !!errors[0] }"
										:clearable="false"
										:options="chickOpts"
										:reduce="(opt) => opt.id"
										:selectable="checkOptionSelectable"
										v-model="row.chick"
										appendToBody
										:calculatePosition="positionDropdown"
										@search="fetchOpts"
										@option:selecting="handleChickSelected"
									/>
								</ValidationProvider>
							</b-td>
							<b-td>
								<ValidationProvider rules="required" v-slot="{ errors }">
									<v-select
										multiple
										label="reason"
										style="min-width: 20rem"
										:class="{ 'border-danger rounded': !!errors[0] }"
										:options="reasonOpts"
										:clearable="false"
										:reduce="(option) => option.id"
										v-model="row.reasons"
										appendToBody
										:calculatePosition="positionDropdown"
									>
										<template v-slot:option="option">
											<div class="d-flex justify-content-between">
												<span :class="{ 'pl-2': !!option.parent_id }">
													{{ option.reason }}
												</span>
												<span v-if="option.parent_id">
													Sub-motivo de {{ option.parent_reason }}
												</span>
											</div>
										</template>
									</v-select>
								</ValidationProvider>
							</b-td>
							<b-td>
								<b-button
									class="btn-icon"
									size="sm"
									variant="danger"
									@click="deleteRow(idx)"
									:disabled="rows.length < 2"
								>
									<feather-icon icon="TrashIcon" />
								</b-button>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
				<div class="d-flex justify-content-end">
					<b-button class="btn-icon" size="sm" variant="outline-success" @click="addRow" v-if="!onlyOne">
						<feather-icon icon="PlusIcon" size="20" />
					</b-button>
				</div>
			</ValidationObserver>
		</b-overlay>
		<template #modal-footer>
			<b-button variant="success" @click="save">Guardar</b-button>
		</template>

		<AddReasonToNursing
			:show="addReason.show"
			@closing="
				addReason.show = false
				getOpts()
			"
		/>
	</b-modal>
</template>

<script>
import reasonsService from "@/services/reasons.service"
import nurseryService from "@/services/nursery/default.service"
import AddReasonToNursing from "./AddReasonToNursing.vue"

export default {
	components: { AddReasonToNursing },
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		title: null,
		rows: [
			{
				chick: null,
				reasons: [],
			},
		],
		chickOpts: [],
		selectedChicks: [],
		reasonOpts: [],
		addReason: {
			show: false,
		},
	}),
	computed: {
		onlyOne() {
			// objeto con id y correlative del specimen
			return !!this.info && !!this.info.chick
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.rows = [
				{
					chick: null,
					reasons: [],
				},
			]
			this.chickOpts = []
			this.selectedChicks = []
			this.$emit("closing")
		},
		async getOpts() {
			this.$emit("loading", true)
			this.is
			const reasons = await reasonsService.getByModule(4)
			this.reasonOpts = reasons
			this.$emit("loading", false)
		},
		addRow() {
			this.rows.push({
				chick: null,
				reasons: [],
			})
		},
		deleteRow(idx) {
			this.rows.splice(idx, 1)
		},
		positionDropdown(dropdownList, component, { width, top, left }) {
			dropdownList.style.zIndex = 9999
			dropdownList.style.maxHeight = "20rem"
			dropdownList.style.top = top
			dropdownList.style.left = left
			dropdownList.style.width = width
		},
		// search
		fetchOpts(search, loading) {
			this.findCintillos(search, loading, this.setOpts)
		},
		findCintillos: _.debounce(async (search, loading, setter) => {
			loading(true)
			const { data } = await nurseryService.getAvailableChicks({ search })
			setter(data)
			loading(false)
		}, 500),
		setOpts(data) {
			const existingIds = this.selectedChicks.map((c) => c.id)
			const withoutDuplicateds = data.filter((opt) => !existingIds.includes(opt.id))
			this.chickOpts = [...withoutDuplicateds, ...this.selectedChicks]
		},
		handleChickSelected(cintillo) {
			const existingIds = this.selectedChicks.map((opt) => opt.id)
			if (existingIds.includes(cintillo.id)) return
			this.selectedChicks.push(cintillo)
			// para limpiar especimens que no se usan al cambiar de opcion, se puede hacer un filter con los ids de los rows
		},
		async save() {
			if (!(await this.$refs.form.validate())) return
			const { isConfirmed } = await this.showConfirmSwal({
				text: `Se agregaran ${this.rows.length} pollo(s) a enfermeria`,
			})
			if (!isConfirmed) return
			this.isLoading = true
			const chicks = this.rows.map((row) => ({ id: row.chick, reasons: row.reasons }))
			await nurseryService.registerChicks({ chicks })
			this.isLoading = false
			this.showToast("success", "top-right", "Enfermeria", "CheckIcon", "GUARDADO CON EXITO")
			this.handleHidden()
			this.$emit("refresh")
		},
		checkOptionSelectable(opt) {
			const existingIds = this.rows.map((row) => row.chick)
			if (existingIds.includes(opt.id)) return false
			return true
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.getOpts()
				this.isActive = val
				if (this.onlyOne) {
					this.rows = [
						{
							chick: this.info.chick.id,
							reasons: [],
						},
					]
					this.title = `ENVIAR POLLO ${this.info.chick.correlative} A ENFERMERIA`
				} else {
					this.title = "ENVIAR POLLOS A ENFERMERIA"
				}
			}
		},
	},
}
</script>
