<template>
	<b-modal title="Nuevos ejemplares" size="xlg" centered v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<b-alert class="p-1" variant="warning" show>
				Para procesar los ejemplares deseados, seleccionar el checkbox de la fila deseada y subir la imagen
				requerida.
			</b-alert>
			<validation-observer ref="form">
				<b-table-simple sticky-header="60vh">
					<b-thead>
						<b-tr>
							<b-th class="text-center" style="z-index: 200" v-for="header in headers" :key="header">
								<div v-if="header == 'select'">
									<b-form-checkbox @change="selectMany($event)" class="d-inline-block" />
								</div>
								<span v-else>{{ header }}</span>
							</b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr class="text-center" v-for="row in rows" :key="row.id">
							<b-td class="p-0 m-0" style="width: 10px">
								<b-form-checkbox v-model="row.selected" class="d-inline-block" />
							</b-td>
							<b-td class="p-0 m-0" style="width: 60px">{{ row.correlative }}</b-td>
							<b-td>{{ row.plate }}</b-td>
							<b-td>
								{{ row.emplacement_user }}
								<br />
								<small>{{ row.emplacement_date | myGlobalDayShort }}</small>
							</b-td>

							<b-td>
								<SpecimenPlate :specimen="row.padrillo" />
							</b-td>
							<b-td>
								<SpecimenPlate :specimen="row.madrilla" />
							</b-td>
							<b-td style="width: 16rem">
								<ValidationProvider :rules="row.selected ? 'required' : ''" v-slot="{ errors }">
									<SelectableImage v-model="row.image" />
									<small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
								</ValidationProvider>
							</b-td>
							<b-td>
								<b-button
								size="sm"
									class="btn-icon"
									variant="danger"
									@click=";(killNew.info = row), (killNew.show = !killNew.show)"
									v-b-tooltip.hover title="Matar al ejemplar"
								>
									<v-icon name="fa-skull-crossbones" scale="1" />
								</b-button>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</validation-observer>
		</b-overlay>
		<div class="d-flex" style="gap: 1rem" slot="modal-footer">
			<b-button variant="primary" @click="sendProcesed">Procesar</b-button>
		</div>
		<KillNewModal :show="killNew.show" :info="killNew.info" @closing="killNew.show = false" @refresh="getRows" />
	</b-modal>
</template>

<script>
import CorralService from "@/services/encaste/corral.service"
import chicksService from "@/services/encaste/chicks.service.js"
import SelectableImage from "@/views/amg/specimens/components/SelectableImage.vue"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import KillNewModal from "./KillNewModal.vue"

export default {
	props: {
		show: Boolean,
	},
	components: { SelectableImage, SpecimenPlate, KillNewModal },
	data: () => ({
		isActive: false,
		isLoading: false,
		headers: ["select", "cintillo", "placa", "emplacado por", "padrillo", "madrilla", "subir imagen", "acciones"],
		retirementDate: new Date(),
		rows: [],
		killNew: { show: false, info: {} },
	}),
	methods: {
		async getRows() {
			this.isLoading = true
			const { data } = await CorralService.getPlacationRequests()
			this.rows = data.map((row) => ({ ...row, selected: false, image: null, dewornDate: null }))
			this.isLoading = false
		},
		handleHidden() {
			this.isActive = false
			this.$emit("closing")
		},
		selectMany(e) {
			this.rows.forEach((row) => {
				row.selected = e
			})
		},
		toBase64(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader()
				reader.readAsDataURL(file)
				reader.onload = () => resolve(reader.result)
				reader.onerror = (error) => reject(error)
			})
		},
		updateDateMassive(e) {
			this.rows.forEach((row) => {
				if (row.selected) {
					row.dewornDate = e
				}
			})
		},
		async sendProcesed() {
			this.isLoading = true
			if (!(await this.$refs.form.validate())) {
				this.showToast(
					"danger",
					"top-right",
					"Campos Incompletos",
					"AlertCircleIcon",
					"Faltan campos por llenar"
				)
				this.isLoading = false
				return
			}
			let data = {
				chicks: [],
			}

			let filtered = this.rows.filter((row) => row.selected)
			if (filtered.length == 0) {
				this.showToast(
					"danger",
					"top-right",
					"Campos Incompletos",
					"AlertCircleIcon",
					"No se selecciono nigun pollo."
				)
				this.isLoading = false
				return
			}
			let arr = filtered.map((chick) => chick.correlative).join(", ")
			let swalMsg = `A punto de confirmar placas a los cintillos ${arr}`
			const { isConfirmed } = await this.showConfirmSwal({ text: swalMsg })
			if (!isConfirmed) {
				this.isLoading = false
				return
			}
			data.chicks = filtered.map((row) => ({
				id: row.id,
				image: row.image,
				plate: row.plate,
			}))
			data.chicks = data.chicks.map(async (row) => ({
				...row,
				image: !!row.image ? await this.toBase64(row.image) : null,
			}))
			data.chicks = await Promise.all(data.chicks)

			let res = await chicksService.loopAssignPlate(data)
			this.isLoading = false
			this.showToast("success", "top-right", "Pollos", "AlertCircleIcon", "Pollos asignados exitosamente.")
			this.handleHidden()
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.getRows()
			}
		},
	},
}
</script>
