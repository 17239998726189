var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal-chicks-by-postura",attrs:{"title":"Pollos por lotes por posturas","cancel-title":"Cancelar","size":"xl"},on:{"hidden":function($event){return _vm.$emit('hidden', _vm.hasChanged)}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return cancel()}}},[_vm._v(" Cerrar "),_c('b-icon',{attrs:{"icon":"x","scale":"2"}})],1)]}}])},[_c('b-row',_vm._l((_vm.arrayGroupedPosturas),function(postura){return _c('b-col',{key:postura.id,attrs:{"cols":"12","xl":"12","md":"12"}},[_c('b-table-simple',[_c('b-thead',{staticClass:"text-uppercase text-center"},[_c('b-th',[_vm._v("Postura")]),_c('b-th',[_vm._v("Padrillo")]),_c('b-th',[_vm._v("Madrilla")]),_c('b-th',[_vm._v("PATA IZQUIERDA")]),_c('b-th',[_vm._v("PATA derecha")]),_c('b-th',[_vm._v("Fecha")])],1),_c('b-tbody',[_c('b-tr',{staticClass:"text-center"},[_c('b-td',[_c('h4',[_vm._v(_vm._s(postura.code))]),_c('b-badge',{attrs:{"variant":postura.status == 'ANULADA'
										? 'danger'
										: postura.status == 'PENDIENTE'
										? 'warning'
										: postura.status == 'POR FINALIZAR'
										? 'info'
										: 'primary'}},[_vm._v(" "+_vm._s(postura.status)+" ")])],1),_c('b-td',[_c('SpecimenPlate',{attrs:{"specimen":{
									plate: postura.placa_padrillo,
									id: postura.specimen_id,
									thumb: postura.padrillo_thumb,
									image: postura.padrillo_photo,
									alias: postura.padrillo_alias,
								}}})],1),_c('b-td',[_c('SpecimenPlate',{attrs:{"specimen":{
									plate: postura.placa_madrilla,
									id: postura.specimen_id,
									thumb: postura.madrilla_thumb,
									image: postura.madrilla_photo,
									alias: postura.madrilla_alias,
								}}})],1),_c('b-td',[_c('CombChickenlegsCintillos',{attrs:{"postura":{
									id: postura.id,
									chickenleg_wich_leg: postura.chickenleg_wich_leg,
									chickenleg_qty_cintillos: postura.chickenleg_qty_citillos,
									cintillo_color: postura.cintillo_color,
									cintillo_description: postura.cintillo_description,
									second_color_color: postura.c3_color,
									second_color_description: postura.c3_description,
									total_minus_infertile_eggs: 1,
								},"chickenleg_wich_leg":1}})],1),_c('b-td',[_c('CombChickenlegsCintillos',{attrs:{"postura":{
									id: postura.id,
									chickenleg_wich_leg: postura.chickenleg_wich_leg,
									chickenleg_qty_cintillos: postura.chickenleg_qty_citillos,
									cintillo_color: postura.cintillo_color,
									cintillo_description: postura.cintillo_description,
									second_color_color: postura.c3_color,
									second_color_description: postura.c3_description,
									total_minus_infertile_eggs: 1,
								},"chickenleg_wich_leg":2}})],1),_c('b-td',[_vm._v(" "+_vm._s(_vm._f("myGlobalDayShort")(postura.start_date))+" ")])],1)],1)],1),_vm._l((postura.json_data),function(lote){return [(lote.chicks)?_c('b-card',{key:lote.id,staticClass:"mt-3",style:(("border: 1px solid " + (_vm.layout.skin == 'dark' ? '#404656' : '#d8d6de') + " !important; border-radius: 10px;")),attrs:{"header-class":"d-flex justify-content-between align-items-center font-weight-bolder text-primary h4","header":lote.code}},[_c('b-table-lite',{attrs:{"responsive":"","items":lote.chicks,"fields":_vm.arrayColumns,"stacked":_vm.currentBreakPoint === 'sm' || _vm.currentBreakPoint === 'xs',"sticky-header":"75vh"},scopedSlots:_vm._u([{key:"cell(dob)",fn:function(data){return [_c('b-row',[_c('b-col',{staticClass:"text-center",attrs:{"cols":"12","md":"9"}},[(!data.item.is_editing_dob)?_c('div',[_c('span',[_vm._v(_vm._s(_vm._f("myGlobalDayShort")(data.item.dob)))])]):_c('div',[_c('b-form-datepicker',{attrs:{"size":"sm","id":"dob","placeholder":"Dob","date-format-options":{
												year: 'numeric',
												month: 'numeric',
												day: 'numeric',
											}},model:{value:(data.item.new_dob),callback:function ($$v) {_vm.$set(data.item, "new_dob", $$v)},expression:"data.item.new_dob"}})],1)]),_c('b-col',{attrs:{"md":"1"}},[(_vm.isAdmin && !data.item.is_editing_dob && data.item.status_number == 1)?_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"EditIcon"},on:{"click":function($event){return _vm.editChickDob(data.item)}}}):_vm._e(),(_vm.isAdmin && data.item.is_editing_dob)?[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"CheckIcon"},on:{"click":function($event){return _vm.saveNewDobToChick(data.item)}}})]:_vm._e()],2),_c('b-col',{attrs:{"md":"1"}},[(_vm.isAdmin && data.item.is_editing_dob)?[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"XIcon"},on:{"click":function($event){return _vm.closeEditChickDob(data.item)}}})]:_vm._e()],2)],1)]}},{key:"cell(gender)",fn:function(data){return [(data.item.is_assigned_plate)?_c('p',[_vm._v(" "+_vm._s(data.item.gender)+" ")]):_c('b-form-select',{attrs:{"id":"lblGender","options":_vm.genders,"required":"","size":"sm","disabled":!_vm.isAdmin || data.item.status_number == 2},on:{"input":function($event){return _vm.onChangeGenderSelect(data.item)}},model:{value:(data.item.gender_number),callback:function ($$v) {_vm.$set(data.item, "gender_number", $$v)},expression:"data.item.gender_number"}})]}},{key:"cell(status)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(data.item.status)+" ")])]}},{key:"cell(assigned_plate)",fn:function(data){return [(data.item.is_assigned_plate || (!_vm.isAdmin && data.item.status_number == 2))?_c('span',[_vm._v(" "+_vm._s(data.item.assigned_plate)+" ")]):_vm._e(),(
									data.item.gender != 'SIN ASIGNAR' &&
									!data.item.is_assigned_plate &&
									data.item_status != 2 &&
									_vm.isAdmin &&
									data.item.status_number == 1
								)?_c('b-row',[_c('b-col',{attrs:{"md":"9"}},[_c('b-form-input',{attrs:{"placeholder":"Placa","size":"sm"},on:{"input":function($event){return _vm.validatePlate(data.item)}},model:{value:(data.item.assigned_plate),callback:function ($$v) {_vm.$set(data.item, "assigned_plate", $$v)},expression:"data.item.assigned_plate"}}),(!data.item.is_correct_plate && data.item.assigned_plate)?_c('span',{staticClass:"text-danger"},[_vm._v("Placa existente")]):_vm._e()],1),_c('b-col',{attrs:{"md":"1"}},[_c('feather-icon',{class:[
											'cursor-pointer',
											!data.item.is_correct_plate ? 'disabled-element' : '' ],attrs:{"icon":"CheckIcon"},on:{"click":function($event){return _vm.setPlacaToChick(data.item)}}})],1),_c('b-col',{attrs:{"md":"1"}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"XIcon"},on:{"click":function($event){data.item.assigned_plate = null}}})],1)],1):_vm._e()]}}],null,true)})],1):_vm._e()]}),_c('hr',{staticClass:"invoice-spacing"})],2)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }