var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.posturas.length > 0)?[_c('debugging-posturas-information',{attrs:{"data":_vm.data},on:{"onClickItem":_vm.onClickItem}}),_c('b-row',{staticClass:"invoice-preview"},_vm._l((_vm.posturas),function(postura){return _c('b-col',{key:postura.id,attrs:{"cols":"12"}},[_c('b-card',{staticClass:"invoice-preview-card m-2",style:(("border: 1px solid " + (_vm.layout.skin == 'dark' ? '#404656' : '#d8d6de') + " !important; border-radius: 10px;")),attrs:{"title":postura.code}},[_c('b-card-body',{staticClass:"invoice-padding pt-0",staticStyle:{"margin-left":"0px","margin-right":"0px"}},[_c('b-row',{staticClass:"invoice-spacing"},[_vm._l((postura.json_data),function(lote){return [(lote.chicks)?_c('b-col',{key:lote.id,attrs:{"cols":"12","xl":"6"}},[_c('b-card',{style:(("border: 1px solid " + (_vm.layout.skin == 'dark' ? '#404656' : '#d8d6de') + " !important; border-radius: 10px; height: 100%;")),attrs:{"header-class":"d-flex justify-content-between align-items-center"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',[_c('span',[_vm._v(" "+_vm._s(lote.code)+" ")]),_c('br'),_c('span',[_vm._v(" "+_vm._s(_vm._f("myGlobalDayWhour")(lote.chicks_dob))+" ")])]),_c('div',{class:_vm.$store.getters['app/currentBreakPoint'] === 'xs'
                            ? 'mt-1'
                            : ''},[_c('b-badge',{staticStyle:{"padding":"9px"},attrs:{"variant":"primary"}},[_vm._v(" M: "+_vm._s(lote.macho_counter)+" ")]),_c('b-badge',{staticStyle:{"padding":"9px"},attrs:{"variant":"danger"}},[_vm._v(" H: "+_vm._s(lote.hembra_counter)+" ")]),(
                            lote.chicks.some(
                              function (chick) { return chick.status_number == 1; }
                            )
                          )?_c('b-form-checkbox',{class:_vm.$store.getters['app/currentBreakPoint'] === 'xs'
                              ? 'mt-1'
                              : '',attrs:{"indeterminate":lote.indeterminate,"button":"","button-variant":lote.allSelected ? 'danger' : 'success',"size":"sm"},on:{"change":function($event){return _vm.toggleAll(lote)}},model:{value:(lote.allSelected),callback:function ($$v) {_vm.$set(lote, "allSelected", $$v)},expression:"lote.allSelected"}},[_vm._v(" "+_vm._s(lote.allSelected ? "Eliminar selección" : "Seleccionar todo")+" ")]):_vm._e()],1)]},proxy:true}],null,true)},[_c('b-table-lite',{attrs:{"responsive":"","items":lote.chicks,"fields":_vm.arrayColumns,"stacked":_vm.currentBreakPoint === 'sm' ||
                        _vm.currentBreakPoint === 'xs',"sticky-header":"75vh"},scopedSlots:_vm._u([{key:"cell(date_of_birth)",fn:function(data){return [_c('b-row',[_c('b-col',{attrs:{"md":"9"}},[(!data.item.is_editing_dob)?[_c('span',[_vm._v(_vm._s(_vm._f("myGlobalDayWhour")(data.item.date_of_birth)))])]:[_c('b-form-datepicker',{attrs:{"size":"sm","id":"dob","placeholder":"Dob","date-format-options":{
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                }},model:{value:(data.item.new_dob),callback:function ($$v) {_vm.$set(data.item, "new_dob", $$v)},expression:"data.item.new_dob"}})]],2),_c('b-col',{attrs:{"md":"1"}},[(
                                _vm.isAdmin &&
                                !data.item.is_editing_dob &&
                                data.item.status_number == 1
                              )?_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"EditIcon"},on:{"click":function($event){return _vm.editChickDob(data.item)}}}):_vm._e(),(_vm.isAdmin && data.item.is_editing_dob)?[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"CheckIcon"},on:{"click":function($event){return _vm.saveNewDobToChick(data.item)}}})]:_vm._e()],2),_c('b-col',{attrs:{"md":"1"}},[(_vm.isAdmin && data.item.is_editing_dob)?[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"XIcon"},on:{"click":function($event){return _vm.closeEditChickDob(data.item)}}})]:_vm._e()],2)],1)]}},{key:"cell(gender)",fn:function(data){return [(data.item.is_assigned_plate)?_c('p',{style:(("color: " + (data.item.gender_number == 1
                              ? 'black'
                              : data.item.gender_number == 2
                              ? 'pink'
                              : '')))},[_vm._v(" "+_vm._s(data.item.gender)+" ")]):_c('b-form-select',{staticClass:"text-center",style:(("color: " + (data.item.gender_number == 1
                              ? 'black'
                              : data.item.gender_number == 2
                              ? 'pink'
                              : ''))),attrs:{"id":"lblGender","options":_vm.genders,"required":"","size":"sm","disabled":!_vm.isAdmin || data.item.status_number == 2},on:{"input":function($event){return _vm.onChangeGenderSelect(data.item)}},model:{value:(data.item.gender_number),callback:function ($$v) {_vm.$set(data.item, "gender_number", $$v)},expression:"data.item.gender_number"}})]}},{key:"cell(status)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(data.item.status)+" ")])]}},{key:"cell(assigned_plate)",fn:function(data){return [(
                            data.item.is_assigned_plate ||
                            (!_vm.isAdmin && data.item.status_number == 2)
                          )?_c('span',[_vm._v(" "+_vm._s(data.item.assigned_plate)+" ")]):_vm._e(),(
                            data.item.gender != 'SIN ASIGNAR' &&
                            !data.item.is_assigned_plate &&
                            data.item_status != 2 &&
                            _vm.isAdmin &&
                            data.item.status_number == 1
                          )?_c('b-row',[_c('b-col',{attrs:{"md":"9"}},[_c('b-form-input',{attrs:{"placeholder":"Placa","size":"sm"},on:{"input":function($event){return _vm.validatePlate(data.item)}},model:{value:(data.item.assigned_plate),callback:function ($$v) {_vm.$set(data.item, "assigned_plate", $$v)},expression:"data.item.assigned_plate"}}),(
                                !data.item.is_correct_plate &&
                                data.item.assigned_plate
                              )?_c('span',{staticClass:"text-danger"},[_vm._v("Placa existente")]):_vm._e()],1),_c('b-col',{attrs:{"md":"1"}},[_c('feather-icon',{class:[
                                'cursor-pointer',
                                !data.item.is_correct_plate
                                  ? 'disabled-element'
                                  : '' ],attrs:{"icon":"CheckIcon"},on:{"click":function($event){return _vm.setPlacaToChick(data.item)}}})],1),_c('b-col',{attrs:{"md":"1"}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"XIcon"},on:{"click":function($event){data.item.assigned_plate = null}}})],1)],1):_vm._e()]}},{key:"cell(actions)",fn:function(data){return [(_vm.isAdmin && !data.item.is_assigned_plate)?_c('feather-icon',{staticClass:"text-danger cursor-pointer",attrs:{"icon":"TrashIcon"},on:{"click":function($event){return _vm.deleteChick(data.item.chick_id)}}}):_vm._e()]}},{key:"cell(checkboxes)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center"},[(data.item.status_number == 1)?_c('b-form-checkbox',{attrs:{"id":("checkbox-" + (data.item.chick_id)),"value":{
                              id: data.item.cintillo_ala,
                              gender: data.item.gender_number,
                            }},on:{"input":function($event){return _vm.onChangeChickCheckbox(lote)}},model:{value:(lote.selected),callback:function ($$v) {_vm.$set(lote, "selected", $$v)},expression:"lote.selected"}}):_vm._e()],1)]}}],null,true)})],1)],1):_vm._e()]})],2)],1)],1)],1)}),1),_c('b-row',{staticClass:"m-1 text-right"},[_c('b-col',{staticClass:"mb-2 text-center",attrs:{"cols":"12"}},[_vm._v(" Pollos a depurar: "),_c('b-badge',{staticStyle:{"padding":"9px"},attrs:{"variant":"primary","id":"badge-macho"}},[_vm._v(" M: "+_vm._s(_vm.machoCounter)+" ")]),_c('b-tooltip',{attrs:{"target":"badge-macho","triggers":"hover","placement":"bottom"}},[_vm._v(" Machos a depurar ")]),_c('b-badge',{staticStyle:{"padding":"9px"},attrs:{"variant":"danger","id":"badge-hembra"}},[_vm._v(" H: "+_vm._s(_vm.hembraCounter)+" ")]),_c('b-tooltip',{attrs:{"target":"badge-hembra","triggers":"hover","placement":"bottom"}},[_vm._v(" Hembras a depurar ")])],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs',"disabled":_vm.thereAreChicksTokill <= 0},on:{"click":function($event){return _vm.killChicks()}}},[_vm._v(" Depurar ")])],1)],1)]:_vm._e(),(_vm.modal_image)?_c('view-image-specimens',{attrs:{"data_specimen":_vm.data_specimen},on:{"click":function($event){_vm.modal_image = false}}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }