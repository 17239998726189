// import { amgApi } from "@/service/axios";

class DebuggingService {

    async getGroupedDetail(payload) {
        const data = await axios.post('/api/chick/get-postura-by-chick', payload)
        let posturas = data.data.data;
        posturas.forEach(element => {
            element.json_data.forEach(lote => {
                lote.isSelected = false;
                lote.indeterminate = false;
                lote.selected = [];
                lote.allSelected = false;
                if(lote.chicks){
                    lote.chicks.forEach(chick => {
                        chick.isSelected = false;
                    })
                    lote.chicks.sort((a, b) => a.status_number - b.status_number || a.gender_number - b.gender_number)
                }

            })

        });
        return data.data;
    }
    async killChicks(payload) {
        const data = await axios.post('/api/deads/save', payload)
        return data.data;
    }
}
export default new DebuggingService()
