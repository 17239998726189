<template>
  <div>
    <template v-if="posturas.length > 0">
      <debugging-posturas-information
        :data="data"
        @onClickItem="onClickItem"
      ></debugging-posturas-information>
      <b-row class="invoice-preview">
        <b-col cols="12" v-for="postura in posturas" :key="postura.id">
          <b-card
            class="invoice-preview-card m-2"
            :style="`border: 1px solid ${
              layout.skin == 'dark' ? '#404656' : '#d8d6de'
            } !important; border-radius: 10px;`"
            :title="postura.code"
          >
            <b-card-body
              class="invoice-padding pt-0"
              style="margin-left: 0px; margin-right: 0px"
            >
              <b-row class="invoice-spacing">
                <!-- <b-col cols="12" md="4" class="p-0">
                    <h6 class="mb-25">{{ postura.code }}</h6>
                </b-col> -->
                <template v-for="lote in postura.json_data">
                  <b-col cols="12" xl="6" :key="lote.id" v-if="lote.chicks">
                    <b-card
                      :style="`border: 1px solid ${
                        layout.skin == 'dark' ? '#404656' : '#d8d6de'
                      } !important; border-radius: 10px; height: 100%;`"
                      header-class="d-flex justify-content-between align-items-center"
                    >
                      <template #header>
                        <div>
                          <span>
                            {{ lote.code }}
                          </span>
                          <br />
                          <span>
                            {{ lote.chicks_dob | myGlobalDayWhour }}
                          </span>
                        </div>
                        <div
                          :class="
                            $store.getters['app/currentBreakPoint'] === 'xs'
                              ? 'mt-1'
                              : ''
                          "
                        >
                          <b-badge variant="primary" style="padding: 9px">
                            M: {{ lote.macho_counter }}
                          </b-badge>
                          <b-badge variant="danger" style="padding: 9px">
                            H: {{ lote.hembra_counter }}
                          </b-badge>
                          <!-- <b-badge variant="dark" style="padding: 9px">
                        N: {{ lote.undefined_counter }}
                        </b-badge> -->
                          <b-form-checkbox
                            v-model="lote.allSelected"
                            :indeterminate="lote.indeterminate"
                            @change="toggleAll(lote)"
                            button
                            :class="
                              $store.getters['app/currentBreakPoint'] === 'xs'
                                ? 'mt-1'
                                : ''
                            "
                            v-if="
                              lote.chicks.some(
                                (chick) => chick.status_number == 1
                              )
                            "
                            :button-variant="
                              lote.allSelected ? 'danger' : 'success'
                            "
                            size="sm"
                          >
                            {{
                              lote.allSelected
                                ? "Eliminar selección"
                                : "Seleccionar todo"
                            }}
                          </b-form-checkbox>
                        </div>
                      </template>
                      <b-table-lite
                        responsive
                        :items="lote.chicks"
                        :fields="arrayColumns"
                        :stacked="
                          currentBreakPoint === 'sm' ||
                          currentBreakPoint === 'xs'
                        "
                        sticky-header="75vh"
                      >
                        <!-- <template #head(checkboxes)>
                        <b-form-checkbox
                        v-model="lote.allSelected"
                        :indeterminate="lote.indeterminate"
                        @change="toggleAll(lote)"
                        ></b-form-checkbox>
                    </template> -->
                        <template #cell(date_of_birth)="data">
                          <b-row>
                            <b-col md="9">
                              <template v-if="!data.item.is_editing_dob">
                                <span>{{
                                  data.item.date_of_birth | myGlobalDayWhour
                                }}</span>
                              </template>
                              <template v-else>
                                <b-form-datepicker
                                  size="sm"
                                  id="dob"
                                  placeholder="Dob"
                                  :date-format-options="{
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                  }"
                                  v-model="data.item.new_dob"
                                />
                              </template>
                            </b-col>
                            <b-col md="1">
                              <feather-icon
                                icon="EditIcon"
                                class="cursor-pointer"
                                v-if="
                                  isAdmin &&
                                  !data.item.is_editing_dob &&
                                  data.item.status_number == 1
                                "
                                @click="editChickDob(data.item)"
                              />
                              <template
                                v-if="isAdmin && data.item.is_editing_dob"
                              >
                                <feather-icon
                                  icon="CheckIcon"
                                  class="cursor-pointer"
                                  @click="saveNewDobToChick(data.item)"
                                />
                              </template>
                            </b-col>
                            <b-col md="1">
                              <template
                                v-if="isAdmin && data.item.is_editing_dob"
                              >
                                <feather-icon
                                  icon="XIcon"
                                  class="cursor-pointer"
                                  @click="closeEditChickDob(data.item)"
                                />
                              </template>
                            </b-col>
                          </b-row>
                          <!-- <span class="mr-1">
                        {{ data.item.date_of_birth | myGlobalDayWhour }}
                        </span>
                        <span>
                        <feather-icon icon="EditIcon" class="cursor-pointer" />
                        </span> -->
                        </template>
                        <template #cell(gender)="data">
                          <p
                            v-if="data.item.is_assigned_plate"
                            :style="`color: ${
                              data.item.gender_number == 1
                                ? 'black'
                                : data.item.gender_number == 2
                                ? 'pink'
                                : ''
                            }`"
                          >
                            {{ data.item.gender }}
                          </p>
                          <b-form-select
                            id="lblGender"
                            v-model="data.item.gender_number"
                            :options="genders"
                            required
                            size="sm"
                            :disabled="!isAdmin || data.item.status_number == 2"
                            @input="onChangeGenderSelect(data.item)"
                            v-else
                            class="text-center"
                            :style="`color: ${
                              data.item.gender_number == 1
                                ? 'black'
                                : data.item.gender_number == 2
                                ? 'pink'
                                : ''
                            }`"
                          ></b-form-select>
                        </template>
                        <template #cell(status)="data">
                          <span>
                            {{ data.item.status }}
                          </span>
                        </template>
                        <template #cell(assigned_plate)="data">
                          <span
                            v-if="
                              data.item.is_assigned_plate ||
                              (!isAdmin && data.item.status_number == 2)
                            "
                          >
                            {{ data.item.assigned_plate }}
                          </span>
                          <b-row
                            v-if="
                              data.item.gender != 'SIN ASIGNAR' &&
                              !data.item.is_assigned_plate &&
                              data.item_status != 2 &&
                              isAdmin &&
                              data.item.status_number == 1
                            "
                          >
                            <b-col md="9">
                              <b-form-input
                                placeholder="Placa"
                                size="sm"
                                v-model="data.item.assigned_plate"
                                @input="validatePlate(data.item)"
                              />
                              <span
                                class="text-danger"
                                v-if="
                                  !data.item.is_correct_plate &&
                                  data.item.assigned_plate
                                "
                                >Placa existente</span
                              >
                            </b-col>
                            <b-col md="1">
                              <feather-icon
                                icon="CheckIcon"
                                :class="[
                                  'cursor-pointer',
                                  !data.item.is_correct_plate
                                    ? 'disabled-element'
                                    : '',
                                ]"
                                @click="setPlacaToChick(data.item)"
                              />
                            </b-col>
                            <b-col md="1">
                              <feather-icon
                                icon="XIcon"
                                class="cursor-pointer"
                                @click="data.item.assigned_plate = null"
                              />
                            </b-col>
                          </b-row>
                        </template>
                        <template #cell(actions)="data">
                          <feather-icon
                            v-if="isAdmin && !data.item.is_assigned_plate"
                            class="text-danger cursor-pointer"
                            @click="deleteChick(data.item.chick_id)"
                            icon="TrashIcon"
                          />
                        </template>
                        <template #cell(checkboxes)="data">
                          <div class="d-flex justify-content-center">
                            <b-form-checkbox
                              :id="`checkbox-${data.item.chick_id}`"
                              :value="{
                                id: data.item.cintillo_ala,
                                gender: data.item.gender_number,
                              }"
                              v-if="data.item.status_number == 1"
                              v-model="lote.selected"
                              @input="onChangeChickCheckbox(lote)"
                            ></b-form-checkbox>
                          </div>
                        </template>
                      </b-table-lite>
                    </b-card>
                  </b-col>
                </template>
                <!-- <b-col
                    md="3"
                    cols="6"
                    class="p-0 mt-xl-0 mt-2 d-flex justify-content-md-end"
                    v-if="index == 0"
                >
                    <div class="d-flex flex-column align-items-center">
                    <h6 class="mb-2">Padrillo:</h6>
                    <b-avatar
                        size="70px"
                        :src="postura.padrillo_thumb"
                        icon="twitter"
                        class="mb-1"
                    />
                    <strong>{{ postura.padrillo_alias }}</strong>
                    <small class="text-muted"
                        >@{{ postura.placa_padrillo }}</small
                    >
                    </div>
                </b-col>
                <b-col
                    md="3"
                    cols="6"
                    class="p-0 mt-xl-0 mt-2 d-flex justify-content-end"
                    v-if="index == 0"
                >

                </b-col> -->
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="m-1 text-right">
        <b-col cols="12" class="mb-2 text-center">
          Pollos a depurar:
          <b-badge variant="primary" id="badge-macho" style="padding: 9px">
            M: {{ machoCounter }}
          </b-badge>
          <b-tooltip target="badge-macho" triggers="hover" placement="bottom">
            Machos a depurar
          </b-tooltip>
          <b-badge variant="danger" id="badge-hembra" style="padding: 9px">
            H: {{ hembraCounter }}
          </b-badge>
          <b-tooltip target="badge-hembra" triggers="hover" placement="bottom">
            Hembras a depurar
          </b-tooltip>
          <!-- <b-badge variant="dark" id="badge-undefined" style="padding: 9px">
                    N: {{ undefinedCounter }}
                </b-badge>
                <b-tooltip
                target="badge-undefined"
                triggers="hover"
                placement="bottom"
                >
                No definido a matar
                </b-tooltip> -->
        </b-col>
        <b-col cols="12">
          <b-button
            variant="primary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="killChicks()"
            :disabled="thereAreChicksTokill <= 0"
          >
            Depurar
          </b-button>
        </b-col>
      </b-row>
    </template>
    <view-image-specimens
      v-if="modal_image"
      :data_specimen="data_specimen"
      @click="modal_image = false"
    ></view-image-specimens>
  </div>
</template>
<script>
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";
import DebuggingService from "./service/debugging.service";
import DebuggingPosturasInformation from "./components/DebuggingPosturasInformation.vue";
export default {
  props: {
    posturas: {
      type: Array,
      required: false,
    },
    paginate: {
      type: Object,
      required: false,
    },
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      sortByCustom: "padrillo_alias",
      sortDesc: false,
      arrayGroupedPosturas: [],
      dato1: "",
      dato2: "",
      posturaId: null,
      posturaComment: null,
      modalActivatingPostura: false,
      loading: false,
      selectedChicks: [],
      chicksFiltered: [],
      selectAllChicks: false,
      genders: [
        {
          text: "MACHO",
          value: 1,
        },
        {
          text: "HEMBRA",
          value: 2,
        },
        {
          text: "SIN ASIGNAR",
          value: 3,
          disabled: true,
        },
      ],
      isEditingDob: false,
      arrayColumns: [
        {
          key: "cintillo_ala",
          label: "Cintillo ala",
          class: "text-center column-table",
        },
        // {
        //   key: "date_of_birth",
        //   label: "Fecha de nacimiento ",
        //   class: "text-center column-table align-items-center",
        // },
        {
          key: "gender",
          label: "Género",
          class: "text-center column-table",
        },
        // {
        //   key: "assigned_plate",
        //   label: "Placa",
        //   class: "text-center column-table",
        // },
        {
          key: "status",
          label: "Estado",
          class: "text-center column-table",
        },
        {
          key: "checkboxes",
          label: "Acciones",
          class: "text-center",
        },
      ],
      data: [
        {
          color: "light-primary",
          customClass: "mb-2 mb-xl-0",
          icon: "TwitterIcon",
          src: null,
          subtitle: "Padrillo",
          title: 0,
          url: "",
          plaque: "",
          alias: "",
          id: "",
        },
        {
          color: "light-primary",
          customClass: "mb-2 mb-xl-0",
          icon: "TwitterIcon",
          src: null,
          subtitle: "Madrilla",
          title: 0,
          url: "",
          plaque: "",
          alias: "",
          id: "",
        },
        {
          color: "light-dark",
          customClass: "mb-2 mb-xl-0",
          icon: "SunsetIcon",
          src: null,
          subtitle: "Machos",
          title: 0,
        },
        {
          color: "light-danger",
          customClass: "mb-2 mb-xl-0",
          icon: "LifeBuoyIcon",
          src: null,
          subtitle: "Hembras",
          title: 0,
        },
        {
          color: "light-info",
          customClass: "mb-2 mb-xl-0",
          icon: "ActivityIcon",
          src: null,
          subtitle: "Vivos",
          title: 0,
        },
      ],
      data_specimen: {
        type: "",
        url: "",
        plaque: "",
        alias: "",
        id: "",
        size: 300000000,
      },
      //       last_left: false,
      // last_right: false,
      modal_image: false,
    };
  },
  components: {
    vSelect,
    DebuggingPosturasInformation,
  },
  computed: {
    ...mapGetters({
      layout: "appConfig/layout",
    }),
    machoChickCounter() {
      let counter = 0;
      this.posturas.forEach((postura) => {
        postura.json_data.forEach((lote) => {
          if (lote.chicks) {
            lote.chicks.forEach((select) => {
              if (select.gender_number == 1 && select.status_number == 1) {
                counter++;
              }
            });
          }
        });
      });
      return counter;
    },
    hembraChickCounter() {
      let counter = 0;
      this.posturas.forEach((postura) => {
        postura.json_data.forEach((lote) => {
          if (lote.chicks) {
            lote.chicks.forEach((select) => {
              if (select.gender_number == 2 && select.status_number == 1) {
                counter++;
              }
            });
          }
        });
      });
      return counter;
    },
    aliveChickCounter() {
      let counter = 0;
      this.posturas.forEach((postura) => {
        postura.json_data.forEach((lote) => {
          if (lote.chicks) {
            lote.chicks.forEach((select) => {
              if (select.status_number == 1) {
                counter++;
              }
            });
          }
        });
      });
      return counter;
    },
    thereAreChicksTokill() {
      let counter = 0;
      this.posturas.forEach((postura) => {
        postura.json_data.forEach((lote) => {
          if (lote.selected.length > 0) {
            counter++;
          }
        });
      });
      return counter;
    },
    machoCounter() {
      let counter = 0;
      this.posturas.forEach((postura) => {
        postura.json_data.forEach((lote) => {
          lote.selected.forEach((select) => {
            if (select.gender == 1) {
              counter++;
            }
          });
        });
      });
      return counter;
    },
    hembraCounter() {
      let counter = 0;
      this.posturas.forEach((postura) => {
        postura.json_data.forEach((lote) => {
          lote.selected.forEach((select) => {
            if (select.gender == 2) {
              counter++;
            }
          });
        });
      });
      return counter;
    },
    undefinedCounter() {
      let counter = 0;
      this.posturas.forEach((postura) => {
        postura.json_data.forEach((lote) => {
          lote.selected.forEach((select) => {
            if (select.gender == 3) {
              counter++;
            }
          });
        });
      });
      return counter;
    },
  },
  methods: {
    ...mapActions({
      toggleLoading: "app/toggleLoading",
    }),
    onChangeChickCheckbox(lote) {
      if (lote.selected.length === 0) {
        lote.indeterminate = false;
        lote.allSelected = false;
      } else if (lote.selected.length === lote.chicks.length) {
        lote.indeterminate = false;
        lote.allSelected = true;
      } else {
        lote.indeterminate = true;
        lote.allSelected = false;
      }
    },
    onClickItem(item) {
      this.seeimage(item.url, item.plaque, item.alias, item.id);
    },
    seeimage(url, plaque, alias, id) {
      this.modal_image = true;
      this.data_specimen.type = 1;
      this.data_specimen.url = url;
      this.data_specimen.plaque = plaque;
      this.data_specimen.alias = alias;
      this.data_specimen.id = id;
    },
    toggleAll(lote) {
      if (lote.allSelected) {
        lote.chicks.forEach((chick) => {
          if (chick.status_number == 1) {
            lote.selected.push({
              id: chick.cintillo_ala,
              gender: chick.gender_number,
            });
          }
        });
      } else {
        lote.selected = [];
      }

      //   lote.selected = lote.allSelected
      //     ? lote.chicks.map((a) => {
      //         console.log(a.status_number, 'status_number')
      //         if (a.status_number == 1) {
      //           return {
      //             id: a.cintillo_ala,
      //             gender: a.gender_number,
      //           };
      //         }else{
      //             return
      //         }
      //       })
      //     : [];
      console.log(lote.selected, "selected");
    },
    async killChicks() {
      this.$swal({
        title: "Estas seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
        customClass: {
          cancelButton: "btn btn-outline-danger ml-1",
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          this.toggleLoading(true);
          let array = [];
          this.posturas.forEach((postura) => {
            postura.json_data.forEach((lote) => {
              lote.selected.forEach((chick) => {
                array.push({
                  id: chick.id,
                  type: 2,
                  observation: {
                    id: 16,
                    module_id: 1,
                    parent_id: null,
                    reason: "DEPURACIÓN",
                    parent_reason: null,
                  },
                  circumstances: null,
                  breeder_id: null,
                  postura_id: null,
                  lote_id: null,
                  evidences: [],
                  customFormData: [],
                  error: "Este cintillo en el ala ya está usado",
                  selectedLoteCode: null,
                });
              });
            });
          });
          let params = {
            header: JSON.stringify(array),
            created_by: this.currentUser.user_id,
          };
          try {
            const data = await DebuggingService.killChicks(params);
            this.showToast(
              "success",
              "top-right",
              "Felicidades",
              "CheckIcon",
              "Se han registrado correctamente las muertes!"
            );
            this.toggleLoading(false);
            this.$emit("updatePosturas");
          } catch (error) {
            console.error(error);
            this.toggleLoading(false);
          }
        }
      });
    },
    validatePlate(chick) {
      axios
        .post("/api/specimens/validate", {
          plate: chick.assigned_plate,
        })
        .then((res) => {
          if (res.data.length > 0) {
            chick.is_correct_plate = 0;
          } else {
            chick.is_correct_plate = 1;
          }
        });
    },
    setPlacaToChick(chick) {
      this.$swal({
        title: "Estas seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
        customClass: {
          cancelButton: "btn btn-outline-danger ml-1",
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios
            .post("/api/chick/set-placa", {
              chicken_id: chick.chick_id,
              assigned_plate: chick.assigned_plate,
              user_id: this.currentUser.user_id,
              dob: chick.date_of_birth,
              gender: chick.gender_number,
            })
            .then((response) => {
              if (response.status == 200) {
                this.showToast(
                  "success",
                  "top-right",
                  "Felicidades",
                  "CheckIcon",
                  "Placa registrada correctamente!"
                );
                this.getGroupedDetail();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    editChickDob(chick) {
      chick.new_dob = chick.date_of_birth;
      chick.is_editing_dob = 1;
    },
    closeEditChickDob(chick) {
      chick.new_dob = "";
      chick.is_editing_dob = 0;
    },
    saveNewDobToChick(chick) {
      this.$swal({
        title: "Estas seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
        customClass: {
          cancelButton: "btn btn-outline-danger ml-1",
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios
            .post("/api/chick/save-new-dob", {
              chick_id: chick.chick_id,
              new_dob: chick.new_dob,
              plate: chick.assigned_plate,
            })
            .then((response) => {
              if (response.status == 200) {
                this.showToast(
                  "success",
                  "top-right",
                  "Felicidades",
                  "CheckIcon",
                  "Fecha de nacimiento editada"
                );
                this.$emit("update");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    onChangeGenderSelect(gender) {
      this.editChick(
        gender.gender_number,
        gender.status_number,
        gender.chick_id
      );
    },
    editChick(gender, status, chick_id) {
      axios
        .post("/api/chick/update", {
          gender: gender,
          status: status,
          chick_id: chick_id,
        })
        .then((res) => {
          // alert('Actualizado');
          this.showToast(
            "success",
            "top-right",
            "Felicidades",
            "CheckIcon",
            "Pollo actualizado"
          );
          this.$emit("updatePosturas");
        });
    },
    resetSearch() {
      this.paginate.search_input = "";
      this.$emit("update");
    },
    moment: function () {
      return moment();
    },
    deleteChick(id) {
      this.$swal({
        title: "Estas seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
        customClass: {
          cancelButton: "btn btn-outline-danger ml-1",
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios
            .post("/api/chick/delete", {
              chick_id: id,
              deleted_by: this.currentUser.user_id,
            })
            .then((response) => {
              if (response.status == 200) {
                this.showToast(
                  "success",
                  "top-right",
                  "Felicidades",
                  "CheckIcon",
                  "Pollo Eliminado"
                );
                this.$emit("update");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
  },
  watch: {
    "paginate.search_input"() {
      this.$emit("update");
    },
    posturas() {
      this.data[0].url = this.posturas[0].padrillo_photo;
      this.data[0].plaque = this.posturas[0].placa_padrillo;
      this.data[0].alias = this.posturas[0].padrillo_alias;
      this.data[0].id = this.posturas[0].padrillo_id;
      this.data[0].src = this.posturas[0].padrillo_thumb;
      this.data[0].title = this.posturas[0].padrillo_alias
        ? this.posturas[0].padrillo_alias
        : this.posturas[0].placa_padrillo;
      this.data[1].url = this.posturas[0].photo;
      this.data[1].plaque = this.posturas[0].placa_madrilla;
      this.data[1].alias = this.posturas[0].madrilla_alias;
      this.data[1].id = this.posturas[0].madrilla_id;
      this.data[1].title = this.posturas[0].madrilla_alias
        ? this.posturas[0].madrilla_alias
        : this.posturas[0].placa_madrilla;
      this.data[1].src = this.posturas[0].madrilla_thumb;
      this.data[2].title = this.machoChickCounter;
      this.data[3].title = this.hembraChickCounter;
      this.data[4].title = this.aliveChickCounter;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
.per-page-selector {
  width: 90px;
}
td.div {
  width: 100% !important;
}
@media (max-width: 960px) {
  .column-table {
    display: flex;
    flex-direction: column;
  }
}
.disabled-element {
  pointer-events: none;
  opacity: 0.5;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
