<template>
	<b-modal
		centered
		hide-footer
		:title="`Tracking de historial clinico del pollo ${info.correlative}`"
		size="lg"
		v-model="isActive"
		@hidden="handleHidden"
	>
		<b-overlay :show="isLoading">
			<b-tabs
				v-model="tab"
				@input="
					rows = []
					getRows()
				"
			>
				<b-tab>
					<template #title>
						<p class="mb-0">Desparacitacion</p>
						<p v-if="counters[0] && counters[0].counter_deworn > 0" class="badge badge-danger mb-0 ml-25">
							{{ counters[0].counter_deworn }}
						</p>
					</template>
				</b-tab>
				<b-tab>
					<template #title>
						<p class="mb-0">Suplementos</p>
						<p v-if="counters[0] && counters[0].counter_vitamin > 0" class="badge badge-danger mb-0 ml-25">
							{{ counters[0].counter_vitamin }}
						</p>
					</template>
				</b-tab>
			</b-tabs>
			<b-table-simple>
				<b-thead class="text-center">
					<b-tr>
						<b-th>Comentario</b-th>
						<b-th>Creado por</b-th>
						<b-th v-if="tab == 1">Suplemento</b-th>
						<b-th v-if="tab == 1">Tipo de suplemento</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="(row, idx) in rows" :key="idx">
						<b-td>
							<p style="max-height: 10rem; overflow-y: auto">
								{{ row.comment }}
							</p>
						</b-td>
						<b-td>
							<span>{{ row.name }}</span>
							<br />
							<small>{{ row.created_at | myGlobalDayShort }}</small>
						</b-td>
						<b-td v-if="tab == 1">{{ row.name }}</b-td>
						<b-td v-if="tab == 1">{{ String(row.type).toUpperCase() }}</b-td>
					</b-tr>
					<b-tr v-if="rows.length == 0">
						<b-td :colspan="tab == 0 ? 2 : 4">No hay registros para mostrar</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
	</b-modal>
</template>

<script>
import corralService from "@/services/encaste/corral.service"

export default {
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		tab: 0,
		rows: [],
		counters: [],
	}),
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
		},
		async getRows() {
			this.isLoading = true
			if (this.tab == 0) {
				const { data } = await corralService.getChickDewornTracking({ chick_id: this.info.id })
				this.rows = data
			} else {
				const { data } = await corralService.getChickVitaminTracking({ chick_id: this.info.id })
				this.rows = data
			}
			console.log(this.rows)
			this.isLoading = false
		},
		async getCounters() {
			const { data } = await corralService.getCounterHcChick({ chick_id: this.info.id })
			// console.log('resp', resp);
			this.counters = data
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.getCounters()
				this.getRows()
			}
		},
	},
}
</script>
