<template>
	<div class="ml-50 d-flex align-items-center ">
		<div class="d-flex align-items-center justify-content-center">
			<b-button v-if="status==1" class="mr-2 d-flex align-items-center justify-content-center" @click="toggleModuleNotepad" variant="primary" size="sm">
				<FeatherIcon icon="SearchIcon" class="mr-25"/>
				Buscador
			</b-button>
			<FeatherIcon
				v-b-tooltip.hover title="Refrezcar cambios"
				size="20"
				@click="clearChicks"
				icon="RefreshCcwIcon"
				class="cursor-pointer icon text-primary"
				:class="[refreshIcon ? 'icon-refresh' : '']"
			/>
		</div>

		<b-modal
			:title="viewModalNotepadItemsSearch.title"
			size="xmd"
			v-if="viewModalNotepadItemsSearch.show"
			v-model="viewModalNotepadItemsSearch.show"
			hide-footer
			@hide="CloseModalNotepad"
		>
			<div class="container-cantidad-pollos">
				<div class="d-flex mt-50">
					<div class="d-flex align-items-center">
						<div
							v-if="searchChicks.filter((it) => [3, 4].includes(it.status_id)).length > 0"
							class="d-flex align-items-center mr-50"
						>
							<div class="circle bg-danger mr-25"></div>
							<span class="font-small-1">Muertos</span>
						</div>
						<div class="d-flex align-items-center">
							<div class="circle bg-success mr-25"></div>
							<span class="font-small-1">vivos</span>
						</div>
					</div>
					<p class="ml-1 mb-0 mt-0 text-right">Pollos: {{ searchChicks.length }}</p>
				</div>
				<div class="mt-50">
					<b-button @click="filterAll" class="ml-1" variant="warning" size="sm">Borrar todo</b-button>
					<b-button
						v-if="searchChicks.filter((it) => [3, 4].includes(it.status_id)).length > 0"
						@click="filtersDeads"
						class="ml-1"
						variant="warning"
						size="sm"
						>Borrar muertos</b-button
					>
				</div>
			</div>
			<div>
				<b-row>
					<b-col cols="12" xl="4" class="mb-1">
						<div class="d-flex align-items-center w-100">
							<b-form-group label="Cintillo" class="w-100 mb-0">
								<!-- {{ chickSelect }} -->
								<v-select
									ref="searchCA"
									class="w-100"
									@input="addCintilloToNotePad($event)"
									:value="chickSelect"
									:options="chicksArray"
									:clearable="false"
									label="label"
									:reduce="(option) => option"
									transition=""
									:placeholder="`Buscar por cintillo en ala`"
									@search="
										(search, loading) =>
											getChicksSelect({
												search,
												loading,
											})
									"
								>
									<template slot="no-options"> Sin cintillo alas encontradas </template>

									<template slot="option" slot-scope="option">
										<div class="d-center">
											{{ option.label }}
										</div>
									</template>
									<template slot="selected-option" slot-scope="option">
										<div class="selected d-center">
											{{ option.label }}
										</div>
									</template>
								</v-select>
							</b-form-group>
						</div>
					</b-col>
				</b-row>
			</div>

			<validation-observer ref="form">
				<div class="mb-2">
					<!-- <p>{{ corral ? "Corral" : "Pollos que ingresan al corral" }}</p> -->
					<p>Notepad</p>
					<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
						<b-form-input
							v-model="cintilloInputNone"
							class="d-none"
							:class="errors[0] ? 'is-invalid' : ''"
						></b-form-input>
						<div class="chick-grid" :class="errors[0] ? 'icon-invalid' : ''">
							<FeatherIcon
								:class="errors[0] ? 'd-flex icon-invalid-chicks' : 'd-none'"
								class=""
								icon="InfoIcon"
								color="red"
								size="12"
							/>
							<div
								v-if="searchChicks.length != 0"
								class="chick-item"
								v-for="(item, key) in searchChicks"
								:key="key"
								:class="
									item.status_id == 3 ? 'bg-danger' : item.status_id == 4 ? 'bg-danger' : 'bg-success'
								"
							>
								<p class="mb-0">{{ item.label }}</p>
								<div class="close">
									<FeatherIcon icon="XIcon" class="icon" @click="REMOVE_SEARCH_CHICK(item)" />
								</div>
							</div>
							<div v-if="searchChicks.length == 0">
								<p class="mb-0 font-small-4" style="color: #cecece">Seleccione un cintillo en ala</p>
							</div>
						</div>
						<span class="invalid-feedback" v-if="errors[0]">{{ errors[0] }}</span>
					</ValidationProvider>
				</div>
			</validation-observer>

			<div class="d-flex justify-content-end">
				<div>
					<b-button @click="saveNotepadChicks" class="ml-1" variant="secondary" size="sm">Guardar</b-button>

					<b-button
						@click="searchNotepadChicks"
						class="ml-1"
						variant="primary"
						size="sm"
						:disabled="searchChicks.length < 1"
						>Buscar</b-button
					>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import notepadService from "@/services/notepad.service";
import chicksService from "@/services/encaste/chicks.service";

export default {
	name: "NotepadItemsSearch",
	data() {
		return {
			viewModalNotepadItemsSearch: {
				show: false,
				title: "Busqueda global",
			},
			chicksArray: [],
			chickSelect: null,
			cintilloInputNone: null,
			refreshIcon: false,
		};
	},
	props: {
		status: {
			type: Number,
			default: 1,
		},
	},
	async mounted() {
		this.updateSearchCintillos();
	},
	computed: {
		...mapState("encasteChicks", ["searchChicks", "jsonSearchChicks", "year","start_date","end_Date"]),
	},
	methods: {
		...mapActions("encasteChicks", ["getChicks"]),
		...mapMutations("encasteChicks", [
			"SET_SEARCH_CHICK",
			"REMOVE_SEARCH_CHICK",
			"SET_JSON_SEARCH_CHICKS",
			"SET_YEAR_MAIN",
			"RESET_CHICKS",
		]),
		async toggleModuleNotepad() {
			this.viewModalNotepadItemsSearch.show = !this.viewModalNotepadItemsSearch.show;

			if (this.viewModalNotepadItemsSearch.show) {
				this.isPreloading();
				const { chicks } = await chicksService.getNotepadData({
					module_id: 2,
					sub_module_id: 1,
				});

				this.SET_SEARCH_CHICK(chicks);
				this.isPreloading(false);
			}
		},
		async getChicksSelect({ search, loading }) {
			loading(true);
			if (search.length > 1) {
				const { message, chicks } = await chicksService.getChicksAlive({
					search,
				});
				this.chicksArray = chicks.map((it) => {
					return {
						label: it.correlative,
						value: it.id,
						id: it.id,
					};
				});
			}
			loading(false);
		},
		addCintilloToNotePad(cintillo) {
			this.SET_SEARCH_CHICK([...this.searchChicks, { ...cintillo, status_id: 2 }]);

			this.chicksArray = [];
		},
		async saveNotepadChicks() {
			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;
			this.isPreloading();
			const { status, message } = await notepadService.upsertNotepad({
				module_id: 2,
				sub_module_id: 1,
				word: this.searchChicks,
			});
			if (!status) {
				this.isPreloading(false);
				return await this.showToast("danger", "top-right", "Pollos", "InfoIcon", "No se guardo correctamente.");
			}
			await this.showToast("success", "top-right", "Pollos", "InfoIcon", "Se guardo correctamente el notepad.");
			this.isPreloading(false);
		},
		async searchNotepadChicks() {
			this.SET_YEAR_MAIN(null),
				this.SET_JSON_SEARCH_CHICKS({
					...this.jsonSearchChicks,
					chicks_id: this.searchChicks.map((it) => it.id),
					start_dob: null,
					end_dob: null,
					search: null,
				});
			await this.getChicks(this.jsonSearchChicks);
			await this.showToast("success", "top-right", "Pollos", "InfoIcon", "Se busco correctamente la lista.");
			this.viewModalNotepadItemsSearch.show = false;
		},
		updateSearchCintillos() {
			this.cintilloInputNone = this.searchChicks.length;
			if (this.cintilloInputNone == 0) {
				this.cintilloInputNone = null;
			}
		},
		async clearChicks() {
			this.refreshIcon = true;
			this.isPreloading();
			this.RESET_CHICKS();


			await this.getChicks({
				...this.jsonSearchChicks,
				chicks_id: [],
				start_dob: this.start_date ? this.start_date : null,
				end_dob: this.end_date ? end_date : null,
				condition: this.status,
			});
			this.isPreloading(false);
			setTimeout(() => {
				this.refreshIcon = false;
			}, 500);
		},
		CloseModalNotepad() {
			this.SET_SEARCH_CHICK([]);
		},
		filtersDeads() {
			this.SET_SEARCH_CHICK(this.searchChicks.filter((it) => ![3, 4].includes(it.status_id)));
		},
		filterAll() {
			this.SET_SEARCH_CHICK([]);
		},
	},
};
</script>

<style lang="scss">
.chick-grid {
	display: flex;
	background: #fafafa;
	flex-wrap: wrap;
	padding: 2rem;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
	position: relative;
	.icon-invalid-chicks {
		position: absolute;
		top: 1rem;
		right: 1rem;
	}
	.chick-item {
		// background: #7367f0;
		width: auto;
		position: relative;
		padding: 0.3rem 0.6rem;
		border-radius: 4px;
		margin-right: 1rem;
		margin-bottom: 1rem;
		&:last-child {
			// margin-bottom: 0;
		}
		p {
			color: #fff;
			font-weight: bolder;
		}
		.close {
			z-index: 100;
			position: absolute;
			top: -10px;
			right: -10px;
			background: rgb(255, 51, 1);
			height: 20px;
			width: 20px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: 0.5s all ease-in-out;
			cursor: pointer;
			.icon {
				color: #fff;
			}
		}
	}
}
.icon-invalid {
	border: 1px solid #ea5455;
	border-radius: 4px;
	// background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ea5455' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ea5455' stroke='none'/%3e%3c/svg%3e);
	background-repeat: no-repeat;
	background-size: calc(0.725em + 0.438rem) calc(0.725em + 0.438rem);
}
.icon {
	transition: 0.5s all ease-in-out;
}
.icon-refresh {
	transform: rotate(180deg);
}
.container-cantidad-pollos {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	padding: 0 1.5rem 1rem 1rem;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	flex-direction: column;
	p {
		margin-top: 1rem;
		padding: 0.2rem 0.5rem;
		background: #7367f0;
		border-radius: 4px;
		width: auto !important;
		color: #fff;
		font-weight: bold;
	}
}
.circle {
	height: 8px;
	width: 8px;
	border-radius: 50%;
	background: beige;
	margin-top: -2px;
}
</style>
