<template>
	<div>
		<Transition>
			<div class="card p-2 mt-1" v-if="changePlate && !recursive">
				<div class="d-flex justify-content-end">
					<button class="btn btn-info mr-1" @click="cleanChangesInputsPlate">Descartar cambios</button>
					<button class="btn btn-primary" @click="saveChangesInputsPlate">Guardar</button>
				</div>
			</div>
		</Transition>
		<Transition>
			<div class="card p-2 mt-1" v-if="changePlateRecursvie && recursive">
				<div class="d-flex justify-content-end">
					<button class="btn btn-info mr-1" @click="cleanChangesInputsPlate">Descartar cambios</button>
					<button class="btn btn-primary" @click="saveChangesInputsPlate">Guardar</button>
				</div>
			</div>
		</Transition>
		<div class="card mb-0">
			<div v-if="!recursive" class="mx-2 m-2 position-relative">
				<b-row>
					<b-col
						cols="12"
						md="12"
						lg="4"
						class="d-flex flex-column align-items-start mt-3 justify-content-between"
					>
						<span class="text-muted">
							Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros
						</span>
					</b-col>
					<!-- Pagination -->
					<b-col cols="12" md="12" lg="8" class="flex-nowrap">
						<div class="flex flex-wrap cross-center w-full gap-x-3 xt-search-input justify-content-end">
							<div class="xt-search-input__paginate">
								<b-pagination
									v-model="current_page"
									:total-rows="total_data"
									:per-page="perpage"
									first-number
									last-number
									class=""
									prev-class="prev-item"
									next-class="next-item"
								>
									<template #prev-text>
										<feather-icon icon="ChevronLeftIcon" size="18" />
									</template>
									<template #next-text>
										<feather-icon icon="ChevronRightIcon" size="18" />
									</template>
								</b-pagination>
							</div>
							<div class="xt-search-input__search">
								<div class="search-container-type">
									<h4 class="mb-0 mr-1" style="min-width: 9ch">Buscar por:</h4>
									<div>
										<b-form-checkbox
											v-model="statusSearch"
											value="correlative"
											unchecked-value="correlative"
										>
											Cintillo
										</b-form-checkbox>
										<b-form-checkbox
											v-model="statusSearch"
											value="specimen"
											unchecked-value="specimen"
										>
											Madrilla / Padrillo
										</b-form-checkbox>
									</div>
								</div>
								<div class="search-container-value">
									<b-form-input
										type="search"
										class="search-input"
										@keyup.enter="init"
										v-model="search"
										:placeholder="
											statusSearch == 'correlative' ? 'Cintillo' : 'Madrilla / Padrillo'
										"
										style="width: 200px"
									/>
									<div class="search-btn">
										<button @click="init" class="btn btn-primary w-75">Buscar</button>
										<FeatherIcon
											@click="changeOpenSidebar"
											icon="FilterIcon"
											size="20"
											class="w-25 text-primary cursor-pointer"
										/>
									</div>
								</div>
							</div>
						</div>
					</b-col>
				</b-row>
				<FeatherIcon
					@click="showInfoRequiredInfo"
					icon="InfoIcon"
					size="20"
					class="d-none d-md-flex position-absolute text-primary cursor-pointer"
					:style="{ top: 0, left: 0 }"
				/>
			</div>
			<div v-else class="mx-2 m-2"></div>

			<div class="table-responsive mb-0">
				<b-table-simple sticky-header class="table mb-0" :style="{ minHeight: '70vh' }">
					<b-thead>
						<b-tr>
							<b-th
								class="bg-white p-0"
								v-if="title.visible"
								v-for="(title, key) in headTable"
								:key="title.key"
							>
								<div class="d-flex flex-column align-items-center justify-content-end w-100">
									<div
										class="d-flex justify-content-center align-items-center p-1 w-100"
										:style="{ backgroundColor: '#F4F1F7', height: '50px' }"
									>
										<div
											v-if="title.key != 'c.status_id' && title.key != 'c.gender'"
											@click="setOrderBy(title.key)"
											class="d-flex align-items-center justify-content-center cursor-pointer"
										>
											<p class="mb-0 mr-1">{{ title.title }}</p>
											<FeatherIcon
												v-if="orderBy == title.key"
												:icon="order == 'asc' ? 'ArrowUpCircleIcon' : 'ArrowDownCircleIcon'"
												size="18"
											/>
										</div>

										<div
											v-if="!changeCheckboxDead && title.key == 'c.status_id'"
											@click="setOrderBy(title.key)"
											class="d-flex align-items-center justify-content-center cursor-pointer"
										>
											<p class="mb-0 mr-1">{{ title.title }}</p>
											<FeatherIcon
												v-if="orderBy == title.key"
												:icon="order == 'asc' ? 'ArrowUpCircleIcon' : 'ArrowDownCircleIcon'"
												size="18"
											/>
										</div>

										<div
											v-if="!changeCheckboxGender && title.key == 'c.gender'"
											@click="setOrderBy(title.key)"
											class="d-flex align-items-center justify-content-center cursor-pointer"
										>
											<p class="mb-0 mr-1">{{ title.title }}</p>
											<FeatherIcon
												v-if="orderBy == title.key"
												:icon="order == 'asc' ? 'ArrowUpCircleIcon' : 'ArrowDownCircleIcon'"
												size="18"
											/>
										</div>

										<div
											v-if="changeCheckboxGender && title.key == 'c.gender' && status == 1"
											class="d-flex flex-column justify-content-center align-items-center"
										>
											<v-select
												:id="`global-dead-select-status`"
												v-model="changeMasiveGender"
												:options="genderArray"
												:clearable="false"
												transition=""
												:reduce="(option) => option.id"
												:disabled="[2, 3].includes(status)"
												@input="updateGenderMassively"
												:style="{ width: '140px' }"
												class="mr-1 bg-white"
												placeholder="Seleccinar genero"
											>
												<template #option="{ label, color }">
													<div class="selected d-center d-flex align-items-center">
														<div class="circle" :style="{ background: color }"></div>
														<p>{{ label }}</p>
													</div>
												</template>

												<template #selected-option="{ label, color }">
													<div class="selected d-center d-flex align-items-center">
														<div class="circle" :style="{ background: color }"></div>
														<p>{{ label }}</p>
													</div>
												</template>
											</v-select>
										</div>

										<div
											v-if="changeCheckboxDead && title.key == 'c.status_id' && status == 1"
											class="d-flex flex-column justify-content-center align-items-center"
										>
											<v-select
												:id="`global-gender-select-status`"
												v-model="globalDeadStatus"
												:options="statusArrayHead"
												:clearable="false"
												transition=""
												:reduce="(option) => option.id"
												:disabled="[2, 3].includes(status)"
												@input="globalDeadStatusModalView"
												:style="{ width: '140px' }"
												class="bg-white"
											>
												<template #option="{ label, color }">
													<div class="selected d-center d-flex align-items-center">
														<div class="circle" :style="{ background: color }"></div>
														<p>{{ label }}</p>
													</div>
												</template>

												<template #selected-option="{ label, color }">
													<div class="selected d-center d-flex align-items-center">
														<div class="circle" :style="{ background: color }"></div>
														<p>{{ label }}</p>
													</div>
												</template>
											</v-select>
										</div>

										<b-form-checkbox
											@input="checkGlobalDead"
											v-if="title.key == 'c.status_id' && status == 1"
											v-model="globalDead"
											name="check-button"
											class="ml-1"
										></b-form-checkbox>

										<b-form-checkbox
											@input="checkGlobalGender"
											v-if="title.key == 'c.gender' && status == 1"
											v-model="globalGender"
											name="check-button"
											class="ml-1"
										></b-form-checkbox>
										<!-- {{globalDead}} -->
									</div>
								</div>
							</b-th>
						</b-tr>
					</b-thead>

					<b-tbody>
						<b-tr
							v-if="(!recursive ? chicks : chicks_recursive).length > 0"
							class="row-table"
							:class="[item.check_retirement || item.plate_edit_status ? 'change-plate' : '']"
							v-for="(item, index) in !recursive ? chicks : chicks_recursive"
							:key="index"
						>
							<b-td v-if="retirement.start">
								<div class="d-flex align-items-center justify-content-center">
									<div class="check-valid">
										<b-form-checkbox
											class="text-center d-flex justify-content-center align-items-center"
											v-model="item.check_retirement"
											@change="
												addRetirementChick({
													chicken: item,
													key: index,
													type: 'machos',
													retirement_check: item.check_retirement,
												})
											"
											:disabled="![1, 2].includes(item.gender_id)"
										></b-form-checkbox>
									</div>
								</div>
							</b-td>

							<b-td class="text-center">
								<div class="d-flex justify-content-center align-items-center">
									<p class="mb-0">{{ item.correlative }}</p>
									<div
										class="d-flex justify-content-center align-items-center gap-x-2 ml-1"
										v-if="!recursive && status == 1"
									>
										<div
											v-b-tooltip.hover
											title="Enfermeria | Pollo en espera"
											v-if="item.nursing_status_id == 1"
										>
											<v-icon
												name="md-healthandsafety-outlined"
												scale="1"
												class="p-0 text-info"
											/>
										</div>
										<div
											v-b-tooltip.hover
											title="Enfermeria | Pollo en proceso"
											v-else-if="item.nursing_status_id == 2"
										>
											<v-icon
												name="md-healthandsafety-outlined"
												scale="1"
												class="p-0 text-success"
											/>
										</div>
										<b-button
											class="btn-icon"
											size="sm"
											variant="flat-primary"
											@click="
												;(addNursery.info = { chick: item }),
													(addNursery.show = !addNursery.show)
											"
											v-if="!isInvitado && item.nursing_status_id === null"
											v-b-tooltip.hover
											title="ENVIAR A ENFERMERIA"
										>
											<v-icon
												name="md-healthandsafety-outlined"
												scale="1.2"
												class="text-danger"
											/>
										</b-button>
									</div>
								</div>
							</b-td>

							<b-td v-if="status == 1 && !recursive" class="">
								<p
									v-if="item.brothers && item.brothers.length"
									@click="
										Number(item.brothers.length - 1) + Number(item.brothers_specimens) > 0 &&
											setChickBrothers(item, 1)
									"
									class="text-center mb-0 font-weight-bolder"
									:class="[
										Number(item.brothers.length - 1) + Number(item.brothers_specimens) > 0
											? 'text-primary cursor-pointer'
											: 'text-danger',
									]"
									v-b-tooltip.hover
									:title="`Pollos: ${Number(item.brothers.length - 1)}, Ejemplares: ${Number(
										item.brothers_specimens
									)}`"
								>
									{{ Number(item.brothers.length - 1) + Number(item.brothers_specimens) }}
								</p>
							</b-td>

							<b-td class="" v-if="status == 1">
								<p v-if="status == 3" class="mb-0">{{ item.plate || "Sin placa" }}</p>
								<div v-else class="text-center d-flex justify-content-center">
									<!--  -->
									<b-form-input
										:disabled="item.gender == 'SIN ASIGNAR' || item.status_id == 5"
										class="input-plate-chick h-full"
										v-model="item.plate"
										type="text"
										placeholder="Placa"
										@input="
											() => {
												if (item.plate != item.plate_edit) {
													item.plate_edit_status = true
												} else {
													item.plate_edit_status = false
												}
											}
										"
									></b-form-input>
								</div>
							</b-td>

							<b-td v-if="status == 2">
								<div class="text-center d-flex justify-content-center">
									<b-form-input
										disabled
										class="input-plate-chick h-full"
										type="text"
										placeholder="Placa"
										:value="item.plate || 'Sin placa'"
									></b-form-input>
								</div>
							</b-td>

							<b-td>
								<div class="d-flex align-items-center justify-content-center">
									<v-select
										:id="`${item.id}-select-gender`"
										v-model="item.gender"
										:options="genderArray"
										:clearable="false"
										transition=""
										:reduce="(option) => option.id"
										:disabled="
											status == 3 ||
											(['MACHO', 'HEMBRA'].includes(item.gender) && item.plate != '')
										"
										@input="updateChickGenderAndStatus(item, index)"
										:style="{ width: '140px' }"
									>
										<template #option="{ label, color }">
											<div class="selected d-center d-flex align-items-center">
												<div class="circle" :style="{ background: color }"></div>
												<p>{{ label }}</p>
											</div>
										</template>

										<template #selected-option="{ label, color }">
											<div class="selected d-center d-flex align-items-center">
												<div class="circle" :style="{ background: color }"></div>
												<p>{{ label }}</p>
											</div>
										</template>
									</v-select>

									<b-form-checkbox
										@input="updateGlobalGender(item)"
										class="ml-1"
										v-model="item.status_gender_massively"
										:disabled="item.status_id == 5"
										v-if="status == 1"
									></b-form-checkbox>
								</div>
							</b-td>

							<b-td class="text-center">
								<div class="d-flex align-items-center justify-content-center">
									<v-select
										:id="`${item.id}-select-status`"
										v-model="item.status_id"
										:options="statusArray"
										:clearable="false"
										transition=""
										:reduce="(option) => option.id"
										:disabled="[2, 3].includes(status) || item.status_id == 5"
										:selectable="(opt) => opt.id != 5"
										@input="deadChick(item, $event)"
										:style="{ width: '160px' }"
									>
										<template #option="{ label, color }">
											<div class="selected d-center d-flex align-items-center">
												<div class="circle" :style="{ background: color }"></div>
												<p>{{ label }}</p>
											</div>
										</template>

										<template #selected-option="{ label, color }">
											<div class="selected d-center d-flex align-items-center">
												<div class="circle" :style="{ background: color }"></div>
												<p>{{ label }}</p>
											</div>
										</template>
									</v-select>

									<b-form-checkbox
										@input="updateGlobalDead(item.status_dead_massively)"
										class="ml-1"
										v-model="item.status_dead_massively"
										:disabled="item.status_id == 5"
										v-if="status == 1"
									></b-form-checkbox>
									<FeatherIcon
										v-b-tooltip.hover
										title="Revivir pollo"
										@click="resurrect(item)"
										icon="PlusSquareIcon"
										class="cursor-pointer text-danger ml-50"
										v-if="status == 3"
									/>
								</div>
							</b-td>

							<b-td class="text-center p-0 m-0">
								<div class="d-flex text-center justify-content-center">
									<div class="container-item-dob">
										<div v-if="!item.dob_edit" class="d-flex align-items-center">
											<p class="mb-0">{{ item.dob }}</p>
											<!-- <FeatherIcon
												v-if="status != 3"
												@click="item.dob_edit = true"
												icon="EditIcon"
												class="edit-dob cursor-pointer ml-50"
												color="orange"
											/> -->
										</div>
										<div v-else class="w-100">
											<date-picker
												@input="(date) => updateChickDob(item, index, date)"
												format="DD-MM-YYYY"
												v-model="item.dob"
												valueType="format"
												:clearable="false"
												:shortcuts="shortcutsDateTime"
											></date-picker>
										</div>
									</div>
								</div>
							</b-td>

							<b-td v-if="[1, 2].includes(status)" class="text-center">
								<div class="d-flex align-items-center gap-x-1" v-if="item.corral">
									<div class="text-center" v-if="item.corral">
										<p
											class="mb-0"
											:class="item.is_corral_active ? '' : 'text-danger'"
											v-if="item.corral.counter_corrales > 0"
										>
											{{ item.is_corral_active ? item.corral.code : "RETIRADO" }}
										</p>

										<p class="mb-0 font-small-3 font-weight-bold" v-if="item.is_corral_active">
											{{ item.corral.start | myGlobalDayShort }}
										</p>
									</div>
									<feather-icon
										class="text-warning clickable"
										icon="ListIcon"
										size="20"
										v-b-tooltip="`Tracking corrales`"
										@click="
											;(corralTracking.info = item), (corralTracking.show = !corralTracking.show)
										"
										v-if="item.corral.counter_corrales > 0"
									/>
								</div>
								<p v-else>---</p>
							</b-td>
							<!-- 
							<b-td v-if="!recursive">
								<div class="d-flex justify-content-center align-items-center gap-x-2">
									<span v-if="item.nursing_status_id == 1">PENDIENTE</span>
									<span v-else-if="item.nursing_status_id == 2">ACEPTADO</span>
									<b-button
										class="btn-icon"
										size="sm"
										variant="flat-primary"
										@click="
											;(addNursery.info = { chick: item }), (addNursery.show = !addNursery.show)
										"
										v-if="!isInvitado && item.nursing_status_id === null"
									>
										<feather-icon icon="PlusIcon" />
									</b-button>
									<b-button
										class="btn-icon"
										size="sm"
										variant="flat-secondary"
										@click="
											;(nurseryTracking.info = {
												chick: item,
												_chick_id: item.id,
											}),
												(nurseryTracking.show = !nurseryTracking.show)
										"
									>
										<feather-icon icon="ListIcon" />
									</b-button>
								</div>
							</b-td> -->

							<b-td v-if="!recursive">
								<SpecimenPlate
									:specimen="{
										id: item.madrilla.id,
										plate: item.madrilla.plate,
										alias: item.madrilla.alias,
										thumb: item.madrilla.thumb,
										image: item.madrilla.image,
									}"
								/>
							</b-td>
							<b-td v-if="!recursive">
								<SpecimenPlate
									:specimen="{
										id: item.padrillo.id,
										plate: item.padrillo.plate,
										alias: item.padrillo.alias,
										thumb: item.padrillo.thumb,
										image: item.padrillo.image,
									}"
								/>
							</b-td>
							<b-td v-if="!recursive && status != 2">
								<b-button
									class="btn-icon"
									variant="flat-info"
									size="sm"
									v-b-tooltip
									title="Tracking Clinico"
									@click="
										;(clinicalTracking.info = item),
											(clinicalTracking.show = !clinicalTracking.show)
									"
								>
									<feather-icon icon="ListIcon" />
								</b-button>
							</b-td>
						</b-tr>
						<b-tr v-if="!recursive ? chicks.length == 0 : chicks_recursive.length == 0">
							<b-td colspan="7" class="text-center">
								<p class="mb-0">Sin cintillos encontrados</p>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>

				<div class="ml-2 mt-1 mb-1">
					<h5 class="mb-0">Enfermería</h5>

					<div class="d-flex align-items-center justify-content-start mt-50">
						<v-icon name="md-healthandsafety-outlined" scale="1" class="p-0 text-danger" />
						<small class="mb-0 ml-25">Enviar pollo a enfermería</small>
					</div>

					<div class="d-flex align-items-center justify-content-start mt-50">
						<v-icon name="md-healthandsafety-outlined" scale="1" class="p-0 text-info" />
						<small class="mb-0 ml-25">Pollo en espera</small>
					</div>

					<div class="d-flex align-items-center justify-content-start mt-50">
						<v-icon name="md-healthandsafety-outlined" scale="1" class="p-0 text-success" />
						<small class="mb-0 ml-25">Pollo en proceso</small>
					</div>
				</div>
			</div>
		</div>

		<b-sidebar v-model="sidebarOptionsChickensList.open">
			<div class="container-sidebar">
				<h3 class="mb-1">Género</h3>

				<div class="form-control-input">
					<!-- <p class="mb-0">Genero:</p> -->
					<b-form-group class="mt-25">
						<b-form-radio v-model="selectGender" name="some-radios" :value="1">MACHO</b-form-radio>
						<b-form-radio v-model="selectGender" name="some-radios" :value="2">HEMBRA</b-form-radio>
						<b-form-radio v-model="selectGender" name="some-radios" :value="3">SIN ASIGNAR</b-form-radio>
					</b-form-group>
				</div>

				<button @click="init" class="btn btn-primary btn-buscar-pl mt-2">Filtrar datos</button>
				<button @click="resetFilters()" class="btn btn-outline-info btn-buscar-pl mt-2 ml-1">
					Reset filtros
				</button>
			</div>
		</b-sidebar>

		<b-modal
			v-model="viewModalBrothersChicks.show"
			:hide-footer="true"
			:title="viewModalBrothersChicks.title"
			size="xl"
			v-if="viewModalBrothersChicks.show"
		>
			<BrothersByChick :ids="viewModalBrothersChicks.ids" @set-reload="refreshBrothersChicks" />
		</b-modal>

		<AddDeadCPCA
			@set-reload="changeReloadRecursive = true"
			v-if="selectDeadChick.open"
			:postura="{
				ca: {
					id: selectDeadChick.ca.id,
					plate: selectDeadChick.ca.plate,
					status: selectDeadChick.ca.status,
				},
				cintillos: {
					cp_id: null,
				},
				egg_lote_id: null,
			}"
			@hidden="closeAddDeadCPCA"
		/>

		<DeadsMassivelyChicksCA
			:chicksDeads="chicksDeads"
			:open="globalDeadStatusModal"
			v-if="globalDeadStatusModal"
			@refresh="refreshDeadGlobal"
			:recursive="recursive"
			:status_id="selectDeadChick.ca.status"
		/>

		<b-modal
			@hide="hideModalRetirement"
			hide-footer
			scrollable
			size="xmd"
			v-model="retirement.show"
			title="Agregar placa"
		>
			<RetirementChicksByCorral
				v-if="retirement.show"
				:chicks="retirement.selects"
				@deleteAddRetirementChick="deleteAddRetirementChick"
				@refresh="refreshRetirementChicks"
				@addRetirementChick="addRetirementChick"
			/>
		</b-modal>
		<CorralTrackingModal
			:show="corralTracking.show"
			:info="corralTracking.info"
			@closing="corralTracking.show = false"
		/>
		<ClinicalTrackingChick
			:show="clinicalTracking.show"
			:info="clinicalTracking.info"
			@closing="clinicalTracking.show = false"
		/>
		<NurseryTracking
			:show="nurseryTracking.show"
			:info="nurseryTracking.info"
			@closing="nurseryTracking.show = false"
		/>
		<AddChicksNursery
			:show="addNursery.show"
			:info="addNursery.info"
			@closing="addNursery.show = false"
			@refresh="init"
		/>
	</div>
</template>

<script>
import deadsService from "@/services/deads.service"
import AddDeadCPCA from "@/views/amg/encaste/lotes/modals/AddDeadCPCA.vue"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import encasteChicksService from "@/services/encaste/chicks.service"
import DeadsMassivelyChicksCA from "./modals/DeadsMassivelyChicksCA.vue"
import DatePicker from "vue2-datepicker"
import "vue2-datepicker/index.css"
import moment from "moment"
import BrothersByChick from "./modals/BrothersByChick.vue"
import NotepadItemsSearch from "./modals/NotepadItemsSearch.vue"
import { mapActions, mapState, mapMutations } from "vuex"
import chicksService from "@/services/encaste/chicks.service"
import corralService from "@/services/encaste/corral.service.js"
import RetirementChicksByCorral from "../corrales/components/RetirementChicksByCorral.vue"
import CorralTrackingModal from "@/views/amg/encaste/corrales/components/CorralTrackingModal.vue"
import ClinicalTrackingChick from "./modals/ClinicalTrackingChick.vue"
import NurseryTracking from "@/views/amg/nursery/components/TrackingModal.vue"
import AddChicksNursery from "@/views/amg/nursery/components/AddChicksNursery.vue"

export default {
	name: "ChickensList",
	components: {
		SpecimenPlate,
		DatePicker,
		BrothersByChick,
		AddDeadCPCA,
		NotepadItemsSearch,
		DeadsMassivelyChicksCA,
		RetirementChicksByCorral,
		CorralTrackingModal,
		ClinicalTrackingChick,
		AddChicksNursery,
		NurseryTracking,
	},
	props: {
		status: {
			type: Number,
			default: 1,
		},
		ids: {
			type: Object,
			default: () => ({}),
		},
		recursive: {
			type: Boolean,
			default: false,
		},
	},
	async mounted() {
		await this.init()
	},

	data() {
		return {
			headTable: [
				{ key: "c.correlative", title: "#Cintillo", visible: true },
				{ key: "count_brothers", title: "Hermanos", visible: this.status == 1 && !this.recursive },
				{ key: "c.assigned_plate", title: "Placa", visible: [1, 2].includes(this.status) },
				{ key: "c.gender", title: "Genero", visible: true },
				{ key: "c.status_id", title: "Estado", visible: true },
				{ key: "c.dob", title: "Nacimiento", visible: true },
				{ key: "p.code", title: "Corrales", visible: [1, 2].includes(this.status) },
				// { key: "nursery", title: "Enfermeria", visible: !this.recursive },q
				{ key: "madrilla_plate", title: "Madrilla", visible: !this.recursive },
				{ key: "padrillo_alias", title: "Padrillo", visible: !this.recursive },
				{ key: "actions", title: "Acciones", visible: !this.recursive && this.status != 2 },
			],
			genderArray: [
				{
					id: "MACHO",
					label: "MACHO",
					color: "#466AFF",
					code: 1,
				},
				{
					id: "HEMBRA",
					label: "HEMBRA",
					color: "#F941FF",
					code: 2,
				},
				{
					id: "SIN ASIGNAR",
					label: "SIN ASIGNAR",
					color: "#C4C4C4",
					code: 3,
				},
			],
			statusArray: [
				// {
				// 	id: 1,
				// 	label: "BORRADOR",
				// 	color: "#FF5722",
				// },
				{
					id: 2,
					label: "ACTIVO",
					color: "#66b366",
				},
				{
					id: 3,
					label: "MUERTO",
					color: "#FF5722",
				},
				{
					id: 4,
					label: "DEPURADO",
					color: "#FF5722",
				},
				{
					id: 5,
					label: "ENFERMO",
					color: "#C4C4C4",
				},
			],
			statusSearch: "correlative",
			search: "",
			current_page: 1,
			perpage: 50,
			orderBy: "correlative",
			order: "asc",
			momentFormat: {
				stringify: (date) => {
					console.log("A0", date)
					console.log("A1", date, moment(date).format("DD-MM-YYYY"))
					return date ? moment(date).format("DD-MM-YYYY") : ""
				},
				parse: (value) => {
					console.log("A2", value, moment(value, "DD-MM-YYYY").toDate())
					return value ? moment(value, "DD-MM-YYYY").toDate() : null
				},
				getWeek: (date) => {
					console.log("A3")
					return // a number
				},
			},
			chickens: [],
			sidebarOptionsChickensList: {
				open: false,
				title: "Opciones",
			},
			bornChickStartDate: null,
			bornChickEndDate: null,
			selectGender: null,
			selectDeadChick: {
				open: false,
				ca: {
					id: null,
					plate: null,
				},
			},
			shortcutsDateTime: [
				{
					text: "Today",
					onClick() {
						const date = new Date()
						// return a Date
						return date
					},
				},
				{
					text: "Yesterday",
					onClick() {
						const date = new Date()
						date.setTime(date.getTime() - 3600 * 1000 * 24)
						return date
					},
				},
			],
			viewInfo: {
				show: false,
				text: null,
			},
			viewModalBrothersChicks: {
				show: false,
				title: "Hermanos",
				ids: {
					chick_id: null,
					postura_id: null,
					brothers: [],
					step_brothers: [],
				},
			},
			changeReloadRecursive: false,
			globalDead: false,
			globalDeadStatus: 2,
			globalDeadStatusModal: false,
			chicksDeads: [],
			changeMasiveGender: null,
			globalGender: false,
			retirement: {
				start: false,
				show: false,
				selects: [],
			},
			corralTracking: { show: false, info: {} },
			clinicalTracking: { show: false, info: {} },
			nurseryTracking: { show: false, info: {} },
			addNursery: { show: false, info: {} },
		}
	},
	computed: {
		...mapState("encasteChicks", [
			"chicks",
			"chicks_recursive",
			"start_page",
			"to_page",
			"total_data",
			"jsonSearchChicks",
			"jsonSearchChicksRecursive",
			"reload_chicks",
			"start_date",
			"end_date",
		]),
		changePlate() {
			const chickVal = this.chicks.filter((it) => it.plate_edit_status == true).length > 0 ? true : false

			return chickVal
		},
		changePlateRecursvie() {
			const chickRecursiveVal =
				this.chicks_recursive.filter((it) => it.plate_edit_status == true).length > 0 ? true : false

			return chickRecursiveVal
		},
		changeCheckboxDead() {
			//chicks_recursive
			const chickVal =
				this.chicks && this.chicks.filter((it) => it.status_dead_massively == true).length > 0 ? true : false
			const chickRecVal =
				this.chicks_recursive &&
				this.chicks_recursive.filter((it) => it.status_dead_massively == true).length > 0
					? true
					: false
			return chickVal || chickRecVal
		},
		changeCheckboxGender() {
			//chicks_recursive
			const chickVal =
				this.chicks && this.chicks.filter((it) => it.status_gender_massively == true).length > 0 ? true : false
			const chickRecVal =
				this.chicks_recursive &&
				this.chicks_recursive.filter((it) => it.status_gender_massively == true).length > 0
					? true
					: false
			return chickVal || chickRecVal
		},
		statusArrayHead() {
			return this.statusArray.filter((opt) => opt.id != 5)
		},
	},
	methods: {
		...mapMutations("encasteChicks", [
			"SET_JSON_SEARCH_CHICKS",
			"SET_JSON_SEARCH_CHICKS_RECURSIVE",
			"SET_CHICKS",
			"SET_CHICKS_RECURSIVE",
			"GET_CHICKS_RECURSIVE",
			"RESET_STATUS_MASSIVELY",
			"TOGGLE_RELOAD_CHICKS",
			"TOGGLE_RELOAD_COUNTERS",
			"DECREMENT_TOTAL_CHICKS",
			"RESET_CHICKS",
		]),
		...mapActions("encasteChicks", ["getChicks", "getChicksRecursive"]),
		async init() {
			this.isPreloading()
			if (this.status == 2 || this.status == 3) {
				this.RESET_CHICKS()
			}
			if (!this.recursive) {
				this.SET_JSON_SEARCH_CHICKS({
					...this.jsonSearchChicks,
					type_search: this.statusSearch == "correlative" ? 1 : 2,
					search: this.search,
					condition: this.status,
					perpage: this.perpage,
					page: this.current_page,
					order_by: this.orderBy,
					order: this.order,
					start_dob: this.start_date ? this.start_date : null,
					end_dob: this.end_date ? this.end_date : null,
					gender_id: this.selectGender,
					chicks_id: this.ids.type
						? this.ids.type == 1
							? this.ids.brothers && this.ids.brothers.filter((item) => item != this.ids.chick_id && item)
							: this.ids.step_brothers &&
							  this.ids.step_brothers.filter((item) => item != this.ids.chick_id && item)
						: this.jsonSearchChicks.chicks_id,
				})
				await this.getChicks(this.jsonSearchChicks)
				if (this.retirement.selects.length > 0) {
					this.retirement.selects.forEach((chick) => {
						this.refreshChick(chick, true)
					})
				}
			} else {
				this.SET_JSON_SEARCH_CHICKS_RECURSIVE({
					...this.jsonSearchChicksRecursive,
					type_search: this.statusSearch == "correlative" ? 1 : 2,
					search: this.search,
					condition: this.status,
					perpage: this.perpage,
					page: this.current_page,
					order_by: this.orderBy,
					order: this.order,
					start_dob: null,
					end_dob: null,
					gender_id: this.selectGender,
					chicks_id: this.ids.type
						? this.ids.type == 1
							? this.ids.brothers && this.ids.brothers.filter((item) => item != this.ids.chick_id && item)
							: this.ids.step_brothers &&
							  this.ids.step_brothers.filter((item) => item != this.ids.chick_id && item)
						: this.jsonSearchChicksRecursive.chicks_id,
				})
				await this.getChicksRecursive(this.jsonSearchChicksRecursive)

				console.log("chicks recursive", this.chicks_recursive.length)

				if (this.chicks_recursive.length == 0) {
					this.viewModalBrothersChicks.show = false
					this.viewModalBrothersChicks.ids = []
				} else {
					if (this.retirement.selects.length > 0) {
						this.retirement.selects.forEach((chick) => {
							this.refreshChick(chick, true)
						})
					}
				}
			}

			this.isPreloading(false)
		},
		setOrderBy(key) {
			if (this.orderBy == key) {
				this.order = this.order == "asc" ? "desc" : "asc"
			} else {
				this.orderBy = key
				this.order = "asc"
			}
		},
		cleanChangesInputsPlate() {
			this.SET_CHICKS(
				this.chicks.map((chicken) => {
					return {
						...chicken,
						plate: chicken.plate_edit,
						plate_edit_status: false,
						dob_edit: false,
					}
				})
			)
		},
		changeOpenSidebar() {
			this.sidebarOptionsChickensList.open = !this.sidebarOptionsChickensList.open
			this.sidebarOptionsChickensList.title = this.sidebarOptionsChickensList.open ? "Cerrar" : "Opciones"
		},
		async saveChangesInputsPlate() {
			this.isPreloading()
			let nullValueValidate = false
			//chicks_recursive
			const data = (!this.recursive ? this.chicks : this.chicks_recursive)
				.filter((chicken) => chicken.plate != chicken.plate_edit)
				.map((item) => {
					item.plate && item.plate != "null" ? (nullValueValidate = true) : (nullValueValidate = false)
					return {
						id: item.id,
						plate: item.plate,
					}
				})

			if (nullValueValidate) {
				this.isPreloading()
				const { message, repeat } = await corralService.insertDewornChiks({
					chicks: data,
				})
				this.isPreloading(false)

				if (repeat.length > 0) {
					await this.$swal({
						title: "Placas repetidas",
						text: `Las siguientes placas ya se encuentran registradas: ${repeat.join(", ")}`,
						icon: "warning",
						confirmButtonText: "Aceptar",
					})
					let ck = (!this.recursive ? this.chicks : this.chicks_recursive)
						.map((chicken) => {
							return {
								...chicken,
								plate_edit_status: repeat.includes(chicken.plate) ? true : false,
								plate: !chicken.plate_edit_status ? chicken.plate_edit : chicken.plate,
							}
						})
						.filter((item) => {
							return (
								[1, 2].includes(this.status) &&
								((this.status == 1 ? item.plate == "" : item.plate != "") ||
									item.plate_edit_status == true)
							)
						})
					ck = ck.map((chicken) => {
						return {
							...chicken,
							plate: !chicken.plate_edit_status ? chicken.plate_edit : chicken.plate,
						}
					})
					if (this.recursive) {
						this.SET_CHICKS_RECURSIVE(ck)
					} else {
						this.SET_CHICKS(ck)
					}
					this.$emit("refresh-counters")
				} else {
					console.log("./././")
					await this.$swal({
						title: "Placas asignadas",
						text: message,
						icon: "success",
						confirmButtonText: "Aceptar",
					})

					this.$emit("refresh-counters")

					await this.init()
				}

				this.showToast("Success", "top-right", "Pollos", "SuccessIcon", message)
			} else {
				this.showToast("info", "top-right", "Pollos", "InfoIcon", "No se puede actualizar una placa vacia.")
			}
			this.$emit("set-reload")
			this.isPreloading(false)

			// console.log("data", data);
		},
		async resetFilters() {
			this.statusSearch = "correlative"
			this.search = ""
			this.bornChickStartDate = null
			this.bornChickEndDate = null
			this.selectGender = null
			await this.init()
		},
		deadChick(item, status) {
			this.selectDeadChick.open = true
			this.selectDeadChick.ca = {
				id: item.id,
				plate: item.correlative,
				status: status,
			}
			// this.$emit("set-reload");
		},
		async closeAddDeadCPCA() {
			this.selectDeadChick.open = false
			this.selectDeadChick.ca = {
				id: null,
				plate: null,
			}

			this.globalDeadStatusModal = false
			this.globalDeadStatus = null

			// this.GET_CHICKS_RECURSIVE({ data: [] });

			this.SET_JSON_SEARCH_CHICKS_RECURSIVE({
				...this.jsonSearchChicksRecursive,
			})

			await this.init()

			// await this.getChicksRecursive(this.jsonSearchChicksRecursive);

			// if (this.changeReloadRecursive) {
			// 	this.$emit("refresh-counters");

			// } else {
			// 	this.SET_CHICKS(
			// 		this.chicks.map((chicken) => {
			// 			return {
			// 				...chicken,
			// 				status_id: 2,
			// 			};
			// 		})
			// 	);
			// }
		},
		async updateChickGenderAndStatus(item, index) {
			this.isPreloading()
			await encasteChicksService.updateChickGenderAndStatus({
				gender: item.gender,
				status: item.status,
				chick_id: item.id,
			})
			;(!this.recursive ? this.chicks : this.chicks_recursive)[index].gender_id = this.genderArray.find(
				(g) => g.id == item.gender
			).code
			this.isPreloading(false)

			await this.showToast("success", "top-right", "Pollos", "InfoIcon", "Género actualizado correctamente.")

			// if(this.recursive) {
			// 	this.changeReloadRecursive = true;
			// }
			this.$emit("set-reload")
		},
		async updateGenderMassively() {
			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) {
				this.changeMasiveGender = null
				return
			}

			this.isPreloading()
			const chicksGenderMasive = (this.recursive ? this.chicks_recursive : this.chicks)
				.filter((it) => it.status_gender_massively && this.changeMasiveGender != it.gender)
				.map((it) => {
					return {
						id: it.id,
						gender: this.changeMasiveGender == "MACHO" ? 1 : this.changeMasiveGender == "HEMBRA" ? 2 : 3,
					}
				})

			// Cuando hay cambios de genero diferente de los seleccionadoss
			if (chicksGenderMasive.length > 0) {
				const { message, status } = await chicksService.updateGenderMassively({
					chicks: chicksGenderMasive,
				})

				let ck = this.recursive ? this.chicks_recursive : this.chicks
				for (let index = 0; index < ck.length; index++) {
					if (ck[index].status_gender_massively) {
						ck[index].gender = this.changeMasiveGender
						ck[index].status_gender_massively = false
					}
				}

				if (status) {
					this.changeMasiveGender = null

					this.recursive ? this.SET_CHICKS(ck) : this.SET_CHICKS_RECURSIVE(ck)
					this.showToast("success", "top-right", "Pollos", "SuccessIcon", message)
				} else {
					this.showToast("info", "top-right", "Pollos", "WarningIcon", "error al actualizar el genero")
				}
			} else {
				this.changeMasiveGender = null
				this.showToast("success", "top-right", "Pollos", "SuccessIcon", "los pollos fueron actualizados")
			}
			this.isPreloading(false)
			this.globalGender = false
		},
		async updateChickDob(item, key, date) {
			this.isPreloading()
			await encasteChicksService.updateDobChick({
				chick_id: item.id,
				dob: `${date.split("-")[2]}-${date.split("-")[1]}-${date.split("-")[0]}`,
			})
			this.chickens[key].dob_edit = false
			this.isPreloading(false)
		},
		showInfoRequiredInfo() {
			this.$swal({
				title: "Información requerida",
				text: "Para poder asignar una placa a un pollo, es necesario que el pollo tenga un género.",
				icon: "info",
				confirmButtonText: "Aceptar",
			})
		},
		setChickBrothers(item, type) {
			this.viewModalBrothersChicks.show = true
			this.viewModalBrothersChicks.title = `POLLO ${item.correlative} / POSTURA ${item.code}`
			this.viewModalBrothersChicks.ids = {
				chick_id: item.id,
				postura_id: item.postura_id,
				brothers: item.brothers,
				step_brothers: item.step_brothers,
				type: type,
			}
		},
		updateGlobalDead(value) {},
		updateGlobalGender(item) {},
		checkGlobalDead() {
			// console.log("ADD", this.globalDead);

			if (this.recursive) {
				this.SET_CHICKS_RECURSIVE(
					this.chicks_recursive.map((item) => {
						return {
							...item,
							status_dead_massively: this.globalDead,
						}
					})
				)
			} else {
				this.SET_CHICKS(
					this.chicks.map((item) => {
						return {
							...item,
							status_dead_massively: this.globalDead,
						}
					})
				)
			}
		},

		checkGlobalGender() {
			// console.log("ADD", this.globalDead);

			if (this.recursive) {
				this.SET_CHICKS_RECURSIVE(
					this.chicks_recursive.map((item) => {
						return {
							...item,
							status_gender_massively: this.globalGender,
						}
					})
				)
			} else {
				this.SET_CHICKS(
					this.chicks.map((item) => {
						return {
							...item,
							status_gender_massively: this.globalGender,
						}
					})
				)
			}
		},

		refreshBrothersChicks() {
			this.changeReloadRecursive = true
			this.closeAddDeadCPCA()
			this.$emit("refresh-counters")
		},
		globalDeadStatusModalView() {
			;(this.selectDeadChick.ca.status = this.globalDeadStatus), (this.globalDeadStatusModal = true)

			this.chicksDeads = (this.recursive ? this.chicks_recursive : this.chicks)
				.filter((item) => item.status_dead_massively)
				.map((it) => {
					return {
						id: it.id,
						correlative: it.correlative,
						images: [],
						observation: null,
						reason_id: null,
					}
				})
		},
		refreshDeadGlobal() {
			// console.log('clos');
			// this.isPreloading();
			this.globalDeadStatusModal = false
			this.globalDeadStatus = 2
			// this.globalDead = false;
			//checkGlobalDead
			// this.$emit("set-reload");
			//globalDeadStatusModal
			this.chicksDeads = []

			// this.init();
		},
		async resurrect(item) {
			// console.log(item)
			// return
			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) return
			// Continuar peticion
			this.isPreloading()
			await deadsService.resurectAla({ ala_id: item.id })

			this.SET_CHICKS(this.chicks.filter((it) => it.id != item.id))

			this.TOGGLE_RELOAD_COUNTERS({ reload: true })

			this.DECREMENT_TOTAL_CHICKS()

			this.isPreloading(false)
			this.showToast("success", "top-right", "Muertos", "AlertCircleIcon", "Realizado exitosamente")
		},
		addRetirementChick({ chicken, key, type, retirement_check }) {
			// console.log("ADD", chicken);

			const chick = {
				check: false,
				check_deworm: false,
				correlative: chicken.correlative,
				gender: 1,
				id: chicken.id,
				image: null,
				key: 1,
				plate: null,
				specimen_id: null,
				status: 2,
				type: "machos",
				visible: retirement_check,
				code_corral: chicken.corral_code || chicken.code_corral,
			}

			if (retirement_check) {
				this.retirement.selects.push(chick)
			} else {
				const index = this.retirement.selects.findIndex((it) => it.id == chicken.id)
				this.retirement.selects.splice(index, 1)
			}

			const index = (this.recursive ? this.chicks_recursive : this.chicks).findIndex((ck) => ck.id == chicken.id)

			;(this.recursive ? this.chicks_recursive : this.chicks)[index].check_retirement = retirement_check

			// console.log("ck", this.posturas[key][type], chicken);
		},
		deleteAddRetirementChick(chick) {
			// console.log("chick", chick);
			this.retirement.selects = this.retirement.selects.filter((it) => it.id != chick.id)
			// this.refreshChick(chick)

			const index = (this.recursive ? this.chicks_recursive : this.chicks).findIndex((ck) => ck.id == chick.id)

			;(this.recursive ? this.chicks_recursive : this.chicks)[index].check_retirement = false
		},
		refreshChick(chick, check) {
			const index = (this.recursive ? this.chicks_recursive : this.chicks).findIndex((it) => it.id == chick.id)

			// console.log("CHICK", chick, index, this.chicks[index]);

			if (index != -1) {
				;(this.recursive ? this.chicks_recursive : this.chicks)[index].check_retirement = check
			}
		},
		async refreshRetirementChicks(repeat) {
			await this.init()
			this.$emit("set-reload")
			this.isPreloading(false)

			// console.log('retire', repeat, this.retirement.selects.length);
			this.retirement.selects = this.retirement.selects.filter((it) => repeat.includes(it.plate))

			if (this.retirement.selects.length == 0) {
				this.retirement.show = false
				this.retirement.start = false
				this.showToast("success", "top-right", "Retiro", "AlertCircleIcon", "Placas agregadas correctamente.")
			}
		},
		startRetirement() {
			this.retirement.start = true
			const index = this.headTable.findIndex((h) => h.id == 2)
			const indexBrothers = this.headTable.findIndex((h) => h.id == 3)
			// const indexPlate = this.headTable.findIndex((h) => h.id == 4);
			this.headTable[index].visible = true
			this.headTable[indexBrothers].visible = true
			// if (this.headTable[index].title == "Hermanos") {
			// 	this.headTable[index].visible = this.recursive ? false : this.status == 1;
			// }
			// this.headTable[indexPlate].title = "Hermanos";
			if (this.recursive) {
				this.headTable[2].visible = false
			}
		},
		cancelRetirement() {
			this.retirement.start = false
			const index = this.headTable.findIndex((h) => h.id == 2)
			const indexBrothers = this.headTable.findIndex((h) => h.id == 3)
			// const indexPlate = this.headTable.findIndex((h) => h.id == 4);
			this.headTable[index].visible = false
			this.headTable[index].title = "Hermanos"
			this.headTable[indexBrothers].visible = true
			// this.headTable[indexPlate].title = "Placa";
			if (this.retirement.selects.length > 0) {
				this.retirement.selects.forEach((chick) => {
					this.refreshChick(chick, false)
				})
			}
			this.retirement.selects = []
		},
		async hideModalRetirement() {
			// await this.init();

			if (this.recursive) {
				await this.init()

				console.log("recursive", this.recursive, this.retirement)

				// if (this.retirement.selects.length > 0) {
				// 	this.retirement.selects.forEach((chick) => {
				// 		this.refreshChick(chick, false);
				// 	});
				// }
			} else {
				await this.init()
			}
		},
	},
	watch: {
		async current_page() {
			await this.init()
		},
		async start_date() {
			await this.init()
		},
		async orderBy() {
			await this.init()
		},
		async order() {
			await this.init()
		},
		async search() {
			// if (this.search.length == 0) {
			// 	await this.init();
			// }
		},
		async "viewModalBrothersChicks.show"() {
			// if (!this.viewModalBrothersChicks.show) {
			// 	if (this.changeReloadRecursive) {
			// 		await this.init();
			// 	}
			// }
		},
		async changeReloadRecursive() {
			// console.log('RELOAD', this.changeReloadRecursive);
			// if (this.changeReloadRecursive) {
			// 	await this.init();
			// }
		},
		"jsonSearchChicks.search"() {
			this.search = this.jsonSearchChicks.search
		},
		async reload_chicks() {
			console.log("reload_chicks", this.reload_chicks)
			if (this.reload_chicks) {
				await this.init()
				this.TOGGLE_RELOAD_CHICKS({ reload: false })
			}
		},
		async reload_chicks_recursive() {
			if (this.reload_chicks_recursive) {
				// await this.init();
				// this.TOGGLE_RELOAD_CHICKS({ reload: false });
			}
		},
		"viewModalBrothersChicks.show"(value) {
			console.log("viewModalBrothersChicks", value)
			// if (value) {
			// 	this.SET_CHICKS(this.chicks_recursive);
			// } else {
			// 	this.SET_CHICKS(this.chicks);
			// }
			if (!value) {
				this.SET_CHICKS_RECURSIVE([])
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.selected {
	// background-color: red;
	.circle {
		height: 10px;
		width: 10px;
		margin-top: 0;
		margin-right: 0.5rem;
		border-radius: 50%;
	}
	p {
		margin-bottom: 0 !important;
	}
}

.row-table {
	transition: 0.5s all ease-in-out;
	padding-top: 1rem !important;
	margin-top: 1rem !important;
	// border-top: 2px solid green !important;
	.td {
		// border-top: 2px solid green !important;
		background: greenyellow;
	}
	border: 2px solid transparent !important;
	.input-plate-chick {
		width: 100px;
	}
}
.change-plate {
	background: #e3e1fc;
	// border: 2px solid #7367f0 !important;

	.input-plate-chick {
		border: 2px solid #7367f0;
	}
}
.dark-layout {
	.change-plate {
		background: #2e2960;
	}
}

.cross-center {
	flex-direction: column;
	justify-content: flex-end !important;
	align-items: flex-end !important;
	align-content: flex-end !important;
	@media screen and (min-width: 1200px) {
		// padding: 1rem;
	}
}
.xt-search-input__search {
	width: 100%;
	// margin: 1rem 0;
	display: flex;
	flex-direction: column;
	@media screen and (min-width: 1400px) {
		flex-direction: row;
		justify-content: flex-end;
	}
	.search-container-type {
		display: flex;
		h4 {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	.search-container-value {
		display: flex;
		flex-direction: column;
		margin-top: 0.5rem;
		@media screen and (min-width: 700px) {
			flex-direction: row;
			// margin-top: .5rem;
			margin-left: 1rem;
		}
		.search-input {
			width: 100% !important;
			@media screen and (min-width: 700px) {
				width: 50% !important;
			}
			@media screen and (min-width: 1000px) {
				width: 50% !important;
			}
		}
		.search-btn {
			margin-top: 0.5rem;
			width: 100% !important;
			@media screen and (min-width: 700px) {
				margin-left: 0.5rem;
				margin-top: 0;
				width: 50% !important;
			}
		}
	}
}
.xt-search-input__paginate {
	margin: 1rem 0;
	ul {
		margin: 0;
	}
}
.container-item-dob {
	width: 210px;
	// background: red;
	text-align: center;
	display: flex;
	justify-content: center !important;
	.edit-dob {
		margin-top: -0.3rem;
	}
}
.container-sidebar {
	padding: 0 1rem 1rem 1rem;

	.form-control-input {
		margin-bottom: 0.5rem;

		p {
			font-size: 12px;
		}
	}
}

.v-enter-active,
.v-leave-active {
	transition: all 0.5s ease-in-out;
}

.v-enter-from,
.v-leave-to {
	opacity: 0;
}
.v-enter-to,
.v-leave-from {
	opacity: 1;
}
.check-valid {
	height: 20px;
	width: 20px;
}
</style>
