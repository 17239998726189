<template>
	<div class="position-absolute-select-infinite">
		<div
			class="container-select-infinite-scroll"
			:style="[viewItemsDropdown ? { minHeight: '350px' } : { minHeight: '30px' }]"
			@focusout="handleFocusOut"
		>
			<div class="container-input">
				<b-form-input
					@focus="handleFocus"
					@input="searchLabel"
					v-if="idSelect"
					v-model="idSelect[label]"
					:placeholder="placeholder"
					ref="inputRef"
					:class="[error ? 'error-danger rounded' : '']"
				></b-form-input>
				<b-form-input
					@focus="handleFocus"
					@click="clickFocus"
					ref="inputRef"
					v-else
					@input="debounceInput"
					v-model="search"
					:placeholder="placeholder"
					:class="[error ? 'error-danger rounded' : '']"
				></b-form-input>
				<FeatherIcon
					size="18"
					icon="ChevronDownIcon"
					class="icon-select"
					:class="[inputFocus ? 'reverse-icon' : '']"
					@click="clickFocus"
				/>
			</div>
			<span v-if="error_input" class="text-danger">{{ error }}</span>

			<div
				class="list-group-wrapper mt-1"
				:class="[viewItemsDropdown ? 'view-items' : '']"
				@focusout="handleFocusOut"
			>
				<transition name="fade">
					<div class="container-spinner">
						<b-spinner class="spinner" v-if="loading" 
							label="Spinning" variant="primary"></b-spinner>
					</div>
				</transition>
				<ul
					v-if="items.length > 0"
					class="list-group"
					:id="`infinite-list-${key_select}`"
					@focusout="handleFocusOut"
				>
					<li
						@click="clickSelect(item)"
						class="list-group-item cursor-pointer"
						v-for="(item, key) in items"
						:key="`${key}-${item.correlative}`"
					>
						<div>
							<p class="mb-0">
								{{ item.correlative }} <span class="ml-1 font-weight-bold">{{ item.status }}</span>
							</p>
						</div>
					</li>
				</ul>
				<ul v-else class="mb-0" :id="`infinite-list-${key_select}`">
					<p class="mb-1 text-center mt-1">Sin elementos encontrados</p>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import { uid } from "uid";

export default {
	name: "SelectInfiniteScroll",
	props: {
		api: {
			type: String,
			required: true,
		},
		key_json: {
			type: String,
			required: true,
		},
		label: {
			type: String,
			required: true,
		},
		placeholder: {
			type: String,
			default: "Buscar",
		},
		v_model: {
			type: Object,
			default: null,
		},
		error: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			loading: false,
			nextItem: 1,
			items: [],
			page: 1,
			idSelect: null,
			search: null,
			inputFocus: false,
			viewItemsDropdown: false,
			key_select: uid(16),
			error_label: null,
		};
	},
	computed: {
		error_input() {
			return ((!this.idSelect || this.search) && this.error) || this.idSelect ? true : false;
		},
	},
	async mounted() {
		this.error_label = this.error;
		this.items = await this.init(this.page);
		// Detect when scrolled to bottom.
		const listElm = document.querySelector(`#infinite-list-${this.key_select}`);
		listElm.addEventListener("scroll", (e) => {
			if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
				this.loadMore();
			}
		});
	},
	methods: {
		loadMore() {
			this.loading = true;
			setTimeout(async (e) => {
				const chicks = await this.init(this.page);
				this.items = [...this.items, ...chicks];
				this.loading = false;
			}, 200);
			this.page++;
			/**************************************/
		},
		async init(page) {
			try {
				const { data } = await axios.get(`/api/${this.api}`, {
					params: {
						page,
						perpage: this.perpage,
						search: this.search,
					},
				});
				return data[this.key_json];
			} catch (err) {
				console.error(err);
			}
		},
		clickSelect(item) {
			if (true) {
				this.v_model.id = item.id;
				this.v_model.postura = item.postura_id;

				this.idSelect = { ...item };
				setTimeout(() => {
					// this.viewItemsDropdown = false;
				}, 500);
			}
		},
		// searchItem() {},
		debounceInput: _.debounce(async function () {
			this.idSelect = null;
			const chicks = await this.init(1);
			this.items = chicks;
			this.page = 2;
		}, 500),
		async searchLabel() {
			if (this.idSelect[this.label] == "") {
				this.idSelect = null;
				this.search = null;
				this.items = await this.init(this.page);
				this.page = 2;
				this.v_model.id = null;
				this.error_label = null;
			}
		},
		clickFocus() {
			// this.search.focus()
			this.$refs.inputRef.focus();
			this.inputFocus = true;
			this.viewItemsDropdown = true;
		},
		handleFocus() {
			// do something here
			this.inputFocus = true;
			this.viewItemsDropdown = true;
		},
		handleFocusOut() {
			this.inputFocus = false;
			setTimeout(() => {
				this.viewItemsDropdown = false;
			}, 150);
		},
	},
	watch: {
		idSelect() {
			setTimeout(() => {
				this.viewItemsDropdown = false;
			}, 20);
		},
	},
};
</script>

<style lang="scss">
.position-absolute-select-infinite {
	position: relative;
	.container-select-infinite-scroll {
		background: transparent;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		transition: 0.3s all ease-in-out;
		.list-group-wrapper {
			// border: 1px solid #ced4da;
			box-shadow: 0px 4px 25px 0px rgb(0 0 0 / 10%);
		}
	}
}

$purple: #5c4084;

.container-select-infinite-scroll {
	position: relative;
	.list-group-wrapper {
		background: #fff;
		position: absolute;
		// top: 100%;
		left: 0;
		width: 100%;
		z-index: 999;
	}
}

.container {
	padding: 40px 80px 15px 80px;
	background-color: #fff;
	border-radius: 8px;
	max-width: 300px;
}

.list-group-wrapper {
	// position: relative;
	// opacity: 0;
	height: 0 !important;
	overflow: hidden;
	min-height: 0 !important;
	width: 100%;
	transition: 0.3s all ease-in-out;
	// background: red !important;
	// pointer-events: none;
}
.view-items {
	overflow: auto !important;
	min-height: auto !important;
	height: auto !important;
	opacity: 1;
	display: block;
	// pointer-events: inherit !important;
}
.list-group {
	overflow: auto;
	height: 20vh;
	border: none !important;
	// border: 1px solid #dce4ec;
	border-radius: 5px;
	// box-shadow: 0px 4px 25px 0px rgb(0 0 0 / 10%);
}

.list-group::-webkit-scrollbar {
	width: 5px;
	background: #d5d1fb;
}

.list-group::-webkit-scrollbar-thumb {
	background: #7367f0;
	border-radius: 10px;
}

.list-group-item {
	margin-top: 1px;
	border-left: none;
	border-right: none;
	border-top: none;
	border: none !important;
	// border-bottom: 2px solid #dce4ec;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
.container-input {
	position: relative;
	// background: red;
	cursor: pointer;
	.icon-select {
		position: absolute;
		right: 10px;
		top: 10px;
		color: #5c4084;
		cursor: pointer;
		transition: 0.3s all ease-in-out;
	}
}
.reverse-icon {
	transform: rotate(180deg);
}
.loading {
	width: 80px !important;
	height: 80px !important;
}
.container-spinner {
	position: relative;
	height: 1px;
	z-index: 9999999;
	width: 100%;
	.spinner {
		position: absolute;
		top: 8500%;
		left: 50%;
		z-index: 9999999;

		// background: red;
	}
}
</style>
