<template>
	<div>
		<b-modal
			ref="modal-add-dead-cp-ca"
			@hidden="$emit('hidden', hasChanged)"
			:title="title"
			no-close-on-backdrop
			cancel-title="Cancelar"
			size="lg"
		>
			<b-form-group label="Elegir muerte o depurar" v-slot="{ ariaDescribedby }">
				<div class="d-flex">
					<b-form-radio
						class="mr-1 d-flex align-items-center"
						v-model="depuracion_boolean"
						name="some-radios"
						:value="true"
						>Muerto</b-form-radio
					>
					<b-form-radio
						class="mr-1 d-flex align-items-center"
						v-model="depuracion_boolean"
						name="some-radios"
						:value="false"
						>Depurar</b-form-radio
					>
				</div>
			</b-form-group>
			<!-- {{ lote_id }} -->

			<ValidationObserver ref="form">
				<b-row v-if="depuracion_boolean">
					<b-col class="input-select-observation" cols="12" lg="6" md="6">
						<b-form-group id="input-group-1" label="Observación:" label-for="input-1">
							<ValidationProvider rules="required" v-slot="{ errors }">
								<v-select
									v-model="dead.reason_id"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									:options="arrayReasons"
									:clearable="false"
									label="reason"
									:reduce="(option) => option.id"
									:class="{ 'border-danger rounded': errors[0] }"
									@input="
										() => {
											dead.observation = null;
										}
									"
								>
									<template v-slot:option="option">
										<div class="d-flex justify-content-between align-items-center">
											<span :style="`margin-left: ${option.parent_id ? '10' : '0'}px`"
												>{{ option.reason }}
											</span>
											<!-- <span v-if="option.parent_id"> Sub-motivo de {{ option.parent_reason }} </span> -->
										</div>
									</template>
								</v-select>
								<span v-if="errors[0]" class="text-danger">{{ errors[0] }}</span>
							</ValidationProvider>
						</b-form-group>
						<b-form-group label="Otros" label-for="observation" v-if="dead.reason_id === 19">
							<ValidationProvider rules="required" v-slot="{ errors }">
								<b-input-group label-for="observation">
									<b-form-textarea
										id="observation"
										v-model="dead.observation"
										type="text"
										placeholder="Ingrese otra razon"
										:state="errors[0] ? false : null"
									></b-form-textarea>
								</b-input-group>
								<span class="text-danger">{{ errors[0] }}</span>
							</ValidationProvider>
						</b-form-group>
					</b-col>

					<b-col cols="12" lg="6" md="12">
						<DragAndDropImages
							v-model="dead.evidences"
							:filesArray="dead.evidences"
							:source="'1'"
							:containerDescription="`Seleccionar evidencia`"
							containerPadding="25px"
						/>
					</b-col>
				</b-row>
				<b-row v-else>
					<b-col class="input-select-observation" cols="12" lg="12" md="12">
						<b-form-group description="Descripcion de la depuracion(opcional)." label="Observaciones:">
							<b-form-textarea
								v-model="observation_depuracion"
								placeholder="Ingrese una observacion"
								rows="3"
								max-rows="6"
							></b-form-textarea>
						</b-form-group>
					</b-col>
				</b-row>
			</ValidationObserver>
			<template #modal-footer="{ cancel }">
				<b-button @click="cancel()" v-if="!loading" variant="secondary"> Cancelar </b-button>
				<b-button variant="secondary" disabled v-else>
					<b-spinner small />
					Cargando...
				</b-button>
				<b-button variant="primary" @click="save()" v-if="!loading"> Guardar </b-button>
				<b-button variant="primary" disabled v-else>
					<b-spinner small />
					Cargando...
				</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import modalMixin from "@/mixins/modal.js";
import DragAndDropImages from "@/components/commons/drag-and-drop/DragAndDropImages.vue";
import reasonsService from "@/services/reasons.service";
import deadsService from "@/services/deads.service";
import SelectInfiniteScroll from "@/views/amg/deads/components/SelectInfiniteScroll.vue";
import { setEvidencesBase64 } from "@/helpers/functions";
import { mapState, mapActions } from "vuex";
import chicksService from "@/services/encaste/chicks.service";

export default {
	name: "AddDeadCPCA",
	components: {
		DragAndDropImages,
		SelectInfiniteScroll,
	},
	mixins: [modalMixin],
	props: {
		postura: {
			type: Object,
			required: true,
		},
		massively: {
			type: Boolean,
			default: false,
		},
		recursive: {
			type: Boolean,
			default: false,
		},
		
	},
	async mounted() {
		if (this.postura.ca && this.postura.ca.status == 4) {
			this.depuracion_boolean = false;
		}
		this.isPreloading();
		await this.getReasonsByModule(1);

		// PENDIENTE ES PARA CP, Y COLOCADO PARA CA
		this.dead.ca_id = this.postura.ca?.id;
		this.dead.type = this.postura.cond ? 1 : 2; // EN PENDIENTE O COLOCADO
		this.dead.postura.cp_id = this.postura.cintillos.cp_id;
		this.dead.postura.egg_lote_id = this.postura.egg_lote_id;

		this.toggleModal("modal-add-dead-cp-ca");
		this.isPreloading(false);
	},
	computed: {
		...mapState("encasteChicks", ["chicks", "chicks_recursive", "jsonSearchChicks"]),
		title() {
			return (
				// plate se refiere al correlativo, no la placa en si
				`Registro ${this.depuracion_boolean ? "muerto" : "depurado"} en cintillo en la ` +
				(this.dead.type === 1 ? "pata" : "ala: " + this.postura.ca.plate)
			);
		},
	},
	data() {
		return {
			hasChanged: false,
			loading: false,
			arrayReasons: [],
			dead: {
				ca_id: null,
				reason_id: null,
				observation: null,
				evidences: [],
				customFormData: [],
				type: 1,
				postura: {
					quantity: 1,
					egg_lote_id: null,
					cp_id: null,
				},
			},
			observation_depuracion: null,
			depuracion_boolean: true,
		};
	},
	methods: {
		...mapActions("encasteChicks", ["getChicks", "getChicksRecursive"]),
		async getReasonsByModule(module_id) {
			const data = await reasonsService.getByModule(module_id);
			this.arrayReasons = data;
		},
		async save() {
			if (!(await this.$refs.form.validate())) {
				this.showToast("danger", "top-right", "Error", "AlertCircleIcon", "Complete los campos");
				return;
			}

			let deads = [];

			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;

			if (!this.massively) {
				this.isPreloading();
				if (!this.dead.ca_id) {
					this.dead.id = this.dead.ca_id;

					this.loading = true;
					deads = await setEvidencesBase64([this.dead]);

					deads = deads.map((dead) => {
						return {
							...dead,
							observation: this.depuracion_boolean ? dead.observation : this.observation_depuracion,
						};
					});
					// console.log("deads", deads);

					const { ok, message } = await deadsService.saveDead(deads, true, this.depuracion_boolean);

					if (!ok) return this.showErrorSwal(message);

					this.showSuccessToast(message);
				} else {
					deads = await setEvidencesBase64([this.dead]);
					// console.log("RD", deads);

					await chicksService.deadChicksMassively({
						chicks: [
							{
								id: this.dead.ca_id,
								observation: this.observation_depuracion,
								reason_id: this.dead.reason_id,
							},
						],
						evidences: deads[0].customFormData,
						status_id: this.depuracion_boolean ? 3 : 4,
						is_masive: 1,
					});
				}
				this.isPreloading(false);
			} else {
				// deads = await setEvidencesBase64([this.dead]);

				console.log("ADD ADD", deads);
			}

			this.loading = false;

			this.clearObjDead();
			this.hasChanged = true;
			this.$emit("hidden", this.hasChanged);
			this.$emit("set-reload");
			await this.getChicksReload();
			this.$root.$emit("reloader-counters-chicks");
		},
		clearObjDead() {
			this.dead = {
				reason_id: null,
				observation: null,
				customFormData: [],
				evidences: [],
				type: 1,
				type: 1,
				postura: {
					egg_lote_id: null,
					cp_id: null,
					quantity: 1,
				},
			};
		},
		async getChicksReload() {
			// this.SET_JSON_SEARCH_CHICKS({
			// 	...this.jsonSearchChicks
			// });
			await this.getChicks(this.jsonSearchChicks);
		},
	},
};
</script>

<style lang="scss" scoped></style>
