<template>
	<div>
		<b-card no-body class="mb-1">
			<div class="m-2">
				<div class="mx-2 mb-2">
					<b-row>
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-start"
						>
							<span class="text-muted"
								>Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros</span
							>a
						</b-col>
						<!-- Pagination -->
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-end"
						>
							<b-pagination
								v-model="current_page"
								:total-rows="total_data"
								:per-page="perpage"
								first-number
								last-number
								class="mb-0 mt-1 mt-sm-0"
								prev-class="prev-item"
								next-class="next-item"
							>
								<template #prev-text>
									<feather-icon icon="ChevronLeftIcon" size="18" />
								</template>
								<template #next-text>
									<feather-icon icon="ChevronRightIcon" size="18" />
								</template>
							</b-pagination>
						</b-col>
					</b-row>
				</div>

				<b-row>
					<b-col cols="12" md="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
						<label>Mostrar</label>
						<v-select
							v-model="perpage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label class="mr-2">registros</label>
						<feather-icon class="cursor-pointer" icon="RefreshCcwIcon" size="20" @click="resetSearch" />
					</b-col>
					<!-- Search -->
					<b-col cols="12" md="8">
						<b-row>
							<b-col cols="12" lg="3" md="6"></b-col>
							<b-col cols="12" lg="3" md="6"> </b-col>
							<b-col cols="12" lg="3" md="6">
								<b-form-group>
									<v-select
										v-model="option"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										:options="searchBy"
										:clearable="false"
										label="name"
										:reduce="(option) => option.item"
									/>
								</b-form-group>
							</b-col>
							<b-col cols="12" lg="3" md="6">
								<b-form-input v-model="search_input" placeholder="Buscar..." debounce="500" />
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</div>
			<div class="table-responsive">
				<b-table
					:api-url="'/api/chick/get'"
					ref="refChickensListTable"
					class="position-relative"
					:items="myProvider"
					:stacked="currentBreakPoint === 'sm' || currentBreakPoint === 'xs'"
					sticky-header="75vh"
					:fields="arrayColumns"
					primary-key="id"
					table-class="text-nowrap"
					responsive
					show-empty
					:busy.sync="isBusy"
					empty-filtered-text="No hay registros que coincidan con tu búsqueda."
					empty-text="No hay registros que mostrar."
					:sort-by.sync="sortByCustom"
					:sort-desc.sync="sortDesc"
					:current-page="current_page"
					:per-page="perpage"
					:filter="searchInput"
					striped
					foot-clone
				>
					<template #thead-top>
						<b-tr class="sticky-vivos">
							<b-th colspan="3" class="bg-transparent"></b-th>
							<b-th colspan="4" class="bg-success bg-darken-1 text-white text-center">Vivos</b-th>
							<b-th colspan="4" class="bg-danger bg-darken-1 text-white text-center">Muertos</b-th>
							<b-th colspan="1" class="bg-primary bg-darken-1 text-white text-center">Total</b-th>
						</b-tr>
					</template>
					<template #table-busy>
						<div class="text-center text-primary my-2">
							<b-spinner class="align-middle"></b-spinner>
							<strong>Cargando...</strong>
						</div>
					</template>
					<template #cell(madrilla)="{ item }">
						<!-- {{ item.padrillo }} -->
						<!-- <div v-if="data.madrilla">
							{{ data.madrilla.alias }}
						</div> -->
						<SpecimenPlate
							:specimen="{
								id: item.madrilla.id,
								plate: item.madrilla.plate,
								alias: item.madrilla.alias,
								thumb: item.madrilla.thumb,
								image: item.madrilla.image,
							}"
						/>
					</template>
					<template #cell(padrillo)="{ item }">
						<SpecimenPlate
							:specimen="{
								plate: item.padrillo.plate,
								alias: item.padrillo.alias,
								id: item.padrillo.id,
								thumb: item.padrillo.thumb,
								image: item.padrillo.image,
							}"
						/>
					</template>
					<template #cell(posturas_qty)="{ item }">
						{{ item.posturas * 1 }}
					</template>

					<template #cell(hembras)="{ item }">
						<b-badge
							variant="light-success"
							class="cursor-pointer px-1 py-50"
							style="font-size: 14px"
							@click="openChicksByPostura(item, 1, 2)"
							>{{ item.vivos.hembras }}</b-badge
						>
					</template>
					<template #cell(machos)="{ item }">
						<b-badge
							variant="light-success"
							class="cursor-pointer px-1 py-50"
							style="font-size: 14px"
							@click="openChicksByPostura(item, 1, 1)"
							>{{ item.vivos.machos }}</b-badge
						>
					</template>

					<template #cell(sin_asignar)="{ item }">
						<b-button
							variant="outline-success"
							class="font-small-5"
							size="sm"
							@click="openChicksByPostura(item, 1, 3)"
							>{{ item.vivos.sin_asignar }}
						</b-button>
					</template>
					<template #cell(total_vivos)="{ item }">
						<b-button
							variant="relief-success"
							class="font-small-5"
							size="sm"
							@click="openChicksByPostura(item, 1, null)"
							>{{ Number(item.vivos.total) }}</b-button
						>
					</template>

					<!-- MUERTOS -->
					<template #cell(muertos_hembras)="{ item }">
						<b-badge
							variant="light-danger"
							class="cursor-pointer px-1 py-50"
							style="font-size: 14px"
							@click="openChicksByPostura(item, 2, 1)"
							>{{ item.muertos.hembras }}
						</b-badge>
					</template>

					<template #cell(muertos_machos)="{ item }">
						<b-badge
							variant="light-danger"
							class="cursor-pointer px-1 py-50"
							style="font-size: 14px"
							@click="openChicksByPostura(item, 2, 2)"
							>{{ item.muertos.machos }}
						</b-badge>
					</template>

					<template #cell(muertos_sin_asignar)="{ item }">
						<b-button
							variant="outline-danger"
							class="font-small-5"
							size="sm"
							@click="openChicksByPostura(item, 2, 3)"
							>{{ item.muertos.sin_asignar }}</b-button
						>
					</template>
					<template #cell(muertos)="{ item }">
						<b-button
							variant="relief-danger"
							class="font-small-5"
							size="sm"
							@click="openChicksByPostura(item, 2, null)"
							>{{ Number(item.muertos.total) }}
						</b-button>
					</template>
					<template #cell(total)="{ item }">
						<b-button
							variant="relief-primary"
							size="sm"
							class="font-small-5"
							@click="openChicksByPostura(item, null, null)"
							>{{ item.total_chicks }}
						</b-button>
					</template>

					<!-- Footer -->
					<template #foot(posturas_qty)>
						<span class="text-primary font-weight-bolder h4"> SUB TOTAL </span>
					</template>
					<template #foot(hembras)>
						<span class="text-secondary font-weight-bolder h4">{{ totalHembras }}</span>
					</template>
					<template #foot(machos)>
						<span class="text-secondary font-weight-bolder h4">{{ totalMachos }}</span>
					</template>
					<template #foot(sin_asignar)>
						<span class="text-secondary font-weight-bolder h4">{{ totalSinAsignar }}</span>
					</template>
					<template #foot(total_vivos)>
						<span class="text-success font-weight-bolder h4">{{ totalVivos }}</span>
					</template>

					<!-- MUERTOS -->
					<template #foot(muertos_machos)>
						<span class="font-weight-bolder h4">{{ totalDeathsMachos }}</span>
					</template>
					<template #foot(muertos_hembras)>
						<span class="font-weight-bolder h4">{{ totalDeathsHembras }}</span>
					</template>
					<template #foot(muertos_sin_asignar)>
						<span class="font-weight-bolder h4">{{ totalDeathsSinAsignar }}</span>
					</template>
					<template #foot(muertos)>
						<span class="text-danger font-weight-bolder h4">{{ totalDeaths }}</span>
					</template>
					<template #foot(total)>
						<span class="text-primary font-weight-bolder h4">{{ totalChicks }}</span>
					</template>
				</b-table>
			</div>
			<div class="mx-2 mb-2">
				<b-row>
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span class="text-muted"
							>Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros</span
						>
					</b-col>
					<!-- Pagination -->
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>
						<b-pagination
							v-model="current_page"
							:total-rows="total_data"
							:per-page="perpage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon icon="ChevronLeftIcon" size="18" />
							</template>
							<template #next-text>
								<feather-icon icon="ChevronRightIcon" size="18" />
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>

		<ChicksByPosturaModal
			:postura="postura"
			:chick_gender="chick_gender"
			:chick_status="chick_status"
			@hidden="closeChicksByPostura"
			v-if="chicksByPosturaController"
		/>

		<!-- TODO: al reordenar los archivos, definir el nombre del componente -->
		<DebuggingPosturas v-if="controllerDebuggingChicks" @hidden="closeDebuggingChicks" />

		<view-image-specimens
			v-if="modal_image"
			:data_specimen="data_specimen"
			@click="closeModalimage"
		></view-image-specimens>
	</div>
</template>

<script>
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useUsersList from "@/views/amg/users/useUsersList";
import userStoreModule from "@/views/amg/users/userStoreModule";
import axios from "@/axios";
import Ripple from "vue-ripple-directive";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ChicksByPosturaModal from "./modals/ChicksByPostura.vue";

import DebuggingPosturas from "@/views/amg/encaste/posturas/debugging/DebuggingPosturas.vue";
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue";
import { mapState } from "vuex";
export default {
	name: "ChicksByPostura",
	directives: {
		Ripple,
	},
	components: {
		AppCollapse,
		AppCollapseItem,
		ChicksByPosturaModal,
		DebuggingPosturas,
		SpecimenPlate,
	},
	created() {
	},
	computed: {
		...mapState("encasteChicks", ["start_date", "end_date", "reload_counters"]),
		searchInput() {
			return this.search_input.length >= 3 ? this.search_input : "";
		},
		totalChicks() {
			return this.items.reduce((total, item) => {
				return total + Number(item.total_chicks);
			}, 0);
		},
		totalDeathsMachos() {
			return this.items.reduce((total, item) => {
				return total + Number(item.muertos.machos);
			}, 0);
		},
		totalDeathsHembras() {
			return this.items.reduce((total, item) => {
				return total + Number(item.muertos.hembras);
			}, 0);
		},
		totalDeathsSinAsignar() {
			return this.items.reduce((total, item) => {
				return total + Number(item.muertos.sin_asignar);
			}, 0);
		},
		totalDeaths() {
			return this.items.reduce((total, item) => {
				return total + Number(item.muertos.total);
			}, 0);
		},
		totalVivos() {
			return this.items.reduce((total, item) => {
				return total + Number(item.vivos.total);
			}, 0);
		},
		totalSinAsignar() {
			return this.items.reduce((total, item) => {
				return total + Number(item.vivos.sin_asignar);
			}, 0);
		},
		totalVivosMH() {
			return this.items.reduce((total, item) => {
				return total + Number(item.vivos.machos) + Number(item.vivos.hembras);
			}, 0);
		},
		totalMachos() {
			return this.items.reduce((total, item) => {
				return total + Number(item.vivos.machos);
			}, 0);
		},
		totalHembras() {
			return this.items.reduce((total, item) => {
				return total + Number(item.vivos.hembras);
			}, 0);
		},
	},
	data() {
		return {
			sortByCustom: "padrillo_alias",
			sortDesc: false,
			arrayColumns: [
				{
					key: "madrilla",
					label: "Madrilla",
					class: "text-center column-table",
				},
				{
					key: "padrillo",
					label: "Padrillo",
					class: "text-center column-table",
				},
				{
					key: "posturas_qty",
					label: "Cantidad de posturas",
					class: "text-center column-table",
				},
				{
					key: "hembras",
					label: "H",
					class: "text-center column-table",
					thClass: "px-50",
					tdClass: "px-50",
				},
				{
					key: "machos",
					label: "M",
					class: "text-center column-table",
					thClass: "px-50",
					tdClass: "px-50",
				},

				{
					key: "sin_asignar",
					label: "SA",
					class: "text-center column-table",
					thClass: "px-50",
					tdClass: "px-50",
				},
				{
					key: "total_vivos",
					label: "T",
					class: "text-center column-table",
				},
				{
					key: "muertos_machos",
					label: "H",
					class: "text-center column-table",
					thClass: "px-50",
					tdClass: "px-50",
				},
				{
					key: "muertos_hembras",
					label: "M",
					class: "text-center column-table",
					thClass: "px-50",
					tdClass: "px-50",
				},
				{
					key: "muertos_sin_asignar",
					label: "SA",
					class: "text-center column-table",
					thClass: "px-50",
					tdClass: "px-50",
				},

				{
					key: "muertos",
					label: "T",
					class: "text-center column-table",
				},
				{
					key: "total",
					label: "TOTAL",
					class: "text-center column-table",
				},
			],
			data_sessions: [],
			modal_sessions: false,
			data: [],
			edit: [],
			list_users: true,
			create_update: false,
			disable: {
				save: false,
			},
			search_input: "",
			orderby: this.dato2 == null ? 3 : this.dato2,
			order: this.dato1 == null ? "desc" : this.dato1,
			start_page: "",
			end_page: "",
			total_data: "",
			perpage: 100,
			next_page: "",
			current_page: 1,
			to_page: "",
			dato1: "",
			dato2: "",
			loadingData: false,
			isBusy: false,
			option: 2,
			searchBy: [
				{ item: 1, name: "Padrillo / Madrilla" },
				{ item: 2, name: "Pollo (Cintillo y Placa)" },
			],
			chicksByPosturaController: false,
			chick_status: null,
			chick_gender: null,
			postura: {
				placa_madrilla: null,
				placa_madrilla: null,
				padrillo_id: -1,
				madrilla_id: -1,
			},
			data_specimen: {
				type: "",
				url: "",
				plaque: "",
				alias: "",
				id: "",
				size: 300000000,
				last_left: false,
				last_right: false,
			},
			modal_image: false,
			items: [],
			controllerDeadChicks: false,
			controllerDebuggingChicks: false,
			controllerDebuggingPosturas: false,
		};
	},
	methods: {
		closeModalimage() {
			this.modal_image = false;
		},
		seeimage(type, url, plaque, alias, id) {
			this.modal_image = true;
			this.data_specimen.type = type;
			this.data_specimen.url = url;
			this.data_specimen.plaque = plaque;
			this.data_specimen.alias = alias;
			this.data_specimen.id = id;
		},
		openChicksByPostura(item, status, gender) {
			this.postura.placa_madrilla = item.placa_madrilla;
			this.postura.placa_padrillo = item.placa_padrillo;
			this.postura.madrilla_id = item.madrilla_id;
			this.postura.padrillo_id = item.padrillo_id;
			this.chick_gender = gender;
			this.chick_status = status;
			this.chicksByPosturaController = true;
		},
		closeChicksByPostura(hasChanged) {
			this.chicksByPosturaController = false;
			this.postura = {
				placa_madrilla: null,
				placa_madrilla: null,
				padrillo_id: null,
				padrillo_id: null,
			};
			if (hasChanged) {
				this.resetSearch();
			}
		},
		onChangeFilter() {
			this.$refs.refChickensListTable.refresh();
		},
		myProvider(ctx) {
			const promise = axios.post(`${ctx.apiUrl}?page=${ctx.currentPage}`, {
				// orderby: ctx.sortDesc,
				// order: ctx.sortByCustom == undefined || ctx.sortByCustom == "" ? "padrillo_alias" : ctx.sortByCustom,
				option: this.option,
				campo: ctx.filter,
				perpage: ctx.perPage,
				start_date: this.start_date,
				final_date: this.end_date,
			});
			return promise.then((data) => {
				this.items = data.data.data;
				this.start_page = data.data.from;
				this.current_page = data.data.current_page;
				this.perpage = data.data.per_page;
				this.next_page = this.start_page + 1;
				this.end_page = data.data.last_page;
				this.total_data = data.data.total;
				this.to_page = data.data.to;
				return this.items || [];
			});
		},
		resetSearch() {
			this.search_input = "";
			this.$refs.refChickensListTable.refresh();
		},
		closeModals() {
			this.modal_sessions = false;
		},
		getcolorspecimen() {
			axios
				.get("/api/specimens/get-colors")
				.then((response) => {
					if (response.status == 200) {
						this.filters[1].options = response.data;
					}
				})
				.catch((err) => {
					console.log(err);
				});
		},
		getdataselect() {
			this.getcolorspecimen();
			axios
				.get("/api/specimens/get-data-select-specimens")
				.then((response) => {
					if (response.status == 200) {
						this.filters[0].options = JSON.parse(response.data[0].data_origin);
						this.filters[2].options = JSON.parse(response.data[0].data_statuse);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		},
		openmodal(id) {
			axios
				.post("/api/get-sessions-user", {
					iduser: id,
				})
				.then((response) => {
					if (response.data) {
						this.data_sessions = response.data;
						this.modal_sessions = true;
					}
				})
				.catch((err) => {
					console.log(err);
				});
		},
		openDebuggingChicks() {
			this.controllerDebuggingChicks = true;
		},
		closeDebuggingChicks() {
			this.controllerDebuggingChicks = false;
		},
	},
	watch: {
		start_date(newValue, oldValue) {
			if (newValue) {
				this.$refs.refChickensListTable.refresh();
			}
		},
		option(newValue, oldValue) {
			if (newValue) {
				this.$refs.refChickensListTable.refresh();
			}
		},
	},
	setup() {
		const USER_APP_STORE_MODULE_NAME = "app-user";

		// Register module
		if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
			store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

		// UnRegister on leave
		onUnmounted(() => {
			if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
		});

		const isAddNewUserSidebarActive = ref(false);

		const roleOptions = [
			{ label: "Admin", value: "admin" },
			{ label: "Author", value: "author" },
			{ label: "Editor", value: "editor" },
			{ label: "Maintainer", value: "maintainer" },
			{ label: "Subscriber", value: "subscriber" },
		];

		const planOptions = [
			{ label: "Basic", value: "basic" },
			{ label: "Company", value: "company" },
			{ label: "Enterprise", value: "enterprise" },
			{ label: "Team", value: "team" },
		];

		const statusOptions = [
			{ label: "Pending", value: "pending" },
			{ label: "Active", value: "active" },
			{ label: "Inactive", value: "inactive" },
		];

		const {
			fetchUsers,
			tableColumns,
			perPage,
			currentPage,
			totalUsers,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refChickensListTable,
			refetchData,

			// UI
			resolveUserRoleVariant,
			resolveUserRoleIcon,
			resolveUserStatusVariant,

			// Extra Filters
			roleFilter,
			planFilter,
			statusFilter,
		} = useUsersList();

		return {
			// Sidebar
			isAddNewUserSidebarActive,

			fetchUsers,
			tableColumns,
			perPage,
			currentPage,
			totalUsers,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refChickensListTable,
			refetchData,

			// Filter
			avatarText,

			// UI
			resolveUserRoleVariant,
			resolveUserRoleIcon,
			resolveUserStatusVariant,

			roleOptions,
			planOptions,
			statusOptions,

			// Extra Filters
			roleFilter,
			planFilter,
			statusFilter,
		};
	},
};
</script>

<style lang="scss" scoped>
.sticky-vivos {
	position: sticky;
	z-index: 999;
}

.per-page-selector {
	width: 90px;
}

td.div {
	width: 100% !important;
}

@media (max-width: 960px) {
	.column-table {
		display: flex;
		flex-direction: column;
	}
}
</style>
