<template>
	<b-modal
		ref="modal-chicks-by-postura"
		@hidden="$emit('hidden', hasChanged)"
		:title="`Pollos por lotes por posturas`"
		cancel-title="Cancelar"
		size="xl"
	>
		<b-row>
			<b-col cols="12" xl="12" md="12" v-for="postura in arrayGroupedPosturas" :key="postura.id">
				<b-table-simple>
					<b-thead class="text-uppercase text-center">
						<b-th>Postura</b-th>
						<b-th>Padrillo</b-th>
						<b-th>Madrilla</b-th>
						<b-th>PATA IZQUIERDA</b-th>
						<b-th>PATA derecha</b-th>
						<b-th>Fecha</b-th>
					</b-thead>
					<b-tbody>
						<b-tr class="text-center">
							<b-td>
								<h4>{{ postura.code }}</h4>
								<b-badge
									:variant="
										postura.status == 'ANULADA'
											? 'danger'
											: postura.status == 'PENDIENTE'
											? 'warning'
											: postura.status == 'POR FINALIZAR'
											? 'info'
											: 'primary'
									"
								>
									{{ postura.status }}
								</b-badge>
							</b-td>
							<b-td>
								<SpecimenPlate
									:specimen="{
										plate: postura.placa_padrillo,
										id: postura.specimen_id,
										thumb: postura.padrillo_thumb,
										image: postura.padrillo_photo,
										alias: postura.padrillo_alias,
									}"
								/>
							</b-td>
							<b-td>
								<SpecimenPlate
									:specimen="{
										plate: postura.placa_madrilla,
										id: postura.specimen_id,
										thumb: postura.madrilla_thumb,
										image: postura.madrilla_photo,
										alias: postura.madrilla_alias,
									}"
								/>
							</b-td>
							<b-td>
								<CombChickenlegsCintillos
									:postura="{
										id: postura.id,
										chickenleg_wich_leg: postura.chickenleg_wich_leg,
										chickenleg_qty_cintillos: postura.chickenleg_qty_citillos,
										cintillo_color: postura.cintillo_color,
										cintillo_description: postura.cintillo_description,
										second_color_color: postura.c3_color,
										second_color_description: postura.c3_description,
										total_minus_infertile_eggs: 1,
									}"
									:chickenleg_wich_leg="1"
								/>
							</b-td>
							<b-td>
								<CombChickenlegsCintillos
									:postura="{
										id: postura.id,
										chickenleg_wich_leg: postura.chickenleg_wich_leg,
										chickenleg_qty_cintillos: postura.chickenleg_qty_citillos,
										cintillo_color: postura.cintillo_color,
										cintillo_description: postura.cintillo_description,
										second_color_color: postura.c3_color,
										second_color_description: postura.c3_description,
										total_minus_infertile_eggs: 1,
									}"
									:chickenleg_wich_leg="2"
								/>
							</b-td>
							<b-td>
								{{ postura.start_date | myGlobalDayShort }}
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
				<template v-for="lote in postura.json_data">
					<b-card
						:key="lote.id"
						v-if="lote.chicks"
						class="mt-3"
						:style="`border: 1px solid ${
							layout.skin == 'dark' ? '#404656' : '#d8d6de'
						} !important; border-radius: 10px;`"
						header-class="d-flex justify-content-between align-items-center font-weight-bolder text-primary h4"
						:header="lote.code"
					>
						<b-table-lite
							responsive
							:items="lote.chicks"
							:fields="arrayColumns"
							:stacked="currentBreakPoint === 'sm' || currentBreakPoint === 'xs'"
							sticky-header="75vh"
						>
							<template #cell(dob)="data">
								<b-row>
									<b-col cols="12" md="9" class="text-center">
										<div v-if="!data.item.is_editing_dob">
											<span>{{ data.item.dob | myGlobalDayShort }}</span>
										</div>
										<div v-else>
											<b-form-datepicker
												size="sm"
												id="dob"
												placeholder="Dob"
												:date-format-options="{
													year: 'numeric',
													month: 'numeric',
													day: 'numeric',
												}"
												v-model="data.item.new_dob"
											/>
										</div>
									</b-col>
									<b-col md="1">
										<feather-icon
											icon="EditIcon"
											class="cursor-pointer"
											v-if="isAdmin && !data.item.is_editing_dob && data.item.status_number == 1"
											@click="editChickDob(data.item)"
										/>
										<template v-if="isAdmin && data.item.is_editing_dob">
											<feather-icon
												icon="CheckIcon"
												class="cursor-pointer"
												@click="saveNewDobToChick(data.item)"
											/>
										</template>
									</b-col>
									<b-col md="1">
										<template v-if="isAdmin && data.item.is_editing_dob">
											<feather-icon
												icon="XIcon"
												class="cursor-pointer"
												@click="closeEditChickDob(data.item)"
											/>
										</template>
									</b-col>
								</b-row>
								<!-- <span class="mr-1">
										{{ data.item.date_of_birth | myGlobalDayWhour }}
									</span>
									<span>
										<feather-icon icon="EditIcon" class="cursor-pointer" />
									</span> -->
							</template>
							<template #cell(gender)="data">
								<p v-if="data.item.is_assigned_plate">
									{{ data.item.gender }}
								</p>
								<b-form-select
									id="lblGender"
									v-model="data.item.gender_number"
									:options="genders"
									required
									size="sm"
									:disabled="!isAdmin || data.item.status_number == 2"
									@input="onChangeGenderSelect(data.item)"
									v-else
								></b-form-select>
							</template>
							<template #cell(status)="data">
								<span>
									{{ data.item.status }}
								</span>
							</template>
							<template #cell(assigned_plate)="data">
								<span v-if="data.item.is_assigned_plate || (!isAdmin && data.item.status_number == 2)">
									{{ data.item.assigned_plate }}
								</span>
								<b-row
									v-if="
										data.item.gender != 'SIN ASIGNAR' &&
										!data.item.is_assigned_plate &&
										data.item_status != 2 &&
										isAdmin &&
										data.item.status_number == 1
									"
								>
									<b-col md="9">
										<b-form-input
											placeholder="Placa"
											size="sm"
											v-model="data.item.assigned_plate"
											@input="validatePlate(data.item)"
										/>
										<span
											class="text-danger"
											v-if="!data.item.is_correct_plate && data.item.assigned_plate"
											>Placa existente</span
										>
									</b-col>
									<b-col md="1">
										<feather-icon
											icon="CheckIcon"
											:class="[
												'cursor-pointer',
												!data.item.is_correct_plate ? 'disabled-element' : '',
											]"
											@click="setPlacaToChick(data.item)"
										/>
									</b-col>
									<b-col md="1">
										<feather-icon
											icon="XIcon"
											class="cursor-pointer"
											@click="data.item.assigned_plate = null"
										/>
									</b-col>
								</b-row>
							</template>
							<!-- <template #cell(actions)="data">
									<feather-icon
										v-if="isAdmin && !data.item.is_assigned_plate"
										class="text-danger cursor-pointer"
										@click="deleteChick(data.item.chick_id)"
										icon="TrashIcon"
									/>
								</template> -->
						</b-table-lite>
					</b-card>
				</template>
				<hr class="invoice-spacing" />
			</b-col>
		</b-row>
		<template #modal-footer="{ cancel }">
			<b-button @click="cancel()" variant="secondary">
				Cerrar
				<b-icon icon="x" scale="2" />
			</b-button>
		</template>
	</b-modal>
</template>
<script>
import modalMixin from "@/mixins/modal.js";
import Ripple from "vue-ripple-directive";
import CombChickenlegsCintillos from "@/views/amg/encaste/lotes/components/CombChickenlegsCintillos";
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue";
import { mapGetters } from "vuex";

export default {
	props: {
		postura: Object,
		chick_status: [Number, Object],
		chick_gender: [Number, Object],
	},
	directives: {
		Ripple,
	},
	components: {
		CombChickenlegsCintillos,
		SpecimenPlate,
	},
	mixins: [modalMixin],
	async mounted() {
		await this.getGroupedDetail();
		this.toggleModal("modal-chicks-by-postura");
	},
	computed: {
		...mapGetters({
			layout: "appConfig/layout",
		}),
	},
	data() {
		return {
			simon: new Date().toString(),
			sortByCustom: "padrillo_alias",
			sortDesc: false,
			arrayGroupedPosturas: [],
			dato1: "",
			dato2: "",
			paginate: {
				orderby: this.dato2 == null ? 3 : this.dato2,
				order: this.dato1 == null ? "desc" : this.dato1,
				start_page: "",
				end_page: "",
				total_data: "",
				perpage: "",
				next_page: "",
				search_input: "",
			},
			posturaId: null,
			posturaComment: null,
			modalActivatingPostura: false,
			loading: false,
			genders: [
				{
					text: "MACHO",
					value: 1,
				},
				{
					text: "HEMBRA",
					value: 2,
				},
				{
					text: "SIN ASIGNAR",
					value: 3,
					disabled: true,
				},
			],
			isEditingDob: false,
			arrayColumns: [
				{
					key: "correlative",
					label: "Cintillo ala",
					class: "text-center",
					width: "140px",
				},
				{
					key: "dob",
					label: "Fecha de nacimiento",
					class: "text-center align-items-center",
				},
				{
					key: "gender",
					label: "Género",
					class: "text-center",
				},
				{
					key: "assigned_plate",
					label: "Placa",
					class: "text-center",
				},
				{
					key: "status",
					label: "Estado",
					class: "text-center",
				},
				// {
				//   key: "actions",
				//   label: "Acciones",
				//   class: "text-center ",
				// },
			],
			hasChanged: false,
		};
	},
	methods: {
		async validatePlate(chick) {
			const { data } = await axios.post("/api/specimens/validate", {
				plate: chick.assigned_plate,
			});
			if (data.length > 0) {
				chick.is_correct_plate = 0;
			} else {
				chick.is_correct_plate = 1;
			}
		},
		async setPlacaToChick(chick) {
			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;
			try {
				await axios.post("/api/chick/set-placa", {
					chicken_id: chick.chick_id,
					assigned_plate: chick.assigned_plate,
					user_id: this.currentUser.user_id,
					dob: chick.date_of_birth,
					gender: chick.gender_number,
				});
				this.showSuccessToast("Placa registrada correctamente!");
				this.getGroupedDetail();
				this.hasChanged = true;
			} catch (error) {
				console.error(error);
				throw error;
			}
		},
		editChickDob(chick) {
			chick.new_dob = chick.date_of_birth;
			chick.is_editing_dob = 1;
		},
		closeEditChickDob(chick) {
			chick.new_dob = "";
			chick.is_editing_dob = 0;
		},
		async saveNewDobToChick(chick) {
			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;
			try {
				await axios.post("/api/chick/save-new-dob", {
					chick_id: chick.chick_id,
					new_dob: chick.new_dob,
					plate: chick.assigned_plate,
				});
				this.showSuccessToast("Fecha de nacimiento editada");
				this.getGroupedDetail();
				this.hasChanged = true;
			} catch (error) {
				console.error(error);
				throw error;
			}
		},
		async onChangeGenderSelect(gender) {
			await this.editChick(gender.gender_number, gender.status_number, gender.chick_id);
		},
		async editChick(gender, status, chick_id) {
			await axios.post("/api/chick/update", {
				gender: gender,
				status: status,
				chick_id: chick_id,
			});
			this.showSuccessToast("Pollo actualizado");
			this.getGroupedDetail();
			this.$emit("update-chick");
			this.hasChanged = true;
		},
		resetSearch() {
			this.paginate.search_input = "";
			this.getGroupedDetail();
		},
		async deleteChick(id) {
			// eliminar al pollo
			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;
			try {
				await axios.post("/api/chick/delete", {
					chick_id: id,
					deleted_by: this.currentUser.user_id,
				});
				this.showSuccessToast("Pollo Eliminado");
				this.getGroupedDetail();
				this.hasChanged = true;
			} catch (error) {
				console.error(error);
				throw error;
			}
		},
		getGroupedDetail(dato1 = null, dato2 = null) {
			this.isPreloading();
			this.dato1 = dato1;
			this.dato2 = dato2;
			axios
				.post("/api/postura/get-grouped-postura-chicks-detail", {
					orderby: "desc",
					order: 3,
					campo: null,
					madrilla_plate: this.postura.placa_madrilla,
					padrillo_plate: this.postura.placa_padrillo,
					madrilla_id: this.postura.madrilla_id,
					padrillo_id: this.postura.padrillo_id,
					status: this.chick_status,
					gender: this.chick_gender,
				})
				.then((response) => {
					if (response.status == 200) {
						this.arrayGroupedPosturas = response.data.data;
						this.paginate.start_page = response.data.current_page;
						this.paginate.perpage = response.data.per_page;
						this.paginate.next_page = this.paginate.start_page + 1;
						this.paginate.end_page = response.data.last_page;
						this.paginate.total_data = response.data.total;
					}
					this.isPreloading(false);
				})
				.catch((err) => {
					console.log(err);
				});
			
		},
		next(next) {
			this.isPreloading();
			axios
				.post("/api/postura/get-grouped-postura-chicks-detail?page=" + next, {
					orderby: "desc",
					order: 3,
					campo: null,
					madrilla_plate: this.postura.placa_madrilla,
					padrillo_plate: this.postura.placa_padrillo,
					madrilla_id: this.postura.madrilla_id,
					padrillo_id: this.postura.padrillo_id,
					status: this.chick_status,
					gender: this.chick_gender,
				})
				.then((response) => {
					if (response.status == 200) {
						this.arrayGroupedPosturas = response.data.data;
						this.paginate.start_page = response.data.current_page;
						this.paginate.perpage = response.data.per_page;
						this.paginate.next_page = this.paginate.start_page + 1;
						this.paginate.end_page = response.data.last_page;
						this.paginate.total_data = response.data.total;
						if (this.paginate.start_page == this.paginate.end_page) {
							this.paginate.next_page = this.paginate.start_page;
						} else {
							this.paginate.next_page = this.paginate.start_page + 1;
						}
						this.isPreloading(false);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		},
		prev(start_p) {
			this.isPreloading();
			var newval = start_p - 1;
			axios
				.post("/api/postura/get-grouped-postura-chicks-detail?page=" + newval, {
					orderby: "desc",
					order: 3,
					campo: null,
					madrilla_plate: this.postura.placa_madrilla,
					padrillo_plate: this.postura.placa_padrillo,
					madrilla_id: this.postura.madrilla_id,
					padrillo_id: this.postura.padrillo_id,
					status: this.chick_status,
					gender: this.chick_gender,
				})
				.then((response) => {
					if (response.status == 200) {
						this.arrayGroupedPosturas = response.data.data;
						this.paginate.start_page = response.data.current_page;
						this.paginate.perpage = response.data.per_page;
						this.paginate.next_page = this.paginate.start_page + 1;
						this.paginate.end_page = response.data.last_page;
						this.paginate.total_data = response.data.total;
						if (this.paginate.start_page == this.paginate.end_page) {
							this.paginate.next_page = this.paginate.start_page;
						} else {
							this.paginate.next_page = this.paginate.start_page + 1;
						}
						this.isPreloading(false);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		},
	},
	watch: {
		"paginate.search_input"() {
			this.getGroupedDetail();
		},
	},
};
</script>
