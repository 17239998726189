<template>
	<b-modal centered no-close-on-backdrop size="sm" :title="title" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<!-- <pre>{{ info }}</pre> -->
			<ValidationObserver ref="form">
				<b-form-group label="Fecha">
					<ValidationProvider rules="required" v-slot="{ errors }">
						<flat-pickr
							class="form-control bg-transparent"
							:class="{ 'border-danger is-invalid': !!errors[0] }"
							:config="{ altInput: true, altFormat: 'd-m-Y' }"
							v-model="date"
						/>
						<small class="text-danger" v-if="errors.length != 0">{{ errors[0] }}</small>
					</ValidationProvider>
				</b-form-group>
				<b-form-group label="Causa de muerte">
					<ValidationProvider rules="required" v-slot="{ errors }">
						<v-select
							label="text"
							:reduce="(opt) => opt.value"
							:class="{ 'border-danger rounded': errors[0] }"
							:options="deadReasonOpts"
							v-model="reason"
						/>
					</ValidationProvider>
				</b-form-group>
				<b-form-group label="Evidencias">
					<!-- <b-form-file multiple v-model="form.evidence" /> -->
					<div class="d-flex justify-content-center">
						<InputImages  v-model="evidence" />
					</div>
				</b-form-group>
			</ValidationObserver>
		</b-overlay>
		<template #modal-footer>
			<b-button variant="primary" @click="save">Guardar</b-button>
		</template>
	</b-modal>
</template>

<script>
import moment from "moment"

import reasonsService from "@/services/reasons.service"
import deadsService from "@/services/deads.service"
import { getBase64 } from "@/helpers/functions"

import InputImages from "@/components/commons/InputImages.vue"

export default {
	components: { InputImages },
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		date: new Date(),
		deadReasonOpts: [],
		reason: null,
		evidence: [],
		title: "",
	}),
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.date = new Date()
			this.reason = null
			this.evidence = []
			this.$emit("closing")
		},
		async getOpts() {
			this.isLoading = true
			const responses = await Promise.all([reasonsService.getByModule(1)])
			this.deadReasonOpts = responses[0].map((opt) => ({ value: opt.id, text: opt.reason }))
			this.isLoading = false
		},
		async save() {
			if (!(await this.$refs.form.validate())) return
			const { isConfirmed } = await this.showConfirmSwal({
				title: `Se matara el ejemplar nuevo ${this.info.plate}`,
			})
			if (!isConfirmed) return
			let images = []
			for (let idx = 0; idx < this.evidence.length; idx++) {
				const img = this.evidence[idx]
				let base = undefined
				if (img.id == null) {
					base = await getBase64(img.file)
				}
				images.push({ base })
			}
			const specimens = [
				{
					id: null,
					reason_id: this.reason,
					date_dead: moment(this.date).format("YYYY-MM-DD"),
					images,
					chick_id: this.info.chick_id,
				},
			]
			await deadsService.insertDeadSpecimens({ specimens })
			this.handleHidden()
			this.showToast("success", "top-right", "Ejemplares", "SuccessIcon", "Se guardo")
			this.$emit("refresh")
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.title = `Matar ejemplar nuevo / ${this.info.plate}`
				this.getOpts()
			}
		},
	},
}
</script>
