<template>
	<div>
		<div class="card container-select-year">
			<b-form-group class="mb-0">
				<v-select
					v-model="season"
					label="season"
					:options="seasons"
					:reduce="(option) => option.id"
					:clearable="false"
					transition=""
					placeholder="Seleccione un año"
					@input="setYearMain($event)"
					class="select-year"
				/>
			</b-form-group>
			<div class="mt-1 mt-md-0 container-btn d-flex justify-content-end align-items-center">
				<b-button variant="primary" class="" @click="addNursery.show = !addNursery.show">
					<feather-icon icon="HeartIcon" />
					ENVIAR A ENFERMERIA
				</b-button>
			</div>
		</div>
		<div class="card px-1 py-0 container-uls-encaste-chicks">
			<ul class="nav nav-tabs align-items-center gap-x-2 mb-0" role="tabslits">
				<b-nav-item
					v-for="(tab, index) in tabArray"
					link-classes="px-1 justify-content-between"
					class="x-tab-width"
					href="#"
					:key="`${index}-index`"
					:active="tabIndex == index"
					@click="tabIndex = index"
				>
					<span>{{ tab.name }}</span>
					<b-badge pill variant="danger" class="ml-1">
						{{ tab.counter }}
					</b-badge>
				</b-nav-item>
			</ul>
			<ul
				class="nav nav-tabs align-items-center mb-0 d-flex justify-content-center"
				role="tabslits"
				:style="{ paddingLeft: '0rem !important', paddingRight: '0rem !important' }"
			>
				<b-nav-item link-classes="px-0 d-none d-md-flex" href="#" disabled class="pm-by-item-label">
					<strong class="text-dark mr-1">VER POR:</strong>
				</b-nav-item>
				<b-nav-item link-classes="nav-item-chicks py-2 pm-by-nav-item" href="#" @click="tabIndex = 3" title="">
					<div class="btn-encaste-pollos" :class="[tabIndex == 3 ? 'btn-encaste-pollos-active' : '']">
						<svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M22.9975 11.1449L22.8632 11.2869L22.9686 11.4515C24.6252 14.0378 25.2787 17.1512 24.8034 20.1947C24.3282 23.2382 22.7578 25.9967 20.3941 27.9418C18.0305 29.8868 15.0398 30.8816 11.9956 30.736C8.95134 30.5905 6.06693 29.3148 3.89553 27.1528L3.89371 27.151C-0.314751 23.0455 -0.936913 16.1696 2.33605 11.235C5.23183 6.87203 12.4914 3.26267 19.8801 8.09257L20.0526 8.20531L20.1962 8.05755L23.6418 4.51217L24.0541 4.08794H23.4625C23.1863 4.08794 22.9199 4.08939 22.6597 4.0908C22.1578 4.09353 21.679 4.09614 21.198 4.08798C20.6139 4.07749 20.1479 3.89024 19.8264 3.58913C19.5061 3.28912 19.3114 2.85949 19.2976 2.32744C19.2837 1.79197 19.4529 1.32468 19.7527 0.988539C20.0512 0.653949 20.4912 0.435433 21.0483 0.415898L21.0486 0.415884C23.6115 0.32193 26.175 0.266664 28.7393 0.250084L28.7396 0.250081C29.3334 0.245365 29.8218 0.445794 30.1632 0.781521C30.5048 1.11734 30.7154 1.60444 30.7238 2.20261V2.20266C30.7607 4.7932 30.7566 7.38409 30.7238 9.97542L30.7238 9.97576C30.7109 11.1206 29.848 11.9597 28.8627 11.9525C27.8773 11.9453 27.1093 11.1896 27.0344 10.0476C26.9983 9.48784 27.007 8.93431 27.0161 8.35112C27.0203 8.08037 27.0246 7.80324 27.0246 7.51612V6.88809L26.593 7.34431L22.9975 11.1449ZM13.1231 9.60423L12.9487 9.59892C8.56678 9.27449 4.47724 12.7601 4.0187 17.2222L4.01869 17.2222C3.75475 19.7932 4.44937 22.1095 5.88652 23.832C7.32399 25.5548 9.49089 26.6674 12.1409 26.8553C16.7782 27.1846 20.6425 23.9324 21.1301 19.1235L21.1301 19.1232C21.6689 13.7595 17.4792 9.73708 13.1231 9.60423Z"
								fill="#858585"
								stroke="#E4E4E4"
								stroke-width="0.5"
							/>
						</svg>
						<span>Postura</span>
					</div>
				</b-nav-item>
			</ul>
		</div>

		<b-tabs
			content-class="mt-2"
			nav-wrapper-class="card pt-1 pr-1 pl-1 d-none"
			nav-class="align-items-center"
			v-model="tabIndex"
			lazy
		>
			<b-tab>
				<ChickensList :status="1" @refresh-counters="getCounters" :key="refreshTrigger" />
			</b-tab>
			<b-tab>
				<ChickensList :status="2" @refresh-counters="getCounters" :key="refreshTrigger" />
			</b-tab>
			<b-tab>
				<ChickensList :status="3" @refresh-counters="getCounters" :key="refreshTrigger" />
			</b-tab>
			<b-tab>
				<ChicksByPostura />
			</b-tab>
		</b-tabs>
		<ModalProcessChickPlates
			:show="showModalProcessChickPlates"
			@closing="
				showModalProcessChickPlates = false
				getCounters()
			"
		/>
		<AddChicksNursery
			:show="addNursery.show"
			:info="addNursery.info"
			@closing="addNursery.show = false"
			@refresh="refreshTrigger++"
		/>
	</div>
</template>

<script>
import { mapMutations, mapState } from "vuex"
import ChickensList from "./ChickensList.vue"
import CorralService from "@/services/encaste/corral.service"
import ChicksByPostura from "./ChicksByPostura.vue"
import encasteChicksService from "@/services/encaste/chicks.service"
import ModalProcessChickPlates from "./modals/ProcessChickPlates.vue"
import AddChicksNursery from "../../nursery/components/AddChicksNursery.vue"

export default {
	name: "ChickensMain",
	components: {
		ChickensList,
		ChicksByPostura,
		ModalProcessChickPlates,
		AddChicksNursery,
	},
	data() {
		return {
			tabIndex: 0,
			activeTabs: false,
			tabArray: [
				{
					name: "SIN PLACA",
					counter: 0,
				},
				{
					name: "CON PLACA",
					counter: 0,
				},
				{
					name: "MUERTOS",
					counter: 0,
				},
			],
			yearMain: new Date().getFullYear(),
			season: null,
			showModalProcessChickPlates: false,
			addNursery: { show: false, info: {} },
			refreshTrigger: 0,
		}
	},
	created() {
		this.$root.$on("reloader-counters-chicks", async () => {
			await this.getCounters()
		})
	},
	destroyed() {
		this.$root.$off("reloader-counters-chicks")
	},
	async mounted() {
		this.season = this.seasons[0].id
		this.setYearMain(this.season)
		await this.getCounters()
	},
	computed: {
		...mapState("encasteChicks", ["start_date", "end_date", "reload_counters"]),
	},
	methods: {
		...mapMutations("encasteChicks", ["SET_SEASON_MAIN", "TOGGLE_RELOAD_COUNTERS"]),
		async getCounters() {
			const { contadores } = await encasteChicksService.getCounterByCondition({
				start_date: this.start_date ? this.start_date : null,
				end_date: this.end_date ? this.end_date : null,
			})
			this.tabArray = Object.entries(contadores).map((item) => {
				return {
					name: item[0].toLocaleUpperCase(),
					counter: parseInt(item[1]),
				}
			})
			let { data } = await CorralService.getCountPlacationRequests()
			this.counterPlatesPending = data.counter
		},
		setYearMain(tr) {
			const { start, end } = this.seasons.find((season) => season.id == tr)
			this.SET_SEASON_MAIN({ start_date: start, end_date: end })
		},
	},
	watch: {
		async season(newValue, oldValue) {
			if (newValue != oldValue && oldValue != null) {
				await this.getCounters()
			}
		},
		async reload_counters() {
			if (this.reload_counters) {
				await this.getCounters()
				this.TOGGLE_RELOAD_COUNTERS({ reload: false })
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.container-select-year {
	padding: 2rem;
	display: flex;

	.select-year {
		width: 100% !important;
		@media screen and (min-width: 700px) {
			width: 200px !important;
		}
	}

	@media screen and (min-width: 700px) {
		align-items: center;
		flex-direction: row;
		justify-content: space-between;
	}
}
.nav-item-chicks {
	width: 100% !important;
}
.nav-item {
	width: 100% !important;
	@media screen and (min-width: 700px) {
		width: auto !important;
	}
}
.container-uls-encaste-chicks {
	display: flex !important;
	flex-direction: column;
	@media screen and (min-width: 700px) {
		flex-direction: row;
		justify-content: space-between;
	}
}
.btn-encaste-pollos {
	background: var(--darkest);
	border: 1px solid transparent;
	border-radius: 10px;
	padding: 0.8rem 1.6rem;
	transition: all 400ms ease-in-out;
	box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
	width: 100% !important;
	text-align: center;
	// background: green;
	svg {
		height: 20px;
		width: 20px;
	}
	span {
		margin-bottom: 0;
		font-weight: bold;
		margin-left: 0rem;
		color: gray;
	}
	&:hover {
		border-color: var(--primary);
	}
}

.btn-encaste-pollos-active {
	background: #7367f0;
	box-shadow: 0 10px 15px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
	svg {
		path {
			fill: #fff;
		}
	}
	span {
		color: #fff;
	}
}
.x-tab-width {
	width: 100%;
	@media screen and (min-width: 700px) {
		width: auto;
	}
}

.btn-counter {
	position: relative;
	.icon-add {
		position: absolute;
		top: -10px;
		right: -10px;
		border-radius: 50%;
		height: 24px;
		width: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
</style>
