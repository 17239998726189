import axios from "@/axios";

const url = "/api/notepad";

class NotepadChicksService {
	async getNotepad({ module_id, sub_module_id }) {
		try {
			const { data } = await axios.get(`${url}/get-items-search`, {
				params: {
					module_id,
					sub_module_id,
				},
			});
			return data;
		} catch (err) {
			console.error(err);
		}
	}

	async upsertNotepad({ module_id, sub_module_id, word }) {
		try {
			const { data } = await axios.post(`${url}/insert-items-search`, {
				module_id,
				sub_module_id,
				word,
			});
			return data;
		} catch (err) {
			return err.response.data;
		}
	}
}
export default new NotepadChicksService();
