<template>
	<b-modal centered hide-footer :title="title" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<b-table-simple small sticky-header="60vh">
				<b-thead class="text-center text-nowrap">
					<b-tr>
						<b-th>Etapa</b-th>
						<b-th>Creado por</b-th>
						<b-th>Comentario</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="(row, idx) in rows" :key="idx">
						<b-td>
							<div class="d-flex justify-content-center align-items-center gap-x-1">
								{{ String(row.name).toUpperCase() }}
								<template v-if="row.is_dead != null">
									<div
										style="width: 15px; height: 15px; border-radius: 50%; background: red"
										v-if="row.is_dead === 1"
										v-b-tooltip.hover
										title="DE BAJA"
									/>
									<div
										style="width: 15px; height: 15px; border-radius: 50%; background: green"
										v-b-tooltip.hover
										title="DE ALTA"
										v-else
									/>
								</template>
							</div>
						</b-td>
						<b-td>
							<span>{{ row.created_by.name }}</span>
							<br />
							<small>{{ row.created_by.date | myGlobalDayShort }}</small>
						</b-td>
						<b-td>
							<p class="overflow-hidden" style="amx-height: 4rem">{{ row.comment || "---" }}</p>
						</b-td>
					</b-tr>
					<b-tr v-if="rows.length == 0">
						<b-td colspan="2" class="text-center">No hay registros para mostrar</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
	</b-modal>
</template>

<script>
import nurseryService from "@/services/nursery/default.service"

export default {
	props: {
		show: Boolean,
		info: Object,
		is_dead: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		title: "",
		rows: [],
	}),
	computed: {
		isChick() {
			return !!this.info.chick
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.rows = []
			this.$emit("closing")
		},
		async getRows() {
			this.isLoading = true
			if (!!this.info._specimen_id) {
				const data = await nurseryService.getTrackingBySpecimen({ id: this.info._specimen_id })
				this.rows = data
			} else if (!!this.info._chick_id) {
				const data = await nurseryService.getTrackingByChick({ id: this.info._chick_id })
				this.rows = data
			} else {
				const { data } = await nurseryService.getTracking({ id: this.info.id })
				this.rows = data
			}
			this.isLoading = false
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.getRows()
				if (true) {
					this.title = `Tracking de enfermeria del ${this.isChick ? "pollo" : "ejemplar"} ${
						this.isChick
							? this.info.chick.correlative
							: this.info.specimen.alias || this.info.specimen.plate
					}`
				} else {
					this.title = `Tracking de enfermeria del ${this.isChick ? "pollo" : "ejemplar"} ${
						this.isChick
							? this.info.chick.correlative
							: this.info.specimen.alias || this.info.specimen.plate
					}`
				}
			}
		},
	},
}
</script>
