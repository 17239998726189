<template>
	<b-overlay :show="isLoading">
		<b-tabs v-model="tab">
			<b-tab>
				<template #title>
					Pollos
					<b-badge class="ml-05" variant="danger" v-if="ids.brothers.length - 1 != 0">
						{{ ids.brothers.length - 1 }}
					</b-badge>
				</template>
			</b-tab>
			<b-tab :disabled="specimens.length == 0">
				<template #title>
					Ejemplares
					<b-badge class="ml-05" variant="danger" v-if="specimens.length != 0">
						{{ specimens.length }}
					</b-badge>
				</template>
			</b-tab>
		</b-tabs>
		<ChickensListBrothers
			@set-reload="$emit('set-reload')"
			:ids="ids"
			:recursive="true"
			v-show="tab == 0 && ids.brothers.length - 1 != 0"
		/>
		<h4 class="text-center" v-if="tab == 0 && ids.brothers.length - 1 == 0">Sin pollos hermanos</h4>
		<div v-show="tab == 1" class="px-1 containers-genders">
			<ContainerGenderCard :specimens="specimens.filter((it) => it.specimen.gender_id == 1)" title="MACHOS" />

			<ContainerGenderCard :specimens="specimens.filter((it) => it.specimen.gender_id == 2)" title="HEMBRAS" />
		</div>
	</b-overlay>
</template>

<script>
import corralService from "@/services/encaste/corral.service.js"

import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import ContainerGenderCard from "@/views/amg/specimens/dashboard/modals/ContainerGenderCard.vue"

export default {
	name: "BrothersByChick",
	components: {
		ChickensListBrothers: () => import("../ChickensList.vue"),
		SpecimenPlate,
		ContainerGenderCard,
	},
	props: {
		ids: {
			type: Object,
			default: () => {},
		},
		recursive: {
			type: Boolean,
		},
	},
	data: () => ({
		isLoading: false,
		tab: 0,
		specimens: [],
		specimensMap: [],
	}),
	methods: {
		async getSpecimenBrothers() {
			this.isLoading = true
			const { data } = await corralService.getSpecimenBrothersByChick({ id: this.ids.chick_id })
			console.log(data)
			this.specimens = data
			this.isLoading = false
		},
	},
	created() {
		this.getSpecimenBrothers()
	},
}
</script>
<style lang="scss" scoped>
.containers-genders {
	display: grid;
	grid-template-columns: 1fr;
	gap: 2rem;
	padding: 2rem;
	padding: 2rem;
	@media screen and (min-width: 1400px) {
		grid-template-columns: 1fr 1fr;
	}
	.container-gender-cards {
		border-radius: 12px;
		// overflow: hidden;
		h4 {
			border-top-right-radius: 12px;
			border-top-left-radius: 12px;
			background: #f5f5f5;
			font-weight: bold;
			// background: red;
			padding: 1rem 2rem;
			margin-bottom: 0;
			text-align: center;
			font-size: 20px;
		}
		// padding: 2rem 4rem;
		.specimens {
			border: 2px solid #f2f2f2;
			background: white;
			display: grid;
			grid-template-columns: 1fr;
			gap: 2rem;
			padding: 2rem;
			@media screen and (min-width: 700px) {
				grid-template-columns: 1fr 1fr;
			}
			@media screen and (min-width: 1000px) {
				grid-template-columns: 1fr 1fr 1fr;
			}
			@media screen and (min-width: 1400px) {
				grid-template-columns: 1fr 1fr;
			}
		}
		.found {
			border: 2px solid #f2f2f2;
			background: white;
		}
	}
}
</style>
