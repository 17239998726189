<template>
  <b-card
    v-if="data"
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>{{title}}</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        {{caption}}
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="(item, index) in data"
          :key="index"
          xl="2"
          sm="6"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                button
                size="48"
                :variant="item.color"
                :src="item.src"
                v-if="item.src"
                @click="$emit('onClickItem', item)"
              >
              </b-avatar>
              <b-avatar
                size="48"
                :variant="item.color"
                v-else
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    title:{
        type: String,
        default: () => 'Información'
    },
    caption:{
        type: String,
        default: () => ''
    }
  },
}
</script>
